import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { ko } from "date-fns/locale";
import "react-day-picker/dist/style.css";
import { addDays, format, subDays } from "date-fns";
import { DateRange, DayPicker } from "react-day-picker";
import { atom, useAtom, useSetAtom } from "jotai";
import { defaultSelectedAtom } from "./SearchForm";
import { useLocation } from "react-router-dom";

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 52px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  z-index: 10;

  .btn_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;
export const ApplyBtn = styled.button`
  margin: 16px 10px;
  border: 1px solid #2d40ff;
  border-radius: 4px;
  background-color: #fff;
  padding: 6px 18px;
  cursor: pointer;
  color: #2d40ff;
  &:hover {
    background-color: #2d40ff;
    color: #fff;
  }
`;

interface datepickerProps {
  setFocused: Dispatch<SetStateAction<boolean>>;
}

export const dateRangeAtom = atom("");
export default function DatePicker({ setFocused }: datepickerProps) {
  const temp = useLocation().pathname.includes("temp");
  const disabledDays = [
    {
      from: new Date(1970),
      to: temp ? addDays(new Date(), 1) : subDays(new Date(), 1),
    },
  ];
  const [defaultSelected, setDefaultSelected] = useAtom(defaultSelectedAtom);
  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);
  const setDateRange = useSetAtom(dateRangeAtom);

  useEffect(() => {
    if (range?.from && range?.to) {
      setDateRange(
        format(range.from, "yyyy-MM-dd") +
          "\u00A0~" +
          "\u00A0" +
          format(range.to, "yyyy-MM-dd")
      );
      setDefaultSelected({ from: range.from, to: range.to });
    }
  }, [range]);

  return (
    <Container>
      <DayPicker
        locale={ko}
        mode="range"
        numberOfMonths={1}
        // numberOfMonths={2}
        defaultMonth={range?.from}
        fixedWeeks
        disabled={disabledDays}
        selected={range}
        onSelect={setRange}
      />
      <div className="btn_wrapper">
        <ApplyBtn onClick={() => setFocused(false)}>적용</ApplyBtn>
      </div>
    </Container>
  );
}

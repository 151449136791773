import axios from "axios";
import { useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useNavigationType,
  useSearchParams,
} from "react-router-dom";
import { styled } from "styled-components";
import DetailCard from "../components/DetailCard";
import BookingForm, { BookingFormAtom } from "../components/BookingForm";
import ImportantInfo, { termCheckAtom } from "../components/ImportantInfo";
import { atom, useAtomValue } from "jotai";
import TermsModal from "../components/TermsModal";
import LoadingScreen from "../components/LoadingScreen";

export const Container = styled.div`
  margin: auto;
  margin-top: 35px;
  margin-bottom: 70px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;

  form {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    padding: 0 16px;
    gap: 70px;
  }

  @media screen and (max-width: 768px) {
    display: block;
    margin-top: 0;
  }

  p {
    margin-bottom: 40px;
    color: #5c5f79;
    font-size: 14px;
    letter-spacing: -0.56px;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 755px;
`;

export const PayBtn = styled.button`
  display: block;
  margin: auto;
  width: 100%;
  max-width: 300px;
  padding: 12px 0;
  border-radius: 5px;
  background: #2d40ff;
  color: #fff;
  font-size: 14px;
  letter-spacing: -0.42px;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    max-width: initial;
  }
`;

export const termsModalAtom = atom(false);
export default function Booking() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = useLocation().search.split("&h_id")[0];
  const check_in = searchParams.get("check_in");
  const check_out = searchParams.get("check_out");
  const h_id = searchParams.get("h_id");
  const r_id = searchParams.get("r_id");
  const rate_id = searchParams.get("rate_id");
  const occupancy = searchParams.getAll("occupancy");
  const [detail, setDetail] = useState<any>([]);
  const [image, setImage] = useState("");
  const [roomName, setRoomName] = useState("");
  const [cancel, setCancel] = useState<any>([]);
  const [pricing, setPricing] = useState<any>([]);
  const [refundable, setRefundable] = useState(false);
  const tk = sessionStorage.getItem("tk");
  const actk = sessionStorage.getItem("actk");
  const data = useAtomValue(BookingFormAtom);
  const termCheck = useAtomValue(termCheckAtom);
  const [reserveNo, setReserveNo] = useState();
  const [wm, setWm] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const modal = useAtomValue(termsModalAtom);
  const [payPrice, setPayPrice] = useState(0);
  const [loading, setLoading] = useState(false);

  const navigationType = useNavigationType();

  useEffect(() => {
    // console.log(navigationType);
    if (navigationType === "POP") {
      navigate(-1);
      alert("다시 객실을 선택해 주세요");
    }
  }, [navigationType]);

  const getPrice = async () => {
    const res = await axios.get(
      `/v1/hotels/${h_id}/rooms/${r_id}/rates/${rate_id}?token=${tk}`
    );

    const occu_price = res.data.data.occupancy_pricing;

    if (occu_price) {
      const list = Object.values(occu_price).map((item: any) => item.totals);
      // console.log(list);
      // console.log(
      //   Number(list[0].inclusive.billable_currency.value * occupancy.length)
      // );

      setPricing(list);
      setPayPrice(
        Number(list[0].inclusive.billable_currency.value * occupancy.length)
      );
    }
  };

  const getRoom = async () => {
    const res = await axios.get(
      `/v1/hotels/${h_id}/rooms/availability${search}`
    );
    const data = res.data.data[0].rooms.find((el: any) => el.id === r_id);
    const rates = data.rates.find((item: any) => (item.id = rate_id));

    setRefundable(rates.refundable);
    setRoomName(data.room_name);
    setCancel(rates.cancel_penalties[0].start);
  };

  const getDetail = async () => {
    const res = await axios.get(`/v1/hotels/${h_id}`);
    setDetail(res.data.data);
    // console.log(res.data.data);
    setImage(res.data.data.images[0].links["1000px"].href);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    getPrice();
    getRoom();
    getDetail();
  }, []);

  const handleBooking = async () => {
    const phone_regex = /[0-9]{11}/;
    const email_regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (phone_regex.test(data.phone) === false) {
      return alert("휴대폰 번호를 확인해 주세요.");
    }
    if (email_regex.test(data.email) === false) {
      return alert("이메일을 확인해 주세요.");
    }
    if (data.rooms[0].given_name === "") {
      return alert("이름을 입력해 주세요.");
    }
    if (data.rooms[0].family_name === "") {
      return alert("성을 입력해 주세요.");
    }
    if (!termCheck) {
      return alert("서비스 이용약관 및 개인정보 보호정책에 동의해 주세요.");
    }

    setLoading(true);

    let body = {
      ...data,
      check_in: check_in,
      check_out: check_out,
      total_price: Number(
        pricing[0].inclusive.billable_currency.value * occupancy.length
      ),
      occupancy: [occupancy[0]],
      price_check_token: tk,
      hotel_id: Number(h_id),
      room_id: Number(r_id),
      rate_id: Number(rate_id),
      bed_group_description: roomName,
    };

    const token = sessionStorage.getItem("actk");
    try {
      const res = await axios.post(`/itinerary/hold`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setReserveNo(res.data.data);
    } catch (e: any) {
      alert("일시적인 오류가 발생하였습니다. 다시 시도해 주세요");
      window.location.reload();
    }
  };

  const resizeHandler = () => {
    if (window.innerWidth < 768) {
      setWm(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  useEffect(() => {
    const user = navigator.userAgent;
    const ua =
      user.indexOf("iPhone") > -1 || user.indexOf("Android") > -1 ? "WM" : "WP";

    const url = `/credit/pay/start?itinerary_id=${window.btoa(
      reserveNo || ""
    )}&user_agent=${ua}&language=KO&card_from=KO`;

    if (reserveNo) {
      setLoading(false);
      window.open(url, "_blank", "width=600,height=500");
    }
  }, [reserveNo]);

  return (
    <Container>
      {loading && <LoadingScreen />}
      {modal && <TermsModal />}
      <DetailCard info={{ image, detail, roomName, pricing }} />
      <FormContainer>
        <BookingForm total={pricing} occupancy={occupancy} />
        <ImportantInfo
          detail={detail}
          cancel={cancel}
          refundable={refundable}
        />
        <p>이 결제가 이루어지는 국가: 한국(KR)</p>
        <PayBtn onClick={handleBooking}>결제하기</PayBtn>
      </FormContainer>
    </Container>
  );
}

import {
  addDays,
  addMonths,
  endOfMonth,
  format,
  startOfMonth,
  subMonths,
} from "date-fns";
import { atom, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { styled } from "styled-components";

const Container = styled.div`
  width: 100%;
  max-width: 472px;
  // height: 600px;
  margin: auto;

  padding: 40px 10px;
  border: 1px solid #eeeff3;

  @media screen and (max-width: 480px) {
    padding: 30px 8px;
  }
`;

const Calendar = styled.div`
  h3 {
    width: fit-content;
    margin: auto;
    color: #161a3f;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.72px;
  }
  .days {
    display: flex;
    justify-content: space-between;
    color: #161a3f;

    span {
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.48px;
    }
  }
  .sun {
    color: #e20000;
  }
  .dates {
    width: 100%;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 60px);
    gap: 7px 5px;
    padding: 16px 0;

    color: #161a3f;
    font-size: 12px;
    letter-spacing: -0.48px;
    cursor: pointer;

    .content {
      width: 60px;
      height: 50px;
      border-radius: 5px;
      padding: 5px 8px 10px;
    }
    .date {
      text-align: right;
      font-size: 12px;
    }
    .price {
      text-align: center;
      padding-top: 2px;
      font-size: 11px;
      font-weight: 700;
      letter-spacing: -0.44px;
    }

    .today {
      border: 1px solid #2d40ff;
    }

    .deadline {
      background: #e3e4ed;
    }

    .minimum {
      border: 1px solid #e20000;
    }

    .selected {
      color: #fff;
      background: #2d40ff;
      border: none;

      .sun {
        color: #fff;
      }
    }

    @media screen and (max-width: 480px) {
      grid-template-columns: repeat(auto-fill, 47px);
      gap: 8px 2px;

      .content {
        width: 46px;
        height: 42px;
        padding: 4px 6px 6px;
      }
      .date {
        font-size: 11px;
      }
      .price {
        padding-top: 2px;
        font-size: 10px;
        letter-spacing: -0.4px;
      }
    }
  }
  @media screen and (max-width: 480px) {
    h3 {
      font-size: 16px;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;

  .navigate {
    width: 24px;
    height: 28px;
    border: none;
    cursor: pointer;
  }
  .hidden{
    visibility: hidden;
  }

  .left {
    transform: rotate(180deg);
    background: #fff url("/img/arrow_right.svg") no-repeat center;
    background-size: 30px;
  }
  .right {
    background: #fff url("/img/arrow_right.svg") no-repeat center;
    background-size: 30px;
  }
}
`;

export const deadlineAtom = atom(false);
export const selectedAtom = atom(format(new Date(), "yyyy-MM-dd"));
export default function PriceDatePicker({ calendar }: any) {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const month = [
    "jan_1",
    "feb_2",
    "mar_3",
    "apr_4",
    "may_5",
    "jun_6",
    "jul_7",
    "aug_8",
    "sep_9",
    "oct_10",
    "nov_11",
    "dec_12",
  ];
  // const test_price = [
  //   81, 142, 142, 99, 90, 122, 98, 98, 98, 98, 122, 122, 101, 142, 65, 72, 72,
  //   72, 165, 69, 69, 69, 71, 71, 71, 71, 69, 69, 69, 69, 71,
  // ];
  const [date, setDate] = useState(new Date());
  const startDate = startOfMonth(date);
  const endDate = Number(format(endOfMonth(date), "dd"));
  const setSelectedDate = useSetAtom(selectedAtom);
  const setDeadline = useSetAtom(deadlineAtom);

  const price_list = calendar
    ? Object.values(JSON.parse(calendar[month[date.getMonth()]]))
    : [];

  const index = days.findIndex((el) => el === format(startDate, "EEE"));
  const fullMonth = [
    ...Array(index),
    ...[...Array(endDate)].map((a, i) => addDays(startDate, i)),
  ];
  const fullPrice = [...Array(index), ...price_list];
  const [selected, setSeleted] = useState("");

  // console.log(fullPrice);

  const handleMonth = (cal: boolean) => {
    if (cal) {
      if (addMonths(new Date(), 1) > date) {
        setDate(addMonths(date, 1));
      }
    } else {
      if (new Date() <= date) {
        setDate(subMonths(date, 1));
      }
    }
  };

  const handleSelect = (item: Date, status: string) => {
    if (!isPast(item)) setSeleted(format(item, "yyMMdd"));
    setSelectedDate(format(item, "yyyy-MM-dd"));
    status.slice(-1) === "d" ? setDeadline(true) : setDeadline(false);
  };

  const findToday = (item: Date) => {
    return item && format(item, "yyMMdd") === format(new Date(), "yyMMdd");
  };

  const isPast = (item: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return item && item < today;
  };

  // console.log(new Date(), date);

  return (
    <Container>
      <Calendar>
        <Header>
          <button
            className={`navigate left ${new Date() <= date ? "" : "hidden"}`}
            onClick={() => handleMonth(false)}
          />
          <h3>{format(date, "yyyy년 MM월")}</h3>
          <button
            className={`navigate right ${
              addMonths(new Date(), 1) > date ? "" : "hidden"
            }`}
            onClick={() => handleMonth(true)}
          />
        </Header>
        <div className="days">
          {days.map((item: string, i: number) => (
            <span className={item === "Sun" ? "sun" : ""} key={item + i}>
              {item}
            </span>
          ))}
        </div>
        <div className="dates">
          {fullMonth.map((item, i) => (
            <div
              className={`content ${
                !isPast(item) &&
                (findToday(item)
                  ? "today"
                  : fullPrice[i]?.slice(-1) === "d"
                  ? "deadline"
                  : fullPrice[i]?.slice(-1) === "m"
                  ? "minimum"
                  : "")
              } ${
                item && selected === format(item, "yyMMdd") ? "selected" : ""
              }`}
              key={i}
              onClick={() => handleSelect(item, fullPrice[i])}
            >
              <div className={`date ${i % 7 === 0 ? "sun" : ""}`}>
                {item && format(item, "d")}
              </div>
              {!isPast(item) && fullPrice[i] && (
                <div className="price">
                  {fullPrice[i].substring(0, fullPrice[i].length - 2)}만~
                </div>
              )}
            </div>
          ))}
        </div>
      </Calendar>
    </Container>
  );
}

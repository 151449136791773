import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { styled } from "styled-components";
import DetailCard from "../../components/DetailCard";
import BookingForm, { BookingFormAtom } from "../../components/BookingForm";
import ImportantInfo, { termCheckAtom } from "../../components/ImportantInfo";
import { atom, useAtomValue } from "jotai";
import TermsModal from "../../components/TermsModal";
import LoadingScreen from "../../components/LoadingScreen";
import { hotels } from "./hotels";
import { termsModalAtom } from "../Booking";
import TempBookingForm, {
  TempBookingFormAtom,
} from "./components/TempBookingForm";

export const Container = styled.div`
  margin: auto;
  margin-top: 35px;
  margin-bottom: 70px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;

  form {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    padding: 0 16px;
    gap: 70px;
  }

  @media screen and (max-width: 768px) {
    display: block;
    margin-top: 0;
  }

  p {
    margin-bottom: 40px;
    color: #5c5f79;
    font-size: 14px;
    letter-spacing: -0.56px;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 755px;
`;

export const PayBtn = styled.button`
  display: block;
  margin: auto;
  width: 100%;
  max-width: 300px;
  padding: 12px 0;
  border-radius: 5px;
  background: #2d40ff;
  color: #fff;
  font-size: 14px;
  letter-spacing: -0.42px;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    max-width: initial;
  }
`;

export default function TempBooking() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const search = useLocation().search.split("&h_id")[0];
  const check_in = searchParams.get("check_in");
  const check_out = searchParams.get("check_out");
  const h_id = searchParams.get("h_id");
  const r_id = searchParams.get("r_id");
  const hsq = searchParams.get("hsq");
  const rsq = searchParams.get("rsq");
  const rate_id = searchParams.get("rate_id");
  const occupancy = searchParams.get("occupancy");
  const company = searchParams.get("company");
  const detail = hotels.find((item) => item.property_id === hsq);
  const image = detail?.images[0].links["1000px"].href;
  const [roomName, setRoomName] = useState("");
  const [cancel, setCancel] = useState<any>([]);
  const [pricing, setPricing] = useState<any>([]);
  const [refundable, setRefundable] = useState(false);
  const tk = sessionStorage.getItem("tk");
  const actk = sessionStorage.getItem("actk");
  const data = useAtomValue(TempBookingFormAtom);
  const termCheck = useAtomValue(termCheckAtom);
  const [reserveNo, setReserveNo] = useState();
  const [wm, setWm] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const modal = useAtomValue(termsModalAtom);
  const [payPrice, setPayPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [room, setRoom] = useState<any>({});
  const mem_seq = sessionStorage.getItem("msq");
  const user = navigator.userAgent;

  const getRoom = async () => {
    const res = await axios.get(
      `/hp/rooms?checkin=${check_in}&checkout=${check_out}`
    );
    setRoom(res.data.data.find((el: any) => el.room_seq === Number(rsq)));
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    getRoom();
  }, []);

  // console.log(room);

  const getNights = () => {
    return Math.ceil(
      Math.abs(
        new Date(check_in ? check_in : "").getTime() -
          new Date(check_out ? check_out : "").getTime()
      ) /
        (1000 * 60 * 60 * 24)
    );
  };

  const getPrice = () => {
    if (occupancy) {
      return (
        room.price +
        ((occupancy > room.default_person
          ? occupancy > room.max_person
            ? room.max_person
            : occupancy
          : room.default_person) -
          room.default_person) *
          room.person_price *
          getNights()
      );
    }
  };

  const handleBooking = async () => {
    const phone_regex = /[0-9]{11}/;
    const email_regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (phone_regex.test(data.booker_phone) === false) {
      return alert("휴대폰 번호를 확인해 주세요.");
    }
    if (email_regex.test(data.booker_email) === false) {
      return alert("이메일을 확인해 주세요.");
    }
    if (data.rooms[0].given_name === "") {
      return alert("이름을 입력해 주세요.");
    }
    if (data.rooms[0].family_name === "") {
      return alert("성을 입력해 주세요.");
    }
    if (!termCheck) {
      return alert("서비스 이용약관 및 개인정보 보호정책에 동의해 주세요.");
    }

    setLoading(true);

    let body = {
      ...data,
      booker_name: data.rooms[0].family_name + " " + data.rooms[0].given_name,
      checkin_date: check_in,
      checkout_date: check_out,
      total_amount: Number(getPrice()),
      occupancy: Number(occupancy),
      client_id: company ? 9196 : 1,
      member_seq: mem_seq ? mem_seq : process.env.REACT_APP_MEMBER_SEQ,
      hotel_seq: Number(hsq),
      room_seq: Number(rsq),
      refundable_date: room.refund_eligible_date,
      booking_etc: "etc",
    };

    try {
      const res = await axios.post(`/hp/booking/hold`, body);
      setReserveNo(res.data.data.booking_id);
    } catch (e: any) {
      alert("일시적인 오류가 발생하였습니다. 다시 시도해 주세요");
      window.location.reload();
    }
  };

  const resizeHandler = () => {
    if (window.innerWidth < 768) {
      setWm(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  useEffect(() => {
    if (reserveNo) {
      setLoading(false);
      formRef.current?.submit();
    }
  }, [reserveNo]);

  return (
    <Container>
      {loading && <LoadingScreen />}
      {modal && <TermsModal />}
      <DetailCard info={{ image, detail, roomName, pricing }} />
      <FormContainer>
        <TempBookingForm price={getPrice()} />
        <ImportantInfo
          detail={detail}
          cancel={cancel}
          refundable={refundable}
        />
        <p>이 결제가 이루어지는 국가: 한국(KR)</p>
        <PayBtn onClick={handleBooking}>결제하기</PayBtn>
      </FormContainer>

      <form
        ref={formRef}
        action={process.env.REACT_APP_TEMP_DANAL_API}
        method="GET"
      >
        <input type="hidden" name="booking_id" value={reserveNo} />
        <input
          type="hidden"
          name="member_seq"
          value={mem_seq || process.env.REACT_APP_MEMBER_SEQ}
        />
        <input type="hidden" name="credit_amount" value={getPrice()} />
        <input type="hidden" name="payment_type" value="CREDIT" />
        <input
          type="hidden"
          name="user_agent"
          value={
            user.indexOf("iPhone") > -1 || user.indexOf("Android") > -1
              ? "WM"
              : "WP"
          }
        />
        <input type="hidden" name="client_id" value={company ? 9196 : 1} />
        {/* 비밀리 id = 518*/}
      </form>
    </Container>
  );
}

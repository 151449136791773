import { styled } from "styled-components";
import { PayBtn } from "./Booking";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const Container = styled.div`
  margin: auto;
  margin-top: 100px;
  width: 100%;
  height: 800px;
  max-width: 1200px;

  .wrapper {
    width: fit-content;
    margin: auto;
    text-align: center;

    h3 {
      margin: 10px 0;
      color: #161a3f;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.96px;
    }
    div {
      color: #161a3f;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.56px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

const HomeBtn = styled(PayBtn)`
  margin-top: 70px;
`;

export default function Fail() {
  const payment = useLocation().pathname.includes("packagepayment");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const company = searchParams.get("company");

  return (
    <Container>
      <div className="wrapper">
        <img src="/img/fail.svg" alt="Booking_fail" />
        {payment ? (
          <>
            <h3>결제에 실패하였습니다.</h3>
            <div>결제를 다시 진행해 주세요.</div>
          </>
        ) : (
          <>
            <h3>예약에 실패하였습니다.</h3>
            <div>예약을 다시 진행해 주세요.</div>
          </>
        )}
      </div>
      <HomeBtn
        onClick={() => {
          company ? navigate("/temp?company=ymstar&hsq=1") : navigate(`/`);
        }}
      >
        홈으로 돌아가기
      </HomeBtn>
    </Container>
  );
}

import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { styled } from "styled-components";
import ImageGallery, { allAtom } from "../components/ImageGallery";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import Rating from "../components/Rating";
import RoomCard, { RateModalAtom, RoomAtom } from "../components/RoomCard";
import RoomPhoto from "../components/RoomPhoto";
import RoomInfo from "../components/RoomInfo";
import RoomSearch, { RoomSearchAtom } from "../components/RoomSearch";
import GoogleMaps from "../components/GoogleMaps";
import TabMenu, { tabAtom, tabMenuAtom } from "../components/TabMenu";
import RateDetail from "../components/RateDetail";

const Container = styled.div`
  position: relative;
  margin: auto;
  margin-top: 45px;
  width: 100%;
  max-width: 1200px;

  .more {
    margin: 16px 0 0;
    display: flex;
    justify-content: end;
  }
  @media screen and (max-width: 680px) {
    .more {
      margin: 12px 0;
      padding: 0 16px;
    }
  }
`;

export const ShowMore = styled.button`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 8px 12px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.39px;
  border: none;
  border-radius: 100px;
  background-color: rgba(12, 14, 28, 0.56);
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }

  img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
`;

const Title = styled.div`
  .name {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
  }

  h3 {
    margin: 0;
    font-size: 24px;
    letter-spacing: -0.96px;
  }

  .address {
    color: #5c5f79;
    font-size: 14px;
    letter-spacing: -0.56px;
  }

  @media screen and (max-width: 680px) {
    padding: 0 16px;

    .name {
      align-items: start;
      flex-direction: column;
    }
  }
`;

const NoRooms = styled.div`
  margin: 24px 0;
  display: flex;
  align-items: start;
  gap: 4px;

  p {
    margin: 0;
    color: #e20000;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.56px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 380px);
  justify-content: center;
  gap: 30px;
  margin-top: 20px;

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(auto-fill, 350px);
  }
`;

const Location = styled.div`
  padding-top: 94px;
  h3 {
    color: #161a3f;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.8px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 40px;
    padding: 0 16px;
  }
`;

export const Address = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;

  .loca_wrapper {
    display: flex;
    align-items: start;
    line-height: 19px;
    gap: 4px;
  }

  .copy {
    min-width: 75px;
    padding: 6px 12px;
    color: #2d40ff;
    font-size: 13px;
    letter-spacing: -0.52px;
    border-radius: 5px;
    border: 1px solid #2d40ff;
    background: #fff;
    cursor: pointer;
  }
`;

export const LocationTopAtom = atom(0);
export default function Hotel() {
  const params = useParams();
  const location = useLocation();
  const path_list = location.pathname.split("/");
  const path = path_list.slice(1).join("/");
  const path_detail = path_list[path_list.length - 2];
  const search = location.search;
  const hotel_id = location.pathname.split("/")[2];
  const locaRef = useRef<HTMLDivElement>(null);
  const roomRef = useRef<HTMLDivElement>(null);
  const setTab = useSetAtom(tabAtom);
  const tabMenu = useAtomValue(tabMenuAtom);
  const setAll = useSetAtom(allAtom);
  const [detail, setDetail] = useState<any>([]);
  const [rooms, setRooms] = useState<any>([]);
  const [roomImg, setRoomImg] = useState<any>([]);
  const [inroom, setInRoom] = useState<any>([]);
  const [roomModal, setRoomModal] = useAtom(RoomAtom);
  const [roomSearch, setRoomSearch] = useAtom(RoomSearchAtom);
  const [noRooms, setNoRooms] = useState(false);
  const [rateModal, setRateModal] = useAtom(RateModalAtom);

  useEffect(() => {
    tabMenu === "room" &&
      roomRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    tabMenu === "location" &&
      locaRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  }, [tabMenu]);

  const handleScroll = () => {
    if (roomRef.current) {
      roomRef.current?.offsetTop > window.scrollY - 90 && setTab("");
      roomRef.current?.offsetTop < window.scrollY - 90 && setTab("room");
    }
    if (locaRef.current) {
      locaRef.current?.offsetTop < window.scrollY - 90 && setTab("location");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getRooms = async () => {
    setRooms([]);
    noRooms && setNoRooms(false);
    const res = await axios.get(`/v1/${path + search}`);
    // console.log("방", res.data.data);

    if (res.data.data.length === 0) {
      setNoRooms(true);
    } else {
      setRooms(res.data.data[0].rooms);
    }
  };

  const getDetail = async () => {
    const res = await axios.get(
      `/v1/hotels/${path_detail === "detail" ? params.id : hotel_id}`
    );
    setDetail(res.data.data);
    setRoomImg(res.data.data.rooms);
    setInRoom(
      Object.values(res.data.data.rooms)
        .map((item: any) => item.amenities)
        .map((el) => Object.values(el))
        .reduce((a: any, b: any) => (a.length > b.length ? a : b))
    );
    // console.log(res.data.data);
  };

  const copyAddress = () => {
    navigator.clipboard
      .writeText(`${detail.address.city + " " + detail.address.line_1}`)
      .then(() => {
        alert("주소가 복사 되었습니다.");
      });
  };

  useEffect(() => {
    !params.id && getRooms();
    getDetail();
    if (roomSearch) setRoomSearch(false);
  }, [search]);

  return (
    <Container>
      <ImageGallery list={detail} />
      <div className="more">
        <ShowMore
          onClick={() => {
            setAll(true);
          }}
        >
          <img src="/img/more_photo.svg" alt="show_more_photo" />
          사진 모두 보기
        </ShowMore>
      </div>

      {!params.id && <TabMenu />}

      <Title>
        <div className="name">
          <h3>{detail.name}</h3>
          {detail.address && (
            <div className="address">
              {detail.address.city + " " + detail.address.line_1}
            </div>
          )}
        </div>
        {detail.ratings && <Rating mgt="0" item={detail.ratings} />}
      </Title>

      {!params.id && (
        <>
          <div ref={roomRef}>
            <RoomSearch />
          </div>

          {noRooms && (
            <NoRooms>
              <img src="/img/warning.svg" alt="no_available_rooms" />
              <p>
                선택하신 여행 날짜에 예약 가능한 객실이 없습니다. 다른 날짜를
                입력해 예약 가능 여부를 확인해 보세요.
              </p>
            </NoRooms>
          )}

          {rateModal && <RateDetail />}

          {rooms.length > 0 && (
            <CardContainer>
              {rooms.map((item: any, i: number) => (
                <RoomCard
                  key={i + item.id}
                  item={item}
                  room={roomImg[item.id]}
                />
              ))}
              {roomModal && <RoomPhoto />}
            </CardContainer>
          )}
        </>
      )}

      <Location ref={locaRef}>
        <h3>위치</h3>

        {detail.location && <GoogleMaps coord={detail.location.coordinates} />}

        <Address>
          <div className="loca_wrapper">
            <img src="/img/location.svg" alt="location" />
            {detail.address && (
              <div className="address">
                {detail.address.city + " " + detail.address.line_1}
              </div>
            )}
          </div>
          <button className="copy" onClick={copyAddress}>
            주소 복사
          </button>
        </Address>
      </Location>

      {detail.amenities && (
        <RoomInfo
          detail={detail}
          amenities={detail.amenities}
          inroom={inroom}
        />
      )}
    </Container>
  );
}

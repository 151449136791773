import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { styled } from "styled-components";
import PackageGuestCard, {
  ContentsBox,
  InputBox,
} from "../components/PackageGuestCard";
import { atom, useAtom } from "jotai";
import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { Button } from "../components/PackageDetail";
import Modal from "../components/Modal";
import useValidate from "../useValidate";

const Container = styled.div`
  width: 100%;
  max-width: 700px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;

  .content_img {
    margin-bottom: 40px;
    width: 100%;
  }

  .content_wrap {
    margin-bottom: 20px;
    border: 1px solid rgb(238, 238, 238);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 0px 20px;
  }

  .card_container {
    padding: 24px 30px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  padding: 24px 30px;
  border-bottom: 1px solid rgb(238, 238, 238);

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const ContentBox = styled(ContentsBox)`
  padding: 24px 30px;
`;

const AddPassengerBtn = styled.button`
  display: block;
  margin: auto;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px 4px 6px;

  color: #2d40ff;
  font-weight: 500;

  border: none;
  border-radius: 100px;
  background-color: #fff;
  cursor: pointer;

  img {
    width: 28px;
  }

  span {
    font-size: 15px;
  }

  &:hover {
    background-color: #0c0e1c0d;
  }

  @media screen and (max-width: 768px) {
    span {
      font-size: 14px;
    }
  }
`;

export const DocumentBox = styled.div<{ color: string; agree?: boolean }>`
  display: flex;
  color: ${(props) => props.color};
  margin-bottom: 14px;
  span {
    color: ${(props) => props.agree && "#0074ff"};
    margin-left: 8px;
    cursor: pointer;
  }
`;

export const Policy = styled.div`
  margin-left: 8px;
  cursor: pointer;
`;

export const CheckBox = styled.input`
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 3px;
  margin-left: 0px;
  margin-right: 13px;
`;

interface GuestCard {
  person: string;
  birth: string;
}

export interface ModalData {
  txt: string;
  link: string;
}

export const policy_list = [
  {
    txt: "여행약관",
    link: "https://event.wafflestay.kr/policy/brisbane1",
  },
  {
    txt: "특별약관",
    link: "https://event.wafflestay.kr/policy/refundgolf",
  },
  {
    txt: "개인정보 보호정책",
    link: "https://event.wafflestay.kr/policy/privacy",
  },
  {
    txt: "이용약관",
    link: "https://admin.wafflestay.kr/term/service",
  },
  {
    txt: "위치정보 이용약관",
    link: "https://event.wafflestay.kr/policy/location",
  },
];
export const guestCardAtom = atom<GuestCard[]>([]);
export default function PackageRequest() {
  const navigate = useNavigate();
  const airtel = useLocation().pathname.includes("airtel");
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get("product_sort");
  const id = searchParams.get("id");
  const [userData, setUserData] = useState({
    consumer_name: "",
    phone: "",
    email: "",
  });
  const [guest, setGuest] = useAtom(guestCardAtom);
  const [img, setImg] = useState("");
  const [check, setCheck] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState<ModalData>({
    txt: "",
    link: "",
  });

  const getImgList = async () => {
    const res = await axios.get(
      `/product/detail/imglist?client_id=1&product_sort=${
        airtel ? "AIRTEL" : "PACKAGE"
      }&product_id=${id}`
    );
    setImg(res.data.data[0].image_url);
  };

  useEffect(() => {
    setGuest([]);
    getImgList();
  }, []);

  const addGuest = () => {
    setGuest([...guest, { person: "", birth: "" }]);
  };

  const handleModal = (idx: number) => {
    setModal(true);
    setModalData(policy_list[idx]);
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const valid = useValidate(userData.phone);

  // const a = [{ person: "이름1", birth: "990101" }];
  // console.log(JSON.stringify(a));

  const handleSubmit = async () => {
    const uid = sessionStorage.getItem("uid");
    const mid = sessionStorage.getItem("mem_seq");

    if (!check) {
      alert("이용약관 및 정책에 동의해 주세요.");
      return;
    }
    if (!userData.consumer_name) {
      alert("이름을 입력해 주세요.");
      return;
    }
    if (!userData.phone) {
      alert("휴대폰 번호를 입력해 주세요.");
      return;
    }
    if (!userData.email) {
      alert("이메일을 입력해 주세요.");
      return;
    }
    if (valid) {
      alert("휴대폰 번호를 확인해 주세요.");
      return;
    }

    const regex = /[^0-9]/g;

    const notValidList = guest.filter(
      (el) =>
        el.person === "" ||
        el.birth === "" ||
        regex.test(el.birth) ||
        el.birth.length !== 6
    );

    if (notValidList.length > 0) {
      alert("추가 여행객 정보를 확인해 주세요.");
      return;
    }

    const data = {
      client_id: "1",
      ...userData,
      with_persons: guest.length,
      persons_info: JSON.stringify(guest),
      policy_check: 1,
      member_seq: mid,
      product_sort: airtel ? "AIRTEL" : "PACKAGE",
      product_id: id,
    };

    try {
      const res = await axios.post("/product/counsel", data);
      res.data.message === "성공" &&
        navigate(`/package/success/${res.data.data}?pid=${id}`);
    } catch (e: any) {
      alert("일시적인 오류가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  return (
    <Container>
      {modal && <Modal setModal={setModal} modalData={modalData} />}
      <img className="content_img" src={img} alt="content" />

      <div className="content_wrap">
        <Title>여행객 정보 입력</Title>
        <ContentBox>
          <div className="form">
            <InputBox>
              <div>이름</div>
              <input
                type="text"
                name="consumer_name"
                placeholder="이름을 입력해 주세요"
                onChange={handleInput}
              />
            </InputBox>
            <InputBox>
              <div className="item">휴대폰 번호</div>
              <div>
                <input
                  type="text"
                  name="phone"
                  placeholder="휴대폰 번호를 입력해 주세요"
                  onChange={handleInput}
                />
                {valid && <div className="warning">숫자만 입력하세요</div>}
              </div>
            </InputBox>
            <InputBox>
              <div className="item">이메일</div>
              <input
                type="text"
                name="email"
                placeholder="이메일을 입력해 주세요"
                onChange={handleInput}
              />
            </InputBox>
          </div>
        </ContentBox>

        <div className="card_container">
          {guest.length > 0 &&
            guest.map((item: GuestCard, i: number) => (
              <PackageGuestCard key={i} no={i + 1} />
            ))}
          {guest.length < 4 && (
            <AddPassengerBtn onClick={addGuest}>
              <img src="/img/inc.svg" alt="여행객 추가" />
              <span>여행객 추가</span>
            </AddPassengerBtn>
          )}
        </div>
      </div>

      <div className="content_wrap">
        <Title className="terms">주요사항 및 정책, 약관</Title>
        <ContentBox style={{ paddingBottom: "36px" }}>
          <p style={{ marginBottom: "10px" }}>
            · 상담신청 시 실제 여행객 성함으로 기재해주세요.
          </p>
          <p style={{ marginBottom: "10px" }}>
            · 상품에 대한 환불은 상품상세정보를 참조해주세요.
          </p>
          <p style={{ marginBottom: "10px" }}>
            · 문의사항은 1533-7901로 부탁드립니다.
          </p>
          <p style={{ marginBottom: "10px" }}>
            · C/S 운영시간은 평일 오전 10시 ~ 17시 입니다.
          </p>
          <div style={{ marginTop: "22px" }}>
            {policy_list &&
              policy_list.map((policy: { txt: string }, i: number) => (
                <DocumentBox key={policy.txt} color="#0074FF">
                  <img src="/img/doc_blue.svg" alt="document" />
                  <Policy
                    onClick={() => {
                      handleModal(i);
                    }}
                  >
                    {policy.txt}
                  </Policy>
                </DocumentBox>
              ))}
          </div>
          <div
            style={{
              marginTop: "26px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CheckBox
              type="checkbox"
              name="agree"
              onChange={() => {
                setCheck(!check);
              }}
            />
            <label>상기 내용에 모두 동의합니다.</label>
          </div>
        </ContentBox>
      </div>

      <Button color="#2D40FF" onClick={handleSubmit}>
        상담신청
      </Button>
    </Container>
  );
}

import { cleanup } from "@testing-library/react";
import axios from "axios";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import EventNoResult from "../common/EventNoResult";

const Container = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1200px;
  margin-top: 100px;

  a {
    text-decoration: none;
  }

  @media screen and (max-width: 768px) {
    margin-top: 60px;
  }
`;

const Title = styled.h3`
  width: fit-content;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
  color: #161a3f;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -1.2px;

  @media screen and (max-width: 768px) {
    margin: initial;
    padding: 0 16px;
    font-size: 20px;
    letter-spacing: -0.8px;
  }
`;

const CardContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 585px 585px;
  gap: 30px;
  padding: 30px 0 40px;

  .wrapper {
    width: 100%;
    max-height: 230px;
    aspect-ratio: 585 / 230;
  }

  .event_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
  }

  @media screen and (max-width: 1200px) {
    display: flex;
    padding: 30px 16px 40px;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 20px 16px 30px;
  }

  @media screen and (max-width: 480px) {
    gap: 10px;
    .wrapper {
      max-height: 140px;
      aspect-ratio: 358 / 140;
    }
  }
`;

export const EventListMoveBtn = styled.button`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 220px;
  padding: 12px 0;
  border-radius: 100px;
  border: 1px solid #a8b0ff;
  background: #fff;

  color: #6f7cff;
  font-size: 14px;
  letter-spacing: -0.56px;

  background-color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #f3f5ff;
  }
`;

export const EventNoResultAtom = atom(false);
export const EventListAtom = atom<any>([]);
export default function HomeEvent() {
  const navigate = useNavigate();
  const setNoResult = useSetAtom(EventNoResultAtom);
  const [eventList, setEventList] = useAtom(EventListAtom);
  const noResult = useAtomValue(EventNoResultAtom);

  const getList = async () => {
    const res = await axios.get("/event/product/list");
    if (res.data.data.size === 0) {
      return setNoResult(true);
    } else {
      setNoResult(false);
    }
    // console.log(res.data.data.list);

    const sorted_list = res.data.data.list.sort(
      (a: any, b: any) => a.product_order - b.product_order
    );

    setEventList(
      sorted_list.filter((e: any) => e.product_name.includes("[진행중]"))
    );
  };

  useEffect(() => {
    cleanup();
    getList();
  }, []);

  return (
    <Container>
      <Title>진행중인 이벤트</Title>
      <CardContainer>
        {noResult ? (
          <EventNoResult />
        ) : (
          eventList.slice(0, 4).map((item: any, i: number) => (
            <div key={i + item} className="wrapper">
              <img
                className="event_img"
                src={item.product_banner_image}
                alt="event_product"
                onClick={() => navigate(`/eventlist/${item.product_seq}`)}
              />
            </div>
          ))
        )}
      </CardContainer>

      <Link to={`/eventlist`}>
        <EventListMoveBtn>
          이벤트 리스트 더보기
          <img src="/img/arrow_right.svg" alt="show_more_hotel" />
        </EventListMoveBtn>
      </Link>
    </Container>
  );
}

import { styled } from "styled-components";
import ImageGallery, { allAtom } from "../components/ImageGallery";
import PriceDatePicker, {
  deadlineAtom,
  selectedAtom,
} from "../components/PriceDatePicker";
import { ShowMore } from "./Hotel";
import { useAtomValue, useSetAtom } from "jotai";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { format } from "date-fns";

const Container = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1200px;
  margin-top: 45px;
  margin-bottom: 120px;

  .top_content {
    display: flex;
    justify-content: space-between;
  }

  .more {
    margin: 6px 0 0;
    display: flex;
    justify-content: end;
  }

  .hotel_name {
    margin-top: 0;
    margin-bottom: 16px;
    color: #161a3f;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.96px;

    .sub {
      color: #06f;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.56px;
    }
  }
  @media screen and (max-width: 1200px) {
    .top_content {
      display: block;
    }
  }
  @media screen and (max-width: 680px) {
    .more {
      margin: 12px 0;
      padding: 0 16px;
    }
    .hotel_name {
      font-size: 20px;
      padding: 0 16px;
    }
  }
`;

const InfoContainer = styled.div`
  width: 100%;
  display: flex;

  gap: 20px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 680px) {
    gap: 30px;
    padding: 0 16px;
  }
`;

const Table = styled.table`
  width: 100%;
  height: fit-content;
  border-collapse: collapse;
  border: 1px solid #eeeff3;
  border-right: 0;
  border-bottom: 0;

  tr {
    color: #161a3f;
    font-size: 14px;
    letter-spacing: -0.56px;

    td {
      padding: 10px 15px;
      border-right: 1px solid #eeeff3;
      border-bottom: 1px solid #eeeff3;
    }
    td:first-of-type {
      padding: 10px 0;
      width: 125px;
      background: #fcfcfc;
      text-align: center;
    }
    .air {
      display: flex;
      gap: 5px;
    }
    .strike {
      text-decoration: line-through;
    }
    .coupon_price {
      color: #e20000;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 480px) {
    tr {
      font-size: 13px;

      td:first-of-type {
        width: 110px;
      }
    }
  }
`;

const Calendar = styled.div`
  width: 100%;
  max-width: 472px;

  .status {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding-top: 18px;

    div {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .box {
      margin: 0;
      width: 16px;
      height: 16px;
      border-radius: 2px;
    }
    span {
      font-size: 12px;
    }
    .today {
      border: 1px solid #2d40ff;
    }
    .selected {
      background: #2d40ff;
    }
    .low_price {
      border: 1px solid #e20000;
    }
    .closed {
      background: #e3e4ed;
    }
  }

  @media screen and (max-width: 1200px) {
    .status {
      padding: 16px 16px;
    }
  }
`;

const RadioBox = styled.div`
  .container {
    display: flex;
    background: #fcfcfc;
    margin: 24px 0;
    padding: 24px 14px;
  }
  h3 {
    margin: 0;
    padding: 0 16px;
    color: #161a3f;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.64px;
  }

  label {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
  }
  input {
    margin: 0;
    width: 18px;
    height: 18px;
  }
  .select {
    display: flex;
    gap: 16px;

    position: relative;
    padding: 0 16px;

    span {
      color: #161a3f;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.56px;
    }
  }
  .select::after {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    width: 1px;
    height: 16px;
    background: #e4e5e9;
  }

  @media screen and (max-width: 480px) {
    padding: 0 16px;
    .container {
      padding: 20px 16px;
    }
    h3 {
      font-size: 14px;
      padding: 0 10px;
    }

    input {
      width: 16px;
      height: 16px;
    }
    .select {
      font-size: 13px;
      padding: 0 10px;
      gap: 12px;
    }
  }
`;

const NoProduct = styled.div`
  margin: 24px 0;
  display: flex;
  align-items: start;
  gap: 4px;

  p {
    margin: 0;
    color: #e20000;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.56px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    text-decoration: none;
  }

  @media screen and (max-width: 480px) {
    padding: 0 16px;
  }
`;
const Card = styled.div`
  display: flex;
  padding: 20px 30px;
  border: 1px solid #eeeff3;
  line-height: 28px;

  .info {
    width: 100%;
    max-width: 900px;

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      margin: 10px 0 0;
      padding: 0;
    }
    li {
      list-style-position: inside;
      color: #5c5f79;
      font-size: 12px;
      letter-spacing: -0.48px;
      span:first-of-type {
        margin-left: -6px;
        margin-right: 4px;
        color: #161a3f;
      }
    }
    li::marker {
      color: #161a3f;
      font-size: 12px;
    }
  }
  .title {
    margin: 0;
    color: #161a3f;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.64px;

    span:first-of-type {
      border-radius: 3px;
      background: #00c3d1;
      padding: 4px 10px;
      margin-right: 8px;

      color: #fff;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.56px;
    }
  }
  .price {
    text-align: center;
    p {
      margin: 0;
      color: #161a3f;
      font-size: 16px;
      letter-spacing: -0.64px;
      text-decoration: line-through;
    }
    span {
      color: #e20000;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -0.56px;
    }
    span: first-of-type {
      font-size: 14px;
      padding-right: 10px;
    }
    .no_dis {
      visibility: hidden;
    }
    .no_discount {
      color: #161a3f;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 20px 16px;
    flex-direction: column;
    align-items: end;
    gap: 14px;
    .info {
      ul {
        gap: 8px 10px;
      }
    }
  }
`;

export default function AirtelDays() {
  const setAll = useSetAtom(allAtom);
  const id = useParams().id;
  const [detail, setDetail] = useState<any>({});
  const img_list = { images: detail.img_list };
  const [dayList, setDayList] = useState<any>([]);
  const selected = useAtomValue(selectedAtom);
  const deadline = useAtomValue(deadlineAtom);
  const [check, setCheck] = useState("all");

  const getDetail = async () => {
    const month = format(new Date(), "M");
    const res = await axios.get(
      `/product/airtel/detail?client_id=1&airtel_banner_seq=${id}&month=${month}`
    );

    // console.log(res.data.data);
    setDetail(res.data.data);
  };

  const getList = async () => {
    const res = await axios.get(
      `/product/airtel/daylist?client_id=1&airtel_banner_seq=${id}&calendar_date=${selected}`
      // `/product/airtel/daylist?client_id=1&airtel_banner_seq=${id}&calendar_date=2023-09-01`
    );
    // console.log(res.data.data);

    setDayList(
      check === "all"
        ? res.data.data.sort((a: any, b: any) => a.order_by - b.order_by)
        : res.data.data
            .filter((item: any) => item.airtel_date === check)
            .sort((a: any, b: any) => a.order_by - b.order_by)
    );
  };

  useEffect(() => {
    getDetail();
    getList();
  }, []);

  useEffect(() => {
    if (new Date(selected) < new Date()) return;
    getList();
  }, [selected, check]);

  return (
    <Container>
      <div className="top_content">
        <div>
          <ImageGallery list={img_list} />
          <div className="more">
            <ShowMore
              onClick={() => {
                setAll(true);
              }}
            >
              <img src="/img/more_photo.svg" alt="show_more_photo" />
              사진 모두 보기
            </ShowMore>
          </div>
        </div>
        <Calendar>
          <PriceDatePicker calendar={detail.calendar} />
          <div className="status">
            <div>
              <p className="box today" />
              <span>오늘</span>
            </div>
            <div>
              <p className="box selected" />
              <span>선택한 날짜</span>
            </div>
            <div>
              <p className="box low_price" />
              <span>최저가</span>
            </div>
            <div>
              <p className="box closed" />
              <span>마감</span>
            </div>
          </div>
        </Calendar>
      </div>

      <h3 className="hotel_name">
        <div>{detail.title}</div>
        <div className="sub">{detail.subtitle}</div>
      </h3>

      <InfoContainer>
        <Table>
          <tbody>
            <tr>
              <td>항공선택</td>
              <td className="air">
                {detail.airline?.split(",").map((item: string) => (
                  <img key={item} src={`/img/air_${item}.svg`} alt={item} />
                ))}
              </td>
            </tr>
            <tr>
              <td>여행일정</td>
              <td>{detail.airtel_date}</td>
            </tr>
            <tr>
              <td>상세지역</td>
              <td>상품참고</td>
            </tr>
            <tr>
              <td className={detail.discount_price > 0 ? "strike" : ""}>
                원가
              </td>
              <td className={detail.discount_price > 0 ? "strike" : ""}>
                {detail.origin_price?.toLocaleString()}~
              </td>
            </tr>
            <tr>
              <td>할인가</td>
              <td className="coupon_price">
                {detail.origin_price > 0 &&
                  detail.discount_price?.toLocaleString() + "~"}
              </td>
            </tr>
          </tbody>
        </Table>
      </InfoContainer>

      <RadioBox>
        <div className="container">
          <h3>여행일정 선택</h3>
          <div className="select">
            <label>
              <input
                type="radio"
                name="nights"
                value="all"
                checked={check === "all"}
                onChange={(e: any) => {
                  setCheck(e.target.value);
                }}
              />
              <span>전체선택</span>
            </label>

            {detail.airtel_date?.split(",").map((item: any, i: number) => (
              <label key={i + item}>
                <input
                  type="radio"
                  name="nights"
                  value={item}
                  checked={check === item}
                  onChange={(e: any) => {
                    setCheck(e.target.value);
                  }}
                />
                <span>{item}</span>
              </label>
            ))}
          </div>
        </div>
      </RadioBox>

      {deadline ? (
        <NoProduct>
          <img src="/img/warning.svg" alt="no_available_rooms" />
          <p>
            선택하신 날짜에 이용 가능한 상품이 없습니다. 다른 날짜를 선택해
            주세요.
          </p>
        </NoProduct>
      ) : (
        <CardContainer>
          {dayList.map((item: any, i: number) => (
            <Link key={item.airtel_seq + i} to={`/airtel/${item.airtel_seq}`}>
              <Card>
                <div className="info">
                  <h3 className="title">
                    <span>인기상품</span>
                    <span>{item.title}</span>
                  </h3>
                  <ul>
                    <li>
                      <span>이용항공</span>
                      <span>{item.airline}</span>
                    </li>
                    <li>
                      <span>여행일정</span>
                      <span>{item.airtel_date}</span>
                    </li>
                    <li>
                      <span>판매기간</span>
                      <span>출발 10일전</span>
                    </li>
                    <li>
                      <span>인천출발</span>
                      <span>{item.start_text}</span>
                    </li>
                    <li>
                      <span>인천도착</span>
                      <span>{item.end_text}</span>
                    </li>
                  </ul>
                </div>

                {item.discount_price > 0 ? (
                  <div className="price">
                    <p>{item.origin_price.toLocaleString()}원 ~</p>
                    <div>
                      <span>할인가</span>
                      <span>{item.discount_price.toLocaleString()}원 ~</span>
                    </div>
                  </div>
                ) : (
                  <div className="price">
                    <p className="no_dis">{item.origin_price} ~</p>
                    <div>
                      <span className="no_discount">정상가</span>
                      <span className="no_discount">
                        {item.origin_price.toLocaleString()}원 ~
                      </span>
                    </div>
                  </div>
                )}
              </Card>
            </Link>
          ))}
        </CardContainer>
      )}
    </Container>
  );
}

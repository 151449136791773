import { styled } from "styled-components";
import { SearchButton } from "../components/RoomSearch";
import SearchInput, {
  destAtom,
  inputFocusAtom,
} from "../components/SearchInput";
import DatePicker, { dateRangeAtom } from "../components/DatePicker";
import {
  InputBox,
  defaultSelectedAtom,
  occupancyAtom,
  pageAtom,
} from "../components/SearchForm";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useRef, useState } from "react";
import Occupancy from "../components/Occupancy";
import { format } from "date-fns";
import { occuListAtom } from "../components/Occupancy";
import { useNavigate } from "react-router-dom";

const Conatiner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 480px;
  background: url("/img/home_search_bg.png") no-repeat center;
  background-size: cover;

  .container {
    width: 100%;
  }
  .title {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-bottom: 40px;

    h3 {
      margin: 0;
      font-size: 36px;
      font-weight: 700;
      letter-spacing: -1.08px;
    }
    h3:last-of-type {
      color: #2d40ff;
    }
  }
  .mb {
    margin-bottom: 0;
  }

  @media screen and (max-width: 680px) {
    height: 500px;
    background: url("/img/search_background_mobile.svg") no-repeat center;
    background-size: cover;
    .container {
      padding: 0 16px;
    }
    .title {
      flex-direction: column;
      gap: 4px;
      margin-bottom: 60px;

      h3 {
        font-size: 24px;
        letter-spacing: -0.72px;
      }
    }
  }
`;

const SearchContainer = styled.div`
    margin: auto;
    width: 100%;
    max-width: 1200px;
    padding: 28px 78px;
    display: flex;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    background: #fff;
  
    .input_wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
  
      .search_input { 
        div:first-of-type { 
          margin-bottom: 0;
          margin-top: 0;
        }
        width: 100%;
      }
    }
  
    @media screen and (max-width: 840px) {
      flex-direction:column;
      gap: 16px;
      padding: 24px 20px;
  
      .icon {
        top: 13px;
      }
  
      .input_wrapper {
        flex-direction:column;
        gap 8px;
  
        @media screen and (max-width: 480px) {
  
  
          .search_input { 
            input {
              padding: 8px 10px 8px 40px;
            }
          }
        }
      }
    }
  `;

const InputContainer = styled(InputBox)`
  margin-top: 0;
  width: 100%;

  @media screen and (max-width: 480px) {
    input {
      height: 45px;
      padding: 8px 10px 8px 40px;
    }
  }
`;
const SearchBtn = styled(SearchButton)`
  margin: 0;
  align-items: center;

  @media screen and (max-width: 840px) {
    width: 100%;
    padding: 12px 0;
  }
`;

export default function HomeSearch() {
  const navigate = useNavigate();
  const [focused, setFocused] = useState(false);
  const [inputFocus, setInputFocus] = useAtom(inputFocusAtom);
  const [dateRange, setDateRange] = useAtom(dateRangeAtom);
  const [occupancy, setOccupancy] = useAtom(occupancyAtom);
  const [defaultSelected, setDefault] = useAtom(defaultSelectedAtom);
  const dest = useAtomValue(destAtom);
  const occuList = useAtomValue(occuListAtom);
  const dateRef = useRef<HTMLDivElement>(null);

  const clickDateOutside = (e: any) => {
    dateRef.current && !dateRef.current.contains(e.target)
      ? setFocused(false)
      : setFocused(!focused);
  };

  const handleSearch = () => {
    if (!dest.lng && !dest.lat) return alert("여행지를 입력해 주세요.");
    const check_in =
      defaultSelected?.from && format(defaultSelected.from, "yyyy-MM-dd");
    const check_out =
      defaultSelected?.to && format(defaultSelected.to, "yyyy-MM-dd");
    const occu_list = occuList
      .map(
        (item: any) =>
          `&occupancy=${item.adults}${
            item.children.length !== 0 ? "-" + item.children : ""
          }`
      )
      .join("");

    navigate(
      `/search?search_type=${dest.region_type}&lng=${dest.lng}&lat=${dest.lat}&category_type=GENERAL&device=ANDROID${occu_list}&check_in=${check_in}&check_out=${check_out}&dest=${dest.name}`
    );
  };

  useEffect(() => {
    document.addEventListener("click", clickDateOutside);
    return () => {
      document.removeEventListener("click", clickDateOutside);
    };
  }, []);

  useEffect(() => {
    if (focused) setOccupancy(false);
    if (inputFocus) {
      setOccupancy(false);
      setInputFocus(!inputFocus);
    }
  }, [focused, inputFocus]);

  useEffect(() => {
    if (defaultSelected?.from && defaultSelected?.to) {
      setDateRange(
        format(defaultSelected.from, "yyyy-MM-dd") +
          "\u00A0~" +
          "\u00A0" +
          format(defaultSelected.to, "yyyy-MM-dd")
      );
    }
  }, []);

  return (
    <Conatiner>
      <div className="container">
        <div className="title">
          <h3>영상으로 미리보는</h3>
          <h3>똑똑한 여행! 와플스테이!</h3>
        </div>
        {/* <SearchContainer>
          <div className="input_wrapper">
            <div className="search_input">
              <SearchInput />
            </div>
            <InputContainer ref={dateRef}>
              <img
                className="icon"
                src="/img/calendar.svg"
                alt="check_in&check_out"
              />
              <input
                type="text"
                placeholder="체크인-체크아웃"
                value={dateRange}
                readOnly
              />
              {focused && <DatePicker setFocused={setFocused} />}
            </InputContainer>

            <InputContainer
              onClick={() => {
                setOccupancy(true);
              }}
            >
              <img
                className="icon"
                src="/img/person.svg"
                alt="room_occupancy"
              />
              <div className="occupancy">
                객실 {occuList.length}개, 인원수{" "}
                {occuList
                  .map((item: any) => item.adults)
                  .reduce((a: number, b: number) => a + b) +
                  occuList
                    .map((item: any) => item.children.length)
                    .reduce((a: number, b: number) => a + b)}
                명
              </div>
              {occupancy && <Occupancy />}
            </InputContainer>
          </div>

          <SearchBtn className="mb" onClick={handleSearch}>
            <img className="icon web" src="/img/search.svg" alt="search" />
            <span>호텔 검색</span>
          </SearchBtn>
        </SearchContainer> */}
      </div>
    </Conatiner>
  );
}

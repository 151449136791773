import { Link, useLocation } from "react-router-dom";
import { styled } from "styled-components";
import { EventListMoveBtn } from "../home_components/event/HomeEvent";
import axios from "axios";
import { useEffect, useState } from "react";

const Container = styled.div<{ $mg?: boolean }>`
  margin: auto;
  width: 100%;
  max-width: 1200px;
  margin-top: ${(props) => (props.$mg ? "85px" : "100px")};
  margin-bottom: ${(props) => props.$mg && "40px"};

  a {
    text-decoration: none;
  }

  h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 0;
    color: #161a3f;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -1.2px;
  }

  @media screen and (max-width: 768px) {
    margin-top: ${(props) => (props.$mg ? "24px" : "60px")};
  }

  @media screen and (max-width: 480px) {
    padding: 0 16px;

    h3 {
      font-size: 20px;
      justify-content: ${(props) => !props.$mg && "start"};

      img {
        width: 70px;
        height: 30px;
      }
    }

    .scroll::-webkit-scrollbar {
      display: none;
    }
  }
`;

const CardContainer = styled.div<{ $scr: boolean }>`
  width: 100%;
  max-width: 1200px;
  padding: 30px 0 40px;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 380px);
  gap: 30px;

  // @media screen and (max-width: 480px) {
  //   max-width: ${(props) => !props.$scr && "360px"};
  //   grid-template-columns: repeat(auto-fill, 360px);
  //   display: ${(props) => !props.$scr && "flex"};
  //   justify-content: ${(props) => !props.$scr && "initial"};
  //   padding: ${(props) => !props.$scr && "20px 0 30px"};
  //   gap: ${(props) => (!props.$scr ? "16px" : "20px")};
  //   overflow-x: ${(props) => !props.$scr && "scroll"};
  // }

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(auto-fill, 360px);
    gap: 20px;
    padding: 20px 0 30px;
  }
`;

const Card = styled.div<{ $scr: boolean }>`
  width: 100%;
  max-width: 380px;
  height: 100%;

  .img_wrap {
    width: 380px;
    height: 288px;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .contents {
    border: 1px solid #eeeff3;
    padding: 0 20px 24px;
  }
  .header {
    padding: 16px 0;
    color: #161a3f;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.72px;
  }
  .title {
    padding-bottom: 4px;
  }
  .sub {
    color: #06f;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.52px;
  }
  .content {
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 4px 0;

    img {
      width: 45px;
    }
    .airline {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .no_img {
      border: 1px solid #eeeff3;
      padding: 1px 6px;
      color: #161a3f;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: -0.52px;
    }
  }
  span {
    color: #5c5f79;
    font-size: 13px;
    letter-spacing: -0.52px;
  }
  .name {
    width: 47px;
  }
  .strike {
    text-decoration: line-through;
  }
  .sale {
    color: #e20000;
  }
  .price {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.8px;
  }

  // @media screen and (max-width: 480px) {
  //   max-width: ${(props) => !props.$scr && "250px"};

  //   .img_wrap {
  //     width: ${(props) => (!props.$scr ? "250px" : "360px")};
  //     height: 233px;
  //   }
  //   .content {
  //     img {
  //       width: 40px;
  //       height: 16px;
  //     }
  //   }
  //   .header {
  //     font-size: 16px;
  //   }
  //   .price {
  //     font-size: 18px;
  //   }
  // }

  @media screen and (max-width: 480px) {
    .img_wrap {
      width: 360px;
      height: 233px;
    }
    .content {
      img {
        width: 40px;
        height: 16px;
      }
    }
    .header {
      font-size: 16px;
    }
    .price {
      font-size: 18px;
    }
  }
`;

export default function HomeAirtel() {
  const airline_list = ["대한항공", "제주항공", "진에어", "티웨이항공"];
  const airtellist = useLocation().pathname.includes("airtellist");
  const [list, setList] = useState<any>([]);

  const getList = async () => {
    const res = await axios.get(
      "/product/list?client_id=1&product_sort=AIRTEL&size=10&page=1"
    );
    // console.log(res.data.data.airtel_list);

    airtellist
      ? setList(res.data.data.airtel_list)
      : setList(res.data.data.airtel_list.slice(0, 6));
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Container $mg={airtellist}>
      <h3>
        <span>추천 리조트 & 항공</span>
        <img src="/img/airtel.svg" alt="airtel" />
        <span>상품</span>
      </h3>

      <CardContainer className="scroll" $scr={airtellist}>
        {list.map((item: any, i: number) => (
          <Link
            key={i + item.title}
            to={`/airteldays/${item.airtel_banner_seq}`}
          >
            <Card $scr={airtellist}>
              <div className="img_wrap">
                <img src={item.main_img} alt={item.title} />
              </div>

              <div className="contents">
                <div className="header">
                  <div className="title">{item.title}</div>
                  <div className="sub">{item.subtitle}</div>
                </div>

                <div>
                  <div className="content">
                    <span className="name">항공편</span>
                    <div className="airline">
                      {item.airline.split(",").map((el: any) =>
                        airline_list.includes(el) ? (
                          <img
                            key={el}
                            src={`/img/air_${el}.svg`}
                            alt="airline"
                          />
                        ) : (
                          <div key={el} className="no_img">
                            {el}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className="content">
                    <span className="name">여행일정</span>
                    <span>{item.airtel_date}</span>
                  </div>
                  <div className="content">
                    <span className="name">상세지역</span>
                    <span>상세정보 참조</span>
                  </div>
                  <div className="content">
                    <span
                      className={`name ${
                        item.discount_price > 0 ? "strike" : ""
                      }`}
                    >
                      원가
                    </span>
                    <span className={item.discount_price > 0 ? "strike" : ""}>
                      {item.origin_price.toLocaleString()}~
                    </span>
                  </div>
                  <div className="content">
                    <span className="name sale">할인가</span>
                    <span className="sale price">
                      {item.discount_price > 0 &&
                        item.discount_price.toLocaleString() + "~"}
                    </span>
                  </div>
                </div>
              </div>
            </Card>
          </Link>
        ))}
      </CardContainer>

      {!airtellist && (
        <Link to={`/airtellist`}>
          <EventListMoveBtn>
            에어텔 상품 더보기
            <img src="/img/arrow_right.svg" alt="show_more_deal" />
          </EventListMoveBtn>
        </Link>
      )}
    </Container>
  );
}

import { styled } from "styled-components";
import animationData from "../success-icon.json";
import { LottieRefCurrentProps } from "lottie-react";
import { CheckMark } from "./Success";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Container = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1200px;
  margin-top: 180px;
  margin-bottom: 180px;

  @media screen and (max-width: 768px) {
    margin-top: 100px;
    padding: 0 16px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 480px;
  margin: auto;
  padding: 60px 40px 60px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(105, 88, 142, 0.1);
  border-radius: 10px;

  h3 {
    color: #122437;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: -0.84px;
  }

  .ct {
    color: #122437;
    text-align: center;
    font-size: 14px;
    letter-spacing: -0.42px;
  }
`;

const Button = styled.button`
  display: block;
  margin: auto;
  margin-top: 50px;
  width: 100%;

  padding: 12px 0;
  border-radius: 5px;
  background: #2d40ff;
  color: #fff;
  font-size: 14px;
  letter-spacing: -0.42px;
  border: none;
  cursor: pointer;
`;

export default function AccountSuccess() {
  const navigate = useNavigate();
  const signup = useLocation().pathname.includes("signup");
  const change = useLocation().pathname.includes("change");
  const lottieRef = useRef<LottieRefCurrentProps>();

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(1.4);
    }
  }, []);

  return (
    <Container>
      {signup ? (
        <ContentContainer>
          <CheckMark
            animationData={animationData}
            lottieRef={lottieRef as any}
            loop={false}
          />
          <h3>회원가입완료</h3>
          <div>
            <div className="ct">회원가입이 완료되었습니다.</div>
            <div className="ct">로그인 후 여행을 시작해보세요.</div>
          </div>

          <Button
            onClick={() => {
              navigate(`/login`, { state: "main" });
            }}
          >
            로그인
          </Button>
        </ContentContainer>
      ) : (
        <ContentContainer>
          <CheckMark
            animationData={animationData}
            lottieRef={lottieRef as any}
            loop={false}
          />
          <h3>{change ? "비밀번호 변경 완료" : "비밀번호 재설정 완료"}</h3>
          <div>
            <div className="ct">비밀번호 변경이 완료되었습니다.</div>
            <div className="ct">다시 로그인해 주세요.</div>
          </div>

          <Button
            onClick={() => {
              navigate(`/login`, { state: "main" });
            }}
          >
            로그인
          </Button>
        </ContentContainer>
      )}
    </Container>
  );
}

import { useSetAtom } from "jotai";
import { useState } from "react";
import { styled } from "styled-components";
import { modalAtom } from "../../../components/ImageGallery";
import { useLocation } from "react-router-dom";
import { tempModalAtom } from "../Temp";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 101;
  background-color: #0c0e1c;

  .wrapper {
    height: 100%;
    min-height: 666px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box {
    width: 100%;
    max-width: 1200px;
    aspect-ratio: 12 / 8;
  }

  .carousel_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0;
  }

  .close {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    background: transparent url("/img/close_wh.svg") no-repeat center;
    border: none;
    cursor: pointer;
  }

  .btn_box {
    position: absolute;
    top: 50%;
    bottom: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 36px;

    button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: none;
        cursor: pointer;
    }

    @media screen and (max-width: 768px) { 
      top: initial;
      bottom: 20%;
    }

  }
  .left {
      transform: rotate(180deg);
      background: #fff url("/img/arrow_right.svg") no-repeat center;
      background-size: 30px;
    }
    .right {
      background: #fff url("/img/arrow_right.svg") no-repeat center;
      background-size: 30px;
    }
  }
`;

// const CaptionContainer = styled.div`
//   position: absolute;
//   bottom: 0;
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
//   padding: 20px 0;

//   span {
//     color: #fff;
//     padding: 0 16px;
//   }
// `;

interface ImgCarouselProps {
  list: { image_seq: number; image_url: string }[];
}

export default function TempImgCarousel({ list }: ImgCarouselProps) {
  const [index, setIndex] = useState(0);
  const setTempModal = useSetAtom(tempModalAtom);

  const handleCarousel = (e: any) => {
    if (e.target.className.includes("left")) {
      index !== 0 && setIndex(index - 1);
    } else {
      list.length - 1 > index && setIndex(index + 1);
    }
  };

  return (
    <Container>
      <button
        className="close"
        onClick={() => {
          setTempModal(false);
        }}
      />
      <div className="btn_box">
        <button className="arrow left" onClick={handleCarousel} />
        <button className="arrow right" onClick={handleCarousel} />
      </div>
      <div className="wrapper">
        <div className="box">
          <img
            className="carousel_img"
            src={list[index].image_url}
            alt="detail"
          />
        </div>
      </div>
    </Container>
  );
}

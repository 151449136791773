import { styled } from "styled-components";
import ImgCarousel from "./ImgCarousel";
import { atom, useAtom, useAtomValue } from "jotai";
import AllPhoto from "./AllPhoto";
import { useLocation } from "react-router-dom";

const Container = styled.div<{ $grid: boolean }>`
  position: relative;
  width: 100%;
  display: ${(props) => !props.$grid && "grid"};
  justify-content: center;
  grid-template-columns: 680px 250px 250px;
  grid-template-rows: 170px 170px;
  gap: 10px;

  .gallery {
    width: ${(props) => (props.$grid ? "700px" : "680px")};
    height: ${(props) => (props.$grid ? "450px" : "350px")};
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
  }
  .gallery:not(:first-of-type) {
    width: 250px;
    height: 170px;
  }
  .gallery:nth-child(1) {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .gallery:nth-child(2) {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .gallery:nth-child(3) {
    grid-column-start: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .gallery:nth-child(4) {
    grid-column-start: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .gallery:nth-child(5) {
    grid-column-start: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: 680px 250px;

    .gallery:nth-child(3),
    .gallery:nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 680px) {
    display: block;
    width: 100%;
    padding: 0 16px;
    .gallery {
      width: 100%;
      height: 100%;
    }
    .gallery:not(:first-of-type) {
      display: none;
    }
  }
`;

export const modalAtom = atom(false);
export const allAtom = atom(false);
export const carouselAtom = atom(0);
export default function ImageGallery({ list }: any) {
  const airtellist = useLocation().pathname.includes("airtel");
  // const imgList = list?.images.map((item: any) => item.links["1000px"].href);
  const all = useAtomValue(allAtom);
  const [modal, setModal] = useAtom(modalAtom);
  const [carouselIdx, setCarouselIdx] = useAtom(carouselAtom);

  return (
    <Container $grid={airtellist}>
      {all && <AllPhoto list={list} />}
      {modal && <ImgCarousel list={list} idx={carouselIdx} />}
      {list.images?.slice(0, airtellist ? 1 : 5).map((item: any, i: number) => (
        <img
          className="gallery"
          key={airtellist ? item.seq + i : item + i}
          src={airtellist ? item.image_url : item.links["1000px"].href}
          alt={airtellist ? `image${i}` : list.images[i].caption}
          onClick={() => {
            setModal(true);
            setCarouselIdx(i);
          }}
        />
      ))}
    </Container>
  );
}

import React from "react";
import { styled } from "styled-components";
import { useAtom } from "jotai";
import { childrenListAtom } from "./RoomNum";
import { occuListAtom } from "./Occupancy";

const Container = styled.div`
  span {
    font-weight: 700;
  }
  .qty {
    width: fit-content;
    display: flex;
    align-items: center;
  }
  .num {
    width: 42px;
    height: 28px;
    padding: 4px 0;
    text-align: center;
    border: 0;
  }
  button {
    width: 28px;
    height: 28px;
    padding: 0;
    border: none;
    background: initial;
    cursor: pointer;

    &:hover:not(.dec_blocked, .inc_blocked) {
      border: 1px solid #72aaff;
    }
  }
  .dec {
    background: url("/img/dec.svg");
    background-position: center;
    border: 1px solid #e5e5e5;
    border-radius: 14px;
  }
  .dec_blocked {
    background: url("/img/dec_blocked.svg");
    background-position: center;
    border: 1px solid #e5e5e5;
    border-radius: 14px;
  }
  .inc {
    background: url("/img/inc.svg");
    background-position: center;
    border: 1px solid #e5e5e5;
    border-radius: 14px;
  }
  .inc_blocked {
    background: url("/img/inc_blocked.svg");
    background-position: center;
    border: 1px solid #e5e5e5;
    border-radius: 14px;
  }
`;

interface GuestNumProsp {
  name: string;
  num?: number;
  c_num?: number[];
  idx: number;
}

export default function GuestNum({ name, num, c_num, idx }: GuestNumProsp) {
  const [occuList, setOccuList] = useAtom(occuListAtom);

  return (
    <Container>
      <div className="qty">
        <button
          className={num === 0 || c_num?.length === 0 ? "dec_blocked" : "dec"}
          onClick={() => {
            if (num === 0 || c_num?.length === 0) return;
            let arr = [...occuList];
            name === "adults" && arr[idx].adults--;
            if (name === "children") {
              arr[idx].children.pop();
              setOccuList([...arr]);
            }
            setOccuList([...arr]);
          }}
        />
        <div className="num">{num || c_num?.length}</div>
        <button
          className={
            (name === "adults" && num === 14) ||
            (name === "children" && c_num?.length === 6)
              ? "inc_blocked"
              : "inc"
          }
          onClick={() => {
            if (name === "adults" && num === 14) return;
            if (name === "children" && c_num?.length === 6) return;
            let arr = [...occuList];
            name === "adults" && arr[idx].adults++;
            if (name === "children") {
              arr[idx].children.push(0);
              setOccuList([...arr]);
            }
            setOccuList([...arr]);
          }}
        />
      </div>
    </Container>
  );
}

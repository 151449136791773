import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";

const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;

  img {
    width: 100%;
  }
  .masking {
    -webkit-mask-image: linear-gradient(180deg, #000 50%, transparent);
  }

  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const Button = styled.button<{ color: string }>`
  width: 100%;
  height: 45px;
  cursor: pointer;
  margin-top: 10px;
  color: #ffffff;
  background: ${(props) => props.color};
  border: none;
  border-radius: 5px;

  font-size: 14px;
  letter-spacing: -0.42px;
`;

export default function PackageDetail() {
  const airtel = useLocation().pathname.includes("airtel");
  const id = useParams().id;
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [images, setImages] = useState({
    topImg: "",
    imgArr: [],
    bottomImg: "",
  });

  console.log(id);

  const getImgList = async () => {
    const res = await axios.get(
      `/product/detail/imglist?client_id=1&product_sort=${
        airtel ? "AIRTEL" : "PACKAGE"
      }&product_id=${id}`
    );

    // console.log(res.data.data.map((item: any) => item.image_url));
    const image_list = res.data.data.map((item: any) => item.image_url);
    setImages({
      topImg: image_list[0],
      imgArr: image_list.slice(1, -1),
      bottomImg: image_list[image_list.length - 1],
    });
  };

  useEffect(() => {
    getImgList();
  }, []);

  return (
    <Container>
      <img
        className={showMore ? "" : "masking"}
        src={images.topImg}
        alt="top"
      />
      {showMore &&
        images.imgArr.map((item: any, i: number) => (
          <img key={i + item} src={item} alt={`image_${i}`} />
        ))}
      <Button
        color="#ffffff"
        style={{
          border: "2px solid #2D40FF",
          color: "#2D40FF",
          marginBottom: "10px",
        }}
        onClick={() => {
          setShowMore(!showMore);
        }}
      >
        {showMore ? "상세정보 접기" : "상세정보 더보기"}
      </Button>
      <img src={images.bottomImg} alt="bottom" />

      <Button
        color="#2D40FF"
        onClick={() => {
          navigate(
            `/package?product_sort=${airtel ? "airtel" : "package"}&id=${id}`
          );
        }}
      >
        상담신청
      </Button>
    </Container>
  );
}

import { useAtom } from "jotai";
import { ChangeEvent } from "react";
import { styled } from "styled-components";
import { occuListAtom } from "./Occupancy";

const SelectBox = styled.div`
  position: relative;
  padding: 8px;
  padding-left: 0px;
  cursor: pointer;
  border-radius: 8px;

  .select_wrapper {
    width: fit-content;
    padding-right: 12px;
    background-color: #fff;
    border: 1px solid #5d646d;
    border-radius: 8px;
    overflow: hidden;
  }
  label {
    position: absolute;
    top: 14px;
    left: 14px;
    font-size: 12px;
  }
  select {
    width: 106px;
    padding: 24px 10px 6px 10px;
    border: none;
    outline: none;
    background: #fff;
  }
  @media screen and (max-width: 1100px) {
    padding-right: 0;
    .select_wrapper {
      width: 100%;
      padding-right: 8px;
    }
    select {
      width: 100%;
      padding-right: 4px;
    }
  }

  @media screen and (max-width: 480px) {
    .select_wrapper {
      display: flex;
      height: 70px;
      padding: 8px 8px 0;
    }
    select {
      padding: 4px 10px 6px 10px;
    }
  }
`;

interface ItemProps {
  p_idx: number;
  item: number;
  idx: number;
}

export default function ChildrenAge({ p_idx, item, idx }: ItemProps) {
  const [occuList, setOccuList] = useAtom(occuListAtom);
  const age = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];

  const handleChildrenAge = (e: ChangeEvent<HTMLSelectElement>) => {
    let arr = [...occuList];
    arr[p_idx].children[idx] = e.target.value;
    setOccuList(arr);
  };

  return (
    <SelectBox>
      <label>아동 {idx + 1}</label>
      <div className="select_wrapper">
        <select
          name={`아동 ${idx + 1}`}
          value={item}
          onChange={handleChildrenAge}
        >
          {age.map((item, i) => (
            <option key={i} value={item}>
              만 {item}세
            </option>
          ))}
        </select>
      </div>
    </SelectBox>
  );
}

import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { styled } from "styled-components";
import ImageGallery, { allAtom } from "../../components/ImageGallery";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import Rating from "../../components/Rating";
import RoomCard, { RateModalAtom, RoomAtom } from "../../components/RoomCard";
import RoomPhoto from "../../components/RoomPhoto";
import RoomInfo from "../../components/RoomInfo";
import RoomSearch, { RoomSearchAtom } from "../../components/RoomSearch";
import GoogleMaps from "../../components/GoogleMaps";
import TabMenu, { tabAtom, tabMenuAtom } from "../../components/TabMenu";
import RateDetail from "../../components/RateDetail";
import { hotels } from "./hotels";
import TempAnnounce from "./components/TempAnnounce";
import TempImgCarousel from "./components/TempImgCarousel";
import TempRoomInfo from "./components/TempRoomInfo";
import { get } from "http";
import TempRoomCard, { TempRoomAtom } from "./components/TempRoomCard";
import TempRoomPhoto from "./components/TempRoomPhoto";
import TempRoomSearch, {
  TempRoomSearchAtom,
} from "./components/TempRoomSearch";
import { format } from "date-fns";
import { defaultSelectedAtom } from "../../components/SearchForm";

const Container = styled.div`
  position: relative;
  margin: auto;
  margin-top: 45px;
  width: 100%;
  max-width: 1200px;

  .more {
    margin: 16px 0 0;
    display: flex;
    justify-content: end;
  }

  .detailImg {
    margin-top: 30px;

    width: 100%;
    height: 300px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 680px) {
    .more {
      margin: 12px 0;
      padding: 0 16px;
    }
  }
`;

export const ShowMore = styled.button`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 8px 12px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.39px;
  border: none;
  border-radius: 100px;
  background-color: rgba(12, 14, 28, 0.56);
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }

  img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
`;

const Title = styled.div`
  .name {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
  }

  h3 {
    margin: 0;
    font-size: 24px;
    letter-spacing: -0.96px;
  }

  .address {
    color: #5c5f79;
    font-size: 14px;
    letter-spacing: -0.56px;
  }

  @media screen and (max-width: 680px) {
    padding: 0 16px;

    .name {
      align-items: start;
      flex-direction: column;
    }
  }
`;

const NoRooms = styled.div`
  margin: 24px 0;
  display: flex;
  align-items: start;
  gap: 4px;

  p {
    margin: 0;
    color: #e20000;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.56px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 380px);
  justify-content: center;
  gap: 30px;
  margin-top: 20px;

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(auto-fill, 350px);
  }
`;

const Location = styled.div`
  padding-top: 94px;
  h3 {
    color: #161a3f;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.8px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 40px;
    padding: 0 16px;
  }
`;

export const Address = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;

  .loca_wrapper {
    display: flex;
    align-items: start;
    line-height: 19px;
    gap: 4px;
  }

  .copy {
    min-width: 75px;
    padding: 6px 12px;
    color: #2d40ff;
    font-size: 13px;
    letter-spacing: -0.52px;
    border-radius: 5px;
    border: 1px solid #2d40ff;
    background: #fff;
    cursor: pointer;
  }
`;

export const tempModalAtom = atom(false);
export default function Temp() {
  const location = useLocation();
  const setAll = useSetAtom(allAtom);
  const locaRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const company = searchParams.get("company");
  const check_in = searchParams.get("check_in");
  const check_out = searchParams.get("check_out");
  const hsq = searchParams.get("hsq");
  const detail = hotels.find((item) => item.property_id === hsq);
  const [tempModal, setTempModal] = useAtom(tempModalAtom);
  const [noti, setNoti] = useState<any>([]);
  const [marketing, setMarketing] = useState<any>([]);
  const [rooms, setRooms] = useState<any>([]);
  const [noRooms, setNoRooms] = useState(false);
  const roomModal = useAtomValue(TempRoomAtom);
  const [roomSearch, setRoomSearch] = useAtom(TempRoomSearchAtom);
  const search = location.search;
  const defaultSelected = useAtomValue(defaultSelectedAtom);

  const copyAddress = () => {
    navigator.clipboard
      .writeText(`${detail?.address.city + " " + detail?.address.line_1}`)
      .then(() => {
        alert("주소가 복사 되었습니다.");
      });
  };

  const getImg = async () => {
    const noti = await axios.get(`/hp/images?parent_seq=1&parent_type=NOTI`);
    const marketing = await axios.get(
      `/hp/images?parent_seq=1&parent_type=MARKETING`
    );

    setNoti(noti.data.data);
    setMarketing(marketing.data.data);
  };

  const getRoom = async () => {
    noRooms && setNoRooms(false);
    const res = await axios.get(
      `/hp/rooms?checkin=${
        check_in ? check_in : format(defaultSelected.from, "yyyy-MM-dd")
      }&checkout=${
        check_out ? check_out : format(defaultSelected.to, "yyyy-MM-dd")
      }`
    );
    setRooms(res.data.data);
    res.data.data.length === 0 && setNoRooms(true);
  };

  useEffect(() => {
    getImg();
  }, []);

  useEffect(() => {
    if (!company) getRoom();
  }, [search]);

  useEffect(() => {
    if (company) getRoom();
    setRoomSearch(false);
  }, [roomSearch]);

  const getNights = () => {
    return Math.ceil(
      Math.abs(
        new Date(check_in ? check_in : defaultSelected.from).getTime() -
          new Date(check_out ? check_out : defaultSelected.to).getTime()
      ) /
        (1000 * 60 * 60 * 24)
    );
  };

  // console.log(defaultSelected);

  return (
    <Container>
      <ImageGallery list={detail} />
      <div className="more">
        <ShowMore
          onClick={() => {
            setAll(true);
          }}
        >
          <img src="/img/more_photo.svg" alt="show_more_photo" />
          사진 모두 보기
        </ShowMore>
      </div>

      {!hsq && <TabMenu />}

      <Title>
        <div className="name">
          <h3>{detail?.name}</h3>
          {detail?.address && (
            <div className="address">
              {detail?.address.city + " " + detail?.address.line_1}
            </div>
          )}
        </div>
        {detail?.ratings && <Rating mgt="0" item={detail?.ratings} />}
      </Title>

      <TempAnnounce src={noti[0]} />
      {marketing.length > 0 && (
        <div className="detailImg">
          <img
            src={marketing[0]?.image_url}
            alt="마케팅"
            onClick={() => setTempModal(true)}
          />
          {tempModal && <TempImgCarousel list={marketing} />}
        </div>
      )}

      <TempRoomSearch />

      {noRooms && (
        <NoRooms>
          <img src="/img/warning.svg" alt="no_available_rooms" />
          <p>
            선택하신 여행 날짜에 예약 가능한 객실이 없습니다. 다른 날짜를 입력해
            예약 가능 여부를 확인해 보세요.
          </p>
        </NoRooms>
      )}

      {rooms.length > 0 && (
        <CardContainer>
          {rooms.map((item: any) => (
            <TempRoomCard
              key={item.room_seq}
              item={item}
              nights={getNights()}
            />
          ))}
          {roomModal && <TempRoomPhoto />}
        </CardContainer>
      )}

      <Location ref={locaRef}>
        <h3>위치</h3>

        {detail?.location && (
          <GoogleMaps coord={detail?.location.coordinates} />
        )}

        <Address>
          <div className="loca_wrapper">
            <img src="/img/location.svg" alt="location" />
            {detail?.address && (
              <div className="address">
                {detail?.address.city + " " + detail?.address.line_1}
              </div>
            )}
          </div>
          <button className="copy" onClick={copyAddress}>
            주소 복사
          </button>
        </Address>
      </Location>

      <TempRoomInfo />
    </Container>
  );
}

import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import animationData from "../success-icon.json";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-top: 40px;

  .heading {
    padding: 40px 0;
    h3 {
      width: fit-content;
      margin: auto;
      padding: 12px 0;
      color: #161a3f;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.96px;
    }
  }

  .emtpy {
    width: 100%;
    height: 438px;

    animation: skeleton-gradient 1.5s infinite ease-in-out;
    border-radius: 10px;

    @keyframes skeleton-gradient {
      0% {
        background-color: rgba(187, 187, 187, 0.1);
      }
      50% {
        background-color: rgba(187, 187, 187, 0.3);
      }
      100% {
        background-color: rgba(187, 187, 187, 0.1);
      }
    }
  }

  @media screen and (max-width: 680px) {
    margin-top: 0;
    padding: 40px 16px 30px;
  }
`;

export const Content = styled.div`
  position: relative;
  padding: 32px 30px;
  border-radius: 10px;
  background: rgba(247, 247, 252, 0.4);

  h3 {
    margin: 0;
  }
  .first {
    border-bottom: 1px solid #f6f6f9;
  }

  img {
    margin-bottom: 20px;
    padding: 0;
    width: 100%;
  }
`;
export const CheckMark = styled(Lottie)`
  width: 70px;
  height: 70px;
  margin: auto;
`;

export const ContentCard = styled.div`
  padding: 20px 0;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 0;
  }
  span {
    color: #161a3f;
    font-size: 14px;
    letter-spacing: -0.56px;
  }
  .price {
    color: #161a3f;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.8px;
  }
`;

export const HistoryBtn = styled.button`
  display: block;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  max-width: 300px;
  padding: 12px 0;
  border-radius: 5px;
  background: #2d40ff;
  color: #fff;
  font-size: 14px;
  letter-spacing: -0.42px;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    max-width: initial;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

export default function Success() {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/");
  const cancel = path[path.length - 1];
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("itinerary_id");

  const [data, setData] = useState<any>();
  const [payTime, setPayTime] = useState<any>([]);

  const lottieRef = useRef<LottieRefCurrentProps>();

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(1.4);
    }
  }, []);

  const checkReservation = async () => {
    const res = await axios.get(`/itineraries/${id}`);
    setData(res.data.data);
    const date = new Date(res.data.data.creation_date_time);
    date.setHours(date.getHours() + 9);
    setPayTime(date.toISOString().split(".")[0].split("T"));
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });

    if (cancel === "cancel") {
      checkReservation();
    } else {
      checkReservation();
    }
  }, []);

  return (
    <Container>
      <div className="heading">
        <CheckMark
          animationData={animationData}
          lottieRef={lottieRef as any}
          loop={false}
        />
        {cancel === "cancel" ? (
          <h3>예약이 취소되었습니다.</h3>
        ) : (
          <h3>예약이 완료되었습니다.</h3>
        )}
      </div>

      {data ? (
        <Content>
          <h3>{data.hotel.name}</h3>
          <ContentCard className="first">
            <div>
              <span>객실</span>
              <span>{data.rooms[0].room_name}</span>
            </div>
            <div>
              <span>체크인</span>
              <span>{data.rooms[0].checkin}</span>
            </div>
            <div>
              <span>체크아웃</span>
              <span>{data.rooms[0].checkout}</span>
            </div>
            <div>
              <span>예약자 이름</span>
              <span>
                {data.rooms[0].family_name + ", " + data.rooms[0].given_name}
              </span>
            </div>
          </ContentCard>
          <ContentCard>
            {cancel !== "cancel" && (
              <div>
                <span>결제일시</span>
                <span>{payTime[0] + " " + payTime[1]}</span>
              </div>
            )}
            <div>
              <span>{cancel === "cancel" ? "환불수단" : "결제수단"}</span>
              <span>카드결제</span>
            </div>
            <div>
              <span>{cancel === "cancel" ? "환불금액" : "결제금액"}</span>
              <span className="price">
                {"₩ " + data.payment_history.product_amount.toLocaleString()}
              </span>
            </div>
          </ContentCard>
        </Content>
      ) : (
        <Content className="emtpy"></Content>
      )}

      {cancel === "cancel" ? (
        <HistoryBtn
          onClick={() => {
            navigate(`/`);
          }}
        >
          홈으로 돌아가기
        </HistoryBtn>
      ) : (
        <HistoryBtn
          onClick={() => {
            navigate(`/reservation/detail/${id}`);
          }}
        >
          예약내역 보기
        </HistoryBtn>
      )}
    </Container>
  );
}

import React from "react";
import { styled } from "styled-components";

const Container = styled.div`
  width: fit-content;
  padding: 120px 0;
  text-align: center;
  margin: auto;

  h3 {
    margin: 24px 0 12px;
    font-size: 28px;
    color: #161a3f;
  }
  img {
    width: 36px;
  }
  font-size: 16px;
    color: #5c5f79;
    font-size: 16px;
    letter-spacing: -0.48px;
  }

  @media screen and (max-width: 768px) {
    div {
      font-size: 14px;
    }
  }
`;

export default function ReservNoResult() {
    return (
        <Container>
            <img
                src="/img/lens.svg"
                alt="search_lens"
            />
            <h3>검색 결과 없음</h3>
            <div>예약하신 호텔이 없습니다.</div>
        </Container>
    );
}

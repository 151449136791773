import { ChangeEvent, KeyboardEvent, useState } from "react";
import { styled } from "styled-components";
import CryptoJS from "crypto-js";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetAtom } from "jotai";
import { loginAtom } from "../components/NavBar";
import { Check } from "./EventReservation";
import { CheckBox, ModalData } from "./PackageRequest";
import { policy_list } from "./PackageRequest";
import Modal from "../components/Modal";

const Container = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1200px;
  margin-top: 85px;

  .id,
  .pw,
  .check,
  .family,
  .given {
    border-bottom: 1px solid #2d40ff;
  }

  .checks {
    padding: 12px 0 18px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 45px;
    padding: 0 16px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  max-width: 480px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 60px;
  padding: 20px 40px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(105, 88, 142, 0.1);
  border-radius: 10px;

  h3 {
    margin: 0;
    margin-top: 45px;
    margin-bottom: 60px;
    text-align: center;
    color: #122437;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: -0.84px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 20px;

    h3 {
      font-size: 30px;
    }
  }
`;

const Input = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 12px;
  border: 0;
  border-bottom: 1px solid #ddd;
  background: #fff;
  overflow: hidden;

  input {
    width: 100%;
    padding: 12px 20px 12px 8px;
    border: 0;
    outline: none;
    font-size: 16px;
  }

  input::placeholder {
    color: #aaa;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

const Button = styled.button<{ $mt?: string }>`
  width: 100%;
  padding: 10px 0;

  margin: auto;
  margin-top: ${(props) => (props.$mt ? props.$mt : "30px")};
  margin-bottom: 40px;
  width: 100%;
  padding: 12px 0;
  border-radius: 5px;
  background: #2d40ff;
  color: #fff;
  font-size: 14px;
  letter-spacing: -0.42px;
  border: none;
  cursor: pointer;
`;

const BtnList = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 36px;
  margin-bottom: 16px;
  padding-right: 8px;

  span {
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
    color: #a3a5b8;
    font-size: 14px;
    letter-spacing: -0.36px;
  }

  span:not(:first-of-type) {
    &::after {
      content: "";
      position: absolute;
      top: 3px;
      left: 0;
      width: 1px;
      height: 15px;
      background: #a3a5b8;
    }
  }

  @media screen and (max-width: 768px) {
    span {
      padding-left: 6px;
      padding-right: 6px;
      font-size: 12px;
    }
    span:not(:first-of-type) {
      &::after {
        top: 3px;
        height: 13px;
        background: #a3a5b8;
      }
    }
  }
`;

const CheckContainer = styled(Check)`
  gap: 8px;
  margin-top: 16px;

  .policy {
    display: flex;
    gap: 6px;

    div {
      color: #122437;
      font-size: 12px;
      letter-spacing: -0.36px;

      span {
        color: #06f;
        font-size: 12px;
        letter-spacing: -0.36px;
        cursor: pointer;
      }
    }
  }

  input {
    margin: 0;
    min-width: 16px;
  }
  label {
    color: #122437;
    font-size: 12px;
    letter-spacing: -0.36px;
  }

  @media screen and (max-width: 768px) {
    align-items: start;

    .policy {
      display: block;
    }
  }
`;

interface InputData {
  id: string;
  pw: string;
  check?: string;
  given?: string;
  family?: string;
}

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const setLogin = useSetAtom(loginAtom);
  const [data, setData] = useState<InputData>({
    id: "",
    pw: "",
    check: "",
    given: "",
    family: "",
  });
  const [check, setCheck] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [focus, setFocus] = useState("");
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState<ModalData>({
    txt: "",
    link: "",
  });

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const getToken = async () => {
    const body = {
      email: data.id,
      password: CryptoJS.SHA256(data.pw).toString(),
      login_type: "EMAIL",
      fcm_token: "",
    };
    try {
      const res = await axios.post("/v1/members/login", body);
      setLogin(res.data.data.access_token);
      sessionStorage.setItem("actk", res.data.data.access_token);
      sessionStorage.setItem("msq", res.data.data.member_id);
      // console.log(res.data.data);
      location.state === "main" ? navigate("/") : navigate(-1);
    } catch (error) {
      alert("아이디와 비밀번호를 다시 확인해 주세요.");
    }
  };

  const signUp = async () => {
    const email_regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const pw_regex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$/;

    if (!data.id) return alert("이메일을 입력해 주세요.");
    if (!email_regex.test(data.id))
      return alert("아이디는 이메일 형식으로 입력해 주세요.");
    if (!data.pw || !data.check) return alert("비밀번호를 입력해 주세요.");
    if (!pw_regex.test(data.pw))
      return alert(
        "비밀번호는 영문,숫자 포함 6자리 이상 조합하여 사용해야 합니다."
      );
    if (data.pw !== data.check) return alert("비밀번호가 일치하지 않습니다.");
    if (!data.family || !data.given) return alert("성함을 입력해 주세요.");
    if (!policy) return alert("이용약관 및 개인정보 보호정책에 동의해 주세요.");

    const body = {
      email: data.id,
      password: CryptoJS.SHA256(data.pw).toString(),
      first_name: data.given,
      last_name: data.family,
      login_type: "EMAIL",
      email_marketing_yn: check ? "Y" : "N",
    };

    try {
      const res = await axios.post("/v1/members", body);
      res.data.code === 0 && navigate("/signup/success");
      // console.log(res.data);
    } catch (e: any) {
      alert(e.response.data.message);
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getToken();
    }
  };

  const focusHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setFocus(e.target.name);
  };
  const blurHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setFocus("");
  };

  const handleModal = (idx: number) => {
    setModal(true);
    setModalData(policy_list[idx]);
  };

  return (
    <Container>
      {modal && <Modal setModal={setModal} modalData={modalData} />}
      <InputContainer>
        {path === "/login" ? (
          <>
            <h3>로그인</h3>
            <Input className={focus === "id" ? "id" : ""}>
              <img
                src={`/img/${
                  focus === "id" ? "person_focus" : "person_out"
                }.svg`}
                alt="id"
              />
              <input
                type="text"
                name="id"
                placeholder="아이디(이메일)"
                onFocus={focusHandler}
                onBlur={blurHandler}
                onChange={handleInput}
                onKeyDown={onKeyDown}
              />
            </Input>
            <Input className={focus === "pw" ? "pw" : ""}>
              <img
                src={`/img/${focus === "pw" ? "lock_focus" : "lock_out"}.svg`}
                alt="pw"
              />
              <input
                type="password"
                name="pw"
                placeholder="비밀번호"
                onFocus={focusHandler}
                onBlur={blurHandler}
                onChange={handleInput}
                onKeyDown={onKeyDown}
              />
            </Input>
            <Button onClick={getToken}>로그인</Button>
            <BtnList>
              <span onClick={() => navigate("/find/password")}>
                비밀번호 찾기
              </span>
              <span onClick={() => navigate("/signup")}>회원가입</span>
            </BtnList>
          </>
        ) : (
          <>
            <h3>회원가입</h3>
            <Input className={focus === "id" ? "id" : ""}>
              <input
                type="text"
                name="id"
                placeholder="아이디(이메일)"
                onFocus={focusHandler}
                onBlur={blurHandler}
                onChange={handleInput}
                onKeyDown={onKeyDown}
              />
            </Input>
            <Input className={focus === "pw" ? "pw" : ""}>
              <input
                type="password"
                name="pw"
                placeholder="비밀번호(영문,숫자 포함 6자리 이상)"
                onFocus={focusHandler}
                onBlur={blurHandler}
                onChange={handleInput}
                onKeyDown={onKeyDown}
              />
            </Input>
            <Input className={focus === "check" ? "check" : ""}>
              <input
                type="password"
                name="check"
                placeholder="비밀번호 확인"
                onFocus={focusHandler}
                onBlur={blurHandler}
                onChange={handleInput}
                onKeyDown={onKeyDown}
              />
            </Input>
            <Input className={focus === "family" ? "family" : ""}>
              <input
                type="text"
                name="family"
                placeholder="성"
                onFocus={focusHandler}
                onBlur={blurHandler}
                onChange={handleInput}
                onKeyDown={onKeyDown}
              />
            </Input>
            <Input className={focus === "given" ? "given" : ""}>
              <input
                type="text"
                name="given"
                placeholder="이름"
                onFocus={focusHandler}
                onBlur={blurHandler}
                onChange={handleInput}
                onKeyDown={onKeyDown}
              />
            </Input>

            <div className="checks">
              <CheckContainer>
                <CheckBox
                  type="checkbox"
                  name="agree"
                  onChange={() => {
                    setCheck(!check);
                  }}
                />
                <label>
                  이메일을 통한 여행, 호텔, 숙박 및 이벤트 정보 수신에
                  동의합니다. (선택)
                </label>
              </CheckContainer>

              <CheckContainer>
                <CheckBox
                  type="checkbox"
                  name="agree"
                  onChange={() => {
                    setPolicy(!policy);
                  }}
                />
                <label className="policy">
                  <span>약관에 모두 동의합니다.</span>
                  <div>
                    <span onClick={() => handleModal(3)}>서비스 이용약관</span>{" "}
                    및
                    <span onClick={() => handleModal(2)}>
                      개인정보 보호 정책
                    </span>
                  </div>
                </label>
              </CheckContainer>
            </div>
            <Button onClick={signUp}>회원가입</Button>
          </>
        )}
      </InputContainer>
    </Container>
  );
}

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";

const Container = styled.div`
  width: 100%;
  max-width: 380px;
  padding: 20px;
  border: 1px solid #e7e7ee;

  h3 {
    margin: 0;
    color: #161a3f;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.64px;
  }

  .wrapper {
    padding: 13px 0 20px;
    div {
      display: flex;
      justify-content: space-between;
      padding: 8px;
    }
    span {
      color: #5c5f79;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: -0.52px;
    }
  }
  .btn_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: end;

    a {
      text-decoration: none;
    }
  }
`;

const Status = styled.div<{ $clr: string }>`
  padding: 0 8px;
  color: ${(props) => props.$clr};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.64px;
`;

const DetailBtn = styled.button`
  padding: 10px 24px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.42px;
  background: #2d40ff;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
`;

export default function ReservationCard({ item }: any) {
  const [status, setStatus] = useState<any>();

  useEffect(() => {
    if (item?.booking_status === "RESUME")
      setStatus({ clr: "#009D3F", name: "예약 완료" });
    if (item?.booking_status === "DELETE")
      setStatus({ clr: "#E20000", name: "예약 취소" });
    if (item?.booking_status === "PAST")
      setStatus({ clr: "#0066FF", name: "사용 완료" });
  }, []);

  return (
    <Container>
      <h3>{item?.hotel_name}</h3>
      <div className="wrapper">
        <div>
          <span>체크인</span>
          <span>{item?.check_in}</span>
        </div>
        <div>
          <span>체크아웃</span>
          <span>{item?.check_out}</span>
        </div>
        <div>
          <span>예약번호</span>
          <span>{item?.itinerary_id}</span>
        </div>
      </div>
      <div className="btn_wrapper">
        <Status $clr={status?.clr}>{status?.name}</Status>
        <Link to={`/reservation/detail/${item?.itinerary_id}`}>
          <DetailBtn>상세보기</DetailBtn>
        </Link>
      </div>
    </Container>
  );
}

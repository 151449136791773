import { styled } from "styled-components";
import { EventListMoveBtn } from "../home_components/event/HomeEvent";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { Contain, TabBtn } from "../hotel_reservation/list/ReservationCategory";

const Container = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1200px;
  margin-top: 100px;

  a {
    text-decoration: none;
  }

  .hot {
    color: #f93c3c;
  }

  @media screen and (max-width: 768px) {
    margin-top: 60px;
  }
`;

const TabContainer = styled(Contain)`
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

const Title = styled.h3<{ $mb: boolean }>`
  width: fit-content;
  margin: auto;
  margin-top: 0;
  margin-bottom: ${(props) => props.$mb && "70px"};

  color: #161a3f;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -1.2px;

  @media screen and (max-width: 768px) {
    margin: initial;
    margin-bottom: ${(props) => props.$mb && "30px"};
    padding: 0 16px;
    font-size: 20px;
    letter-spacing: -0.8px;
  }
`;

const CardContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 30px 0 40px;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 380px);
  gap: 30px;

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(auto-fill, 172px);
    gap: 14px;
    padding: 20px 0 30px;
  }
`;

const Card = styled.div`
  width: 100%;
  max-width: 380px;
  height: 100%;

  div {
    width: 380px;
    height: 288px;
    border-radius: 10px;
    overflow: hidden;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .title {
    height: fit-content;

    p {
      margin-top: 10px;
      margin-bottom: 3px;
      color: #161a3f;
      font-size: 16px;
      letter-spacing: -0.64px;
    }
    span {
      color: #161a3f;
      font-size: 22px;
      font-weight: 700;
      letter-spacing: -0.88px;
    }
  }
  @media screen and (max-width: 480px) {
    div {
      width: 172px;
      height: 130px;
    }
    .title {
      p {
        font-size: 14px;
      }
      span {
        font-size: 18px;
      }
    }
  }
`;

export default function HomeHotDeal() {
  const hotdeallist = useLocation().pathname.includes("hotdeallist");
  const [list, setList] = useState<any>([]);
  const [tab, setTab] = useState(false);

  const getList = async () => {
    const res = await axios.get("/event/product/list");

    const sorted_list = res.data.data.list.sort(
      (a: any, b: any) => a.product_order - b.product_order
    );

    tab
      ? setList(
          sorted_list.filter((e: any) => e.product_name.includes("[마감]"))
        )
      : setList(
          sorted_list.filter((e: any) => e.product_name.includes("[진행중]"))
        );
  };

  useEffect(() => {
    getList();
  }, [tab]);

  return (
    <Container>
      <Title $mb={hotdeallist}>
        <span className="hot">핫딜!</span>
        <span>특가 상품</span>
      </Title>

      {hotdeallist && (
        <TabContainer>
          <TabBtn $clr={tab} onClick={() => setTab(false)}>
            진행중인 상품
          </TabBtn>
          <TabBtn $clr={!tab} onClick={() => setTab(true)}>
            종료된 상품
          </TabBtn>
        </TabContainer>
      )}

      <CardContainer>
        {!hotdeallist
          ? list.slice(0, 6).map((item: any, i: number) => (
              <Link
                key={i + item.product_order}
                to={`/hotdeal/${item.product_seq}`}
              >
                <Card>
                  <div>
                    <img
                      className="event_img"
                      src={item.product_banner_image}
                      alt={item.product_name}
                    />
                  </div>
                  <div className="title">
                    <p>{item.product_name.replace("[진행중]", "")}</p>
                  </div>
                </Card>
              </Link>
            ))
          : list.map((item: any, i: number) => (
              <Link
                key={i + item.product_order}
                to={`/hotdeal/${item.product_seq}`}
              >
                <Card>
                  <div>
                    <img
                      className="event_img"
                      src={item.product_banner_image}
                      alt={item.product_name}
                    />
                  </div>
                  <div className="title">
                    <p>
                      {item.product_name.replace(
                        tab ? "[마감]" : "[진행중]",
                        ""
                      )}
                    </p>
                  </div>
                </Card>
              </Link>
            ))}
      </CardContainer>

      {!hotdeallist && list.length > 6 && (
        <Link to={`/hotdeallist`}>
          <EventListMoveBtn>
            핫딜 상품 더보기
            <img src="/img/arrow_right.svg" alt="show_more_hotel" />
          </EventListMoveBtn>
        </Link>
      )}
    </Container>
  );
}

import { ChangeEvent, useEffect, useState } from "react";
import axios from "axios";
import { styled } from "styled-components";
import { atom, useAtom } from "jotai";
import { useSearchParams } from "react-router-dom";

const Container = styled.div`
  position: relative;

  .list {
    position: absolute;
    width: 100%;
    max-height: 400px;
    margin: 0;
    top: 48px;
    z-index: 10;
    padding: 16px 0;
    list-style: none;
    background: #fff;
    border: 1px solid #808996;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow-y: scroll;
    list-style: none;

    li {
      padding: 12px 18px;
      cursor: pointer;
      &:hover {
        background-color: #f6f7f9;
      }
    }
`;

const InputBox = styled.div`
  position: relative;
  margin-bottom: 10px;
  margin-top: 20px;
  input {
    width: 100%;
    line-height: 28px;
    border: 1px solid #e7e7ee;
    border-radius: 4px;
    padding: 10px 20px 10px 40px;
    outline: none;
    font-size: 14px;
  }
  .icon {
    position: absolute;
    top: 15px;
    left: 10px;
    color: #5d646d;
  }
`;

interface Destination {
  address: string;
  lat: number | null;
  lng: number | null;
  name: string;
  property_id: number | null;
  region_type: string | null;
}

//todo : 메인페이지 개발 후 수정
export const destAtom = atom<Destination>({
  region_type: "",
  property_id: null,
  name: "",
  address: "",
  lat: null,
  lng: null,
});

export const keywordAtom = atom("");
export const inputFocusAtom = atom(false);
export default function SearchInput() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dest_name = searchParams.get("dest");
  const [keyword, setKeyword] = useAtom(keywordAtom);
  // const [keyword, setKeyword] = useState("");
  const [destList, setDestList] = useState<Destination[]>([]);
  const [dest, setDest] = useAtom(destAtom);
  const [dropDown, setDropDown] = useState(false);
  const [inputFocus, setInputFocus] = useAtom(inputFocusAtom);

  const search = async () => {
    try {
      const res = await axios.get(
        `/search?keyword=${keyword}&size=30&language=kr`
      );
      setDestList(res.data.data);
      if (res.data.data.length > 0) setDropDown(true);
    } catch (e: any) {
      if (e.response.data.code === 5) return;
    }
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (dest)
      setDest({
        address: "",
        lat: 0,
        lng: 0,
        name: "",
        property_id: 0,
        region_type: "",
      });
    setKeyword(e.target.value);
  };

  const handleClick = (item: Destination) => {
    setDest(item);
    setKeyword(item.name);
    setDropDown(false);
  };

  useEffect(() => {
    const valid = /[a-zA-Z0-9|가-힣]/;
    const debounce = setTimeout(() => {
      if (valid.test(keyword) && !dest?.name) search();
    }, 500);
    return () => clearTimeout(debounce);
  }, [keyword]);

  useEffect(() => {
    setKeyword(dest.name);
    setDropDown(false);
  }, []);

  return (
    <Container
      onClick={() => {
        setInputFocus(true);
      }}
    >
      <InputBox>
        <img className="icon" src="/img/location.svg" alt="destination" />
        <input
          type="text"
          value={keyword}
          placeholder="여행지를 검색해보세요"
          onChange={handleInput}
        />
      </InputBox>
      {dropDown && (
        <ul className="list">
          {destList.map((item, i) => (
            <li key={item.name + i} onClick={() => handleClick(item)}>
              {item.name}
            </li>
          ))}
        </ul>
      )}
    </Container>
  );
}

import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { styled } from "styled-components";
import { occuListAtom } from "../../../components/Occupancy";
import { defaultSelectedAtom } from "../../../components/SearchForm";
import { format } from "date-fns";

const Container = styled.div`
  border: 1px solid #eeeff3;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  .vertical {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 200px;

    .deadline {
      color: #009d3f;
      font-size: 13px;
      letter-spacing: -0.52px;
    }
    .no_room {
      color: #e20000;
      font-size: 13px;
      letter-spacing: -0.52px;
    }
  }

  .img_container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: 288px;
    background: #eee;

    .no_img {
      width: 70px;
      height: 70px;
      cursor: default;
    }
    p {
      color: #ccc;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.56px;
    }
  }

  img {
    max-width: 380px;
    height: 288px;
    object-fit: cover;
    cursor: pointer;
  }

  .name {
    // height: 100%;
    // max-height: 78px;
    margin-bottom: 12px;
    color: #161a3f;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.72px;
  }
  .check {
    display: flex;
    align-items: start;
    padding: 8px 0;
    cursor: default;

    input {
      margin: 4px 8px 0 0;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
`;

const MoreRoomImgBtn = styled.button`
  position: absolute;
  bottom: 10px;
  right: 10px;

  display: flex;
  align-items: center;
  width: fit-content;
  padding: 8px 16px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.39px;
  border: none;
  border-radius: 100px;
  background-color: rgba(12, 14, 28, 0.56);
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }

  img {
    width: 19px;
    height: 19px;
    margin-right: 6px;
  }
`;

const RoomInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px;
`;

const Common = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 12px 0;

  p {
    margin: 0;
    font-size: 14px;
  }
  .person,
  .info {
    color: #525254;
    font-size: 13px;
    letter-spacing: -0.56px;
  }
`;

const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  p {
    margin: 0;
    font-size: 14px;
    color: #191e3b;
  }
  .amenities {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: end;
`;

const Totals = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin: 16px 0 8px;

  .total_wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  p {
    margin: 0;
  }

  .no_refund {
    color: #e20000;
    font-size: 13px;
    letter-spacing: -0.52px;
  }

  .strike {
    text-decoration: line-through;
  }
  .exc {
    color: #161a3f;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: -1.04px;
  }
  .rooms,
  .tax {
    font-size: 13px;
    color: #191e3b;
    letter-spacing: -0.52px;
  }
  .inc {
    color: #191e3b;
    font-size: 13px;
    letter-spacing: -0.52px;
  }

  .rate_detail {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #06f;
    padding: 8px 0 0;
    letter-spacing: -0.52px;
    background-color: #fff;
    border: none;
    cursor: pointer;

    img {
      margin-left: 4px;
      width: 20px;
      height: 20px;
      padding-top: 1px;
    }
  }
`;
const ReservationBtn = styled.button<{ $available: boolean }>`
  width: 100px;
  height: 45px;
  border: none;
  border-radius: 5px;
  background-color: ${(props) => (props.$available ? "#2d40ff" : "#bdbdbd")};
  color: #fff;
  font-size: 14px;
  letter-spacing: -0.42px;
  cursor: ${(props) => props.$available && "pointer"};
`;

export const TempRoomAtom = atom(false);
export const TempRoomImgAtom = atom<any>([]);
export default function TempRoomCard({ item, nights }: any) {
  const setRoomModal = useSetAtom(TempRoomAtom);
  const setRoomImg = useSetAtom(TempRoomImgAtom);
  const [searchParams] = useSearchParams();
  const occupancy = searchParams.get("occupancy");
  const company = searchParams.get("company");
  const occuList = useAtomValue(occuListAtom);
  const defaultSelected = useAtomValue(defaultSelectedAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search;

  const available = company
    ? occuList[0].adults <= item.max_person
    : ((occupancy && occupancy <= item.max_person) as boolean);

  const getPrice = () => {
    if (occupancy) {
      return (
        item.price +
        ((occupancy > item.default_person
          ? occupancy > item.max_person
            ? item.max_person
            : occupancy
          : item.default_person) -
          item.default_person) *
          item.person_price *
          nights
      );
    }
    return (
      item.price +
      ((occuList[0].adults > item.default_person
        ? occuList[0].adults > item.max_person
          ? item.max_person
          : occuList[0].adults
        : item.default_person) -
        item.default_person) *
        item.person_price *
        nights
    );
  };

  const handleBooking = () => {
    if (!available) return;

    const query = `?company=ymstar&hsq=1&check_in=${format(
      defaultSelected.from,
      "yyyy-MM-dd"
    )}&check_out=${format(defaultSelected.to, "yyyy-MM-dd")}&occupancy=${
      occuList[0].adults
    }`;
    navigate(`/temp/book${company ? query : search}&rsq=${item.room_seq}`);
  };

  return (
    <Container>
      <div
        className="img_container"
        onClick={() => {
          setRoomModal(true);
          setRoomImg(item.images);
        }}
      >
        <img src={item.images[0].image_url} alt="room" />
        {item.images.length > 1 && (
          <MoreRoomImgBtn>
            <img src="/img/more_photo.svg" alt="show_more_room_photo" />{" "}
            {item.images.length}
          </MoreRoomImgBtn>
        )}
      </div>
      <RoomInfo>
        <div className="name">{item.room_name}</div>

        <Common>
          {item.amenities.map((el: any, i: number) => (
            <p key={i}>{el.amenity_desc}</p>
          ))}

          <p className="person">
            기본 {item.default_person}인 / 최대 {item.max_person}인
          </p>
          <p className="info">{item.room_info}</p>
        </Common>

        <div className="vertical">
          {item.room_left > 0 && (
            <>
              <div className="deadline">
                환불은 체크인 2일 전(고객센터 운영일 기준) <br /> 14:00 까지
                환불 가능 (고객센터 운영시간 내 취소가능)
                <br />
                자세한 환불가능기간은 호텔 페이지 내 공지사항 이미지 참조
              </div>
            </>
          )}

          <Totals>
            <div className="total_wrap">
              {item.room_left > 0 ? (
                <p className="exc">{"₩ " + getPrice().toLocaleString()}</p>
              ) : (
                <div className="no_room">예약 가능한 방이 없습니다.</div>
              )}
            </div>

            <ReservationBtn
              $available={available && item.room_left > 0}
              onClick={handleBooking}
            >
              {available && item.room_left > 0 ? "예약하기" : "예약불가"}
            </ReservationBtn>
          </Totals>
        </div>
      </RoomInfo>
    </Container>
  );
}

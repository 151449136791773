import { useSetAtom } from "jotai";
import { styled } from "styled-components";
import { allAtom, carouselAtom, modalAtom } from "./ImageGallery";
import { useLocation } from "react-router-dom";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 101;
  background-color: #ffffff;
  overflow-y: auto;

  .close-wrap {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 3px 4px 0px;
  }

  .close {
    width: 40px;
    height: 40px;
    margin-left: 8px;
    background: transparent url("/img/close.svg") no-repeat center;
    border: none;
    cursor: pointer;
    margin-top: 5px;
  }

  .content-wrap {
    margin: auto;
    margin-bottom: 80px;
    width: 100%;
    max-width: 1200px;
  }

  h3 {
    margin-top: 80px;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 500;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 590px 590px;
    gap: 24px 20px;

    .item {
      font-size: 14px;
    }

    img {
      width: 100%;
      max-width: 590px;
      height: 100%;
      max-height: 330px;
      object-fit: cover;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 590px) {
    .content-wrap {
      max-width: fit-content;
      padding: 0 16px;
    }
    .wrapper {
      width: fit-content;
      display: flex;
      flex-direction: column;
      img {
        max-height: 240px;
      }
    }
  }
`;

export default function AllPhoto({ list }: any) {
  const airtellist = useLocation().pathname.includes("airtel");
  const setAll = useSetAtom(allAtom);
  const setModal = useSetAtom(modalAtom);
  const setCarousel = useSetAtom(carouselAtom);

  return (
    <Container>
      <div className="close-wrap">
        <button
          className="close"
          onClick={() => {
            setAll(false);
          }}
        />
      </div>
      <div className="content-wrap">
        <h3>모든 사진</h3>
        <div className="wrapper">
          {list.images?.map((item: any, i: number) => (
            <div className="item" key={airtellist ? item.seq + i : item + i}>
              <img
                // src={item.links["1000px"].href}
                src={airtellist ? item.image_url : item.links["1000px"].href}
                alt={list.images[i].caption}
                onClick={() => {
                  setModal(true);
                  setCarousel(i);
                }}
              />
              <div>{list.images[i].caption}</div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}

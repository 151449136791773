import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { CheckBox } from "./PackageRequest";
import { loginAtom } from "../components/NavBar";
import { useAtom } from "jotai";

const Container = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1200px;
  margin-top: 85px;

  @media screen and (max-width: 768px) {
    margin-top: 45px;
    padding: 0 16px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  max-width: 480px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 60px;
  padding: 20px 40px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(105, 88, 142, 0.1);
  border-radius: 10px;

  h3 {
    margin: 0;
    margin-top: 45px;
    margin-bottom: 60px;
    text-align: center;
    color: #122437;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: -0.84px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
    padding-bottom: 14px;
    border-bottom: 1px solid #ddd;

    color: #161a3f;
    font-size: 16px;
    letter-spacing: -0.42px;

    .require {
      color: #f00;
      font-size: 13px;
      padding-left: 3px;
    }

    input {
      width: 100%;
      max-width: 240px;
      border: 0;
      outline: none;
      font-size: 16px;
    }
  }

  .id {
    span,
    input {
      color: #999999;
    }
  }
  .first_name,
  .last_name,
  .phone {
    border-bottom: 1px solid #2d40ff;
  }

  .checkbox {
    padding-bottom: 14px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 50px;
    color: #161a3f;
    font-size: 13px;
    letter-spacing: -0.39px;

    .input_wrap {
      display: flex;
      margin-top: 14px;
    }

    label {
      font-size: 12px;
      letter-spacing: -0.36px;
      cursor: pointer;
    }
    input {
      width: 18px;
      height: 18px;
      margin: 0;
      margin-right: 8px;
    }
  }

  .delete {
    display: block;
    width: fit-content;
    margin: auto;
    margin-bottom: 40px;
    cursor: pointer;

    color: #161a3f;
    font-size: 14px;
    letter-spacing: -0.42px;
    border: 0;
    background: #fff;
    cursor: pointer;
  }

  .contents {
    margin-bottom: 28px;

    div:first-of-type {
      padding-bottom: 12px;
    }

    .textarea {
      margin-left: 20px;
      margin-top: 8px;
      max-width: 380px;

      textarea {
        resize: none;
        width: 100%;
        height: 80px;
      }
    }
  }

  .delete_info {
    p {
      margin: 4px 0;
      color: #161a3f;
      font-size: 14px;
      letter-spacing: -0.42px;
    }
  }

  .radio_wrap {
    padding: 4px;
    font-size: 14px;
    input {
      margin: 0;
      margin-right: 6px;
    }
  }

  .agree {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    font-size: 14px;

    input {
      width: 18px;
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 20px 20px;
    .input_container {
      input {
        width: 100%;
        max-width: 200px;
        border: 0;
        outline: none;
      }
    }
  }
`;

const Button = styled.button<{ $clr?: string }>`
  width: 100%;
  padding: 12px 24px;
  margin-bottom: 30px;
  border: 0;
  border-radius: 5px;
  background: ${(props) => (props.$clr ? props.$clr : "#2d40ff")};
  color: #fff;
  font-size: 14px;
  letter-spacing: -0.42px;
  cursor: pointer;
`;

// const BtnContainer = styled.div`
//   display: flex;
//   gap: 10px;
//   padding: 22px 0;

//   button {
//     width: 100%;
//     padding: 12px 0;
//     border: 0;
//     border-radius: 5px;
//     color: #fff;
//     font-size: 14px;
//     letter-spacing: -0.42px;
//     cursor: pointer;
//   }
//   .cancel {
//     background: #a8abbf;
//   }
//   .save {
//     background: #2d40ff;
//   }
// `;

interface Info {
  advertising_email: string | null;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
}

export default function Account() {
  const navigate = useNavigate();
  const [info, setInfo] = useState<Info>({
    advertising_email: null,
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
  });
  const [check, setCheck] = useState(false);
  const [focus, setFocus] = useState("");
  const deletes = useLocation().pathname.includes("delete");
  const mid = sessionStorage.getItem("msq");
  const token = sessionStorage.getItem("actk");
  const [login, setlogin] = useAtom(loginAtom);
  const [radio, setRadio] = useState("");
  const [reason, setReason] = useState("");
  const [agree, setAgree] = useState(false);

  const getMemberInfo = async () => {
    const res = await axios.get(`/v1/members/${mid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setInfo(res.data.data);
  };

  const getMarketing = async () => {
    const res = await axios.get(`/v1/members/${mid}/policy`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setCheck(res.data.data.email_marketing_yn === "Y" ? true : false);
  };

  const setMemberInfo = async () => {
    try {
      const res = await axios.patch(`/v1/members/${mid}`, info, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const response = await axios.patch(
        `/v1/members/${mid}/policy`,
        {
          email_marketing_yn: check === true ? "Y" : "N",
          push_marketing_yn: check === true ? "Y" : "N",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      res.data.code === 0 &&
        response.data.code === 0 &&
        alert("계정 정보가 수정되었습니다.");
    } catch (e: any) {
      alert(e.response.data.message);
    }
  };

  const deleteAccount = async () => {
    if (!radio) return alert("탈퇴사유를 선택해 주세요.");
    if (radio === "기타 탈퇴사유" && reason === "")
      return alert("탈퇴사유를 입력해 주세요.");
    if (!agree) return alert("동의 여부에 체크하셔야 회원탈퇴가 가능합니다.");

    const body = {
      type:
        radio === "재가입(ID변경)"
          ? "NEW"
          : radio === "서비스 이용 불편"
          ? "COMPEL"
          : radio === "숙박 및 여행 정보의 부족"
          ? "LACK_INFO"
          : "ETC",
      is_agree: agree,
      reason: reason,
    };

    try {
      const res = await axios.post(`/v1/member`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      alert("회원탈퇴가 완료되었습니다.");
      setlogin("");
      sessionStorage.removeItem("actk");
      navigate("/");
    } catch (e: any) {
      alert(e.response.data.message);
    }
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setInfo({ ...info, [e.target.name]: e.target.value });
  };

  const focusHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setFocus(e.target.name);
  };
  const blurHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setFocus("");
  };

  useEffect(() => {
    getMemberInfo();
    getMarketing();
  }, []);

  return (
    <Container>
      {deletes ? (
        <InputContainer>
          <h3>회원탈퇴</h3>
          <div className="contents">
            <div>탈퇴 시 주의 사항</div>
            <div className="delete_info">
              <p>
                탈퇴 시 현재 사용하고 계신 계정 정보는 삭제 및 파기되며 본인과
                타인 모두 복구가 불가능 합니다.
              </p>
              <p>
                현재 사용중인 아이디(이메일)는 탈퇴 후 30일 이후 부터 동일
                아이디로 재가입 가 능합니다.
              </p>
              <p>재가입 되더라도 과거 이력은 복구가 불가능 합니다.</p>
            </div>
          </div>

          <div className="contents">
            <div>탈퇴사유</div>

            <div className="radio_wrap">
              <input
                type="radio"
                name="reason"
                value="재가입(ID변경)"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setRadio(e.target.value)
                }
              />
              <label>재가입(ID변경)</label>
            </div>
            <div className="radio_wrap">
              <input
                type="radio"
                name="reason"
                value="서비스 이용 불편"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setRadio(e.target.value)
                }
              />
              <label>서비스 이용 불편</label>
            </div>
            <div className="radio_wrap">
              <input
                type="radio"
                name="reason"
                value="숙박 및 여행 정보의 부족"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setRadio(e.target.value)
                }
              />
              <label>숙박 및 여행 정보의 부족</label>
            </div>
            <div className="radio_wrap">
              <input
                type="radio"
                name="reason"
                value="기타 탈퇴사유"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setRadio(e.target.value)
                }
              />
              <label>기타 탈퇴사유</label>
            </div>

            {radio === "기타 탈퇴사유" && (
              <div className="textarea">
                <textarea
                  name="etc"
                  placeholder="탈퇴사유 입력"
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    setReason(e.target.value);
                  }}
                />
              </div>
            )}
          </div>

          <div className="agree">
            <CheckBox
              type="checkbox"
              name="agree"
              onChange={() => {
                setAgree(!agree);
              }}
            />
            <label>상기 내용을 확인하였으며 동의합니다.</label>
          </div>

          <Button $clr="#ff4040" onClick={deleteAccount}>
            회원탈퇴
          </Button>
        </InputContainer>
      ) : (
        <InputContainer>
          <h3>내 계정 정보</h3>

          <div className="container id">
            <span>아이디</span>
            <input type="text" name="email" value={info.email} readOnly />
          </div>
          <div
            className={
              "container" + (focus === "last_name" ? " last_name" : "")
            }
          >
            <div>
              <span>성</span>
              <span className="require">(필수)</span>
            </div>
            <input
              type="text"
              name="last_name"
              value={info.last_name}
              onFocus={focusHandler}
              onBlur={blurHandler}
              onChange={handleInput}
            />
          </div>
          <div
            className={
              "container" + (focus === "first_name" ? " first_name" : "")
            }
          >
            <div>
              <span>이름</span>
              <span className="require">(필수)</span>
            </div>
            <input
              type="text"
              name="first_name"
              value={info.first_name}
              onFocus={focusHandler}
              onBlur={blurHandler}
              onChange={handleInput}
            />
          </div>
          <div className={"container" + (focus === "phone" ? " phone" : "")}>
            <span>전화번호</span>
            <input
              type="text"
              name="phone"
              inputMode="numeric"
              value={info.phone}
              onFocus={focusHandler}
              onBlur={blurHandler}
              onChange={handleInput}
            />
          </div>

          <div className="checkbox">
            <div>알림 정보 수신</div>
            <div className="input_wrap">
              <input
                type="checkbox"
                name="advertising_email"
                checked={check}
                // checked={info.advertising_email ? true : false}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setCheck(e.target.checked)
                }
              />
              <label>광고 및 정보 수신 동의</label>
            </div>
          </div>

          <Button onClick={setMemberInfo}>저장</Button>
          {/* <BtnContainer>
          <button className="cancel">취소</button>
          <button className="save">저장</button>
        </BtnContainer> */}

          <button
            className="delete"
            onClick={() => {
              navigate("/mypage/delete/account");
            }}
          >
            회원탈퇴
          </button>
        </InputContainer>
      )}
    </Container>
  );
}

import styled from "styled-components";
import HotelCard from "../components/HotelCard";
import SearchForm, {
  HotelListAtom,
  isEndAtom,
  pageAtom,
} from "../components/SearchForm";
import { atom, useAtom, useAtomValue } from "jotai";
import NoResult from "../components/NoResult";
import { useEffect, useState } from "react";
import LoadingCard from "../components/LoadingCard";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  margin-top: 45px;

  .btn_container {
    width: fit-content;
    margin: auto;
    margin-bottom: 70px;
  }

  @media screen and (max-width: 1100px) {
    display: block;
  }
`;

const CardContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 830px;

  @media screen and (max-width: 1100px) {
    margin: auto;
  }
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

const MoreHotelBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 220px;
  padding: 12px 0;
  border-radius: 100px;
  border: 1px solid #a8b0ff;
  background: #fff;

  color: #6f7cff;
  font-size: 14px;
  letter-spacing: -0.56px;

  background-color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #f3f5ff;
  }
`;

export const ScrollTopBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: none;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(206, 210, 255, 0.6);
  cursor: pointer;

  img {
    width: 32px;
    transform: rotate(180deg);
  }

  @media screen and (max-width: 768px) {
    bottom: 20px;
    right: 16px;
  }
`;

export const loadingAtom = atom(false);
export const noResultAtom = atom(false);
export default function Search() {
  const hotelList = useAtomValue(HotelListAtom);
  const is_end = useAtomValue(isEndAtom);
  const [page, setPage] = useAtom(pageAtom);
  const noResult = useAtomValue(noResultAtom);
  const [scroll, setScroll] = useState(false);
  const loading = useAtomValue(loadingAtom);
  // console.log(hotelList);
  const load = new Array(4).fill("");

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 1600) {
      setScroll(true);
    } else setScroll(false);
  };

  return (
    <Container>
      <SearchForm />
      <CardContainer>
        {loading ? (
          load.map((item: any, i: number) => <LoadingCard key={i} />)
        ) : noResult ? (
          <NoResult />
        ) : (
          hotelList.map((item: any, i: number) => (
            <HotelCard key={item + i} item={item} />
          ))
        )}
        {!is_end && !noResult && (
          <div className="btn_container">
            <MoreHotelBtn
              onClick={() => {
                setPage(page + 1);
              }}
            >
              검색된 호텔 더 보기
              <img src="/img/arrow_right.svg" alt="show_more_hotel" />
            </MoreHotelBtn>
          </div>
        )}
      </CardContainer>
      {scroll && (
        <ScrollTopBtn
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
          }
        >
          <img src="/img/arrow_down.svg" alt="scroll_to_top" />
        </ScrollTopBtn>
      )}
    </Container>
  );
}

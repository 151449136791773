import { styled } from "styled-components";

const Container = styled.div`
  .wrapper {
    padding: 70px 0;
    border-bottom: 1px solid #f6f6f9;
  }

  @media screen and (max-width: 680px) {
    padding: 0 16px;

    .wrapper {
      padding: 40px 0;
    }
  }

  h3 {
    margin: 0;
    margin-bottom: 32px;
    color: #161a3f;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.8px;
  }

  .list {
    width: 276px;

    @media screen and (max-width: 680px) {
      width: 100%;
    }

    .cards {
      display: grid;
      grid-template-columns: repeat(auto-fill, 80px);
      gap: 24px 0;
    }

    .item_name {
      margin: 0;
      margin-bottom: 14px;
      color: #161a3f;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.64px;
    }

    p {
      margin: 12px 0 0;
      color: #5c5f79;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: -0.52px;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin: 0;
    padding-left: 16px;
  }

  li {
    color: #5c5f79;
    font-size: 13px;
    letter-spacing: -0.52px;
  }
  li::marker {
    color: #959fff;
  }
  .no_bullet {
    list-style: none;
    padding: 0;
    line-height: 20px;
  }
`;

const ContentContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 276px);
  gap: 32px;

  @media screen and (max-width: 680px) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export default function TempRoomInfo() {
  return (
    <Container>
      <div className="wrapper">
        <h3>숙박시설 내 편의시설</h3>
        <ContentContainer>
          <div className="list">
            <p className="item_name">고객 서비스</p>
            <ul>
              <li>직원 24시간 로비 상주</li>
              <li>무인 키오스크</li>
              <li>체크인 전 짐 보관</li>
              <li>투어/티켓 안내</li>
            </ul>
          </div>
          <div className="list">
            <p className="item_name">가족 여행 편의 시설</p>
            <ul>
              <li>냉장고</li>
            </ul>
          </div>
          <div className="list">
            <p className="item_name">인터넷</p>
            <ul>
              <li>
                모든 객실 및 일부 공용 구역에서 Wifi와 유선 인터넷 무료 이용
                가능
              </li>
            </ul>
          </div>
          <div className="list">
            <p className="item_name">편의 시설</p>
            <ul>
              <li>프런트 데스크의 귀중품 보관</li>
              <li>
                더 블루 라운지(정확한 오픈 일자 및 운영 시간은 호텔에 문의)
              </li>
            </ul>
          </div>
          <div className="list">
            <p className="item_name">주차 및 교통편</p>
            <ul>
              <li>시설 내 무료 셀프 주차</li>
              <li>휠체어로 주차장 이용 가능</li>
            </ul>
          </div>
          <div className="list">
            <p className="item_name">기타</p>
            <ul>
              <li>금연 숙박 시설</li>
            </ul>
          </div>
        </ContentContainer>
      </div>

      <div className="wrapper">
        <h3>객실 내 편의시설</h3>
        <ContentContainer>
          <div className="list">
            <p className="item_name">침실</p>
            <ul>
              <li>냉.난방 시스템</li>
              <li>암막 커튼</li>
              <li>테이블</li>
              <li>소파</li>
            </ul>
          </div>
          <div className="list">
            <p className="item_name">식사 및 음료</p>
            <ul>
              <li>무료 생수</li>
              <li>냉장고</li>
            </ul>
          </div>
          <div className="list">
            <p className="item_name">욕실</p>
            <ul>
              <li>샴푸</li>
              <li>컨디셔너</li>
              <li>바디워시</li>
              <li>타월</li>
              <li>헤어 드라이어</li>
            </ul>
          </div>
          <div className="list">
            <p className="item_name">욕실</p>
            <ul>
              <li>샴푸</li>
              <li>컨디셔너</li>
              <li>바디워시</li>
              <li>타월</li>
              <li>헤어 드라이어</li>
            </ul>
          </div>
          <div className="list">
            <p className="item_name">엔터테인먼트</p>
            <ul>
              <li>Wifi</li>
              <li>Smart TV</li>
              <li>유튜브 및 넷플릭스(개인 ID 필요)</li>
            </ul>
          </div>
        </ContentContainer>
      </div>

      <div className="wrapper">
        <h3>정책</h3>
        <ContentContainer>
          <div className="list">
            <p className="item_name">체크인</p>
            <ul>
              <li>토요일: 17:00</li>
              <li>일요일 - 금요일: 15:00</li>
            </ul>
          </div>
          <div className="list">
            <p className="item_name">체크아웃</p>
            <ul>
              <li>토요일: 12:00</li>
              <li>일요일 - 금요일: 11시</li>
            </ul>
          </div>
          <div className="list">
            <p className="item_name">특별 체크인 지침</p>
            <ul className="no_bullet">
              <li>
                숙박 시설에 미리 연락해 체크인 지침을 꼭 확인해 주세요. 도착
                시에는 프런트 데스크 직원이 도와드립니다. 예약 확인 메일에 나와
                있는 연락처로 숙박 시설에 미리 연락하여 체크인 안내를 받으시기
                바랍니다. 23:00 이후에 도착 예정이신 경우 예약 확인 메일에 나와
                있는 연락처로 미리 숙박 시설에 연락해 주시기 바랍니다.{" "}
              </li>
            </ul>
          </div>
          <div className="list">
            <p className="item_name">출입 방법</p>
            <ul>
              <li>직원 상주 프런트 데스크 또는 무인 키오스크</li>
            </ul>
          </div>
          <div className="list">
            <p className="item_name">반려동물</p>
            <ul>
              <li>반려동물 동반 불가</li>
            </ul>
          </div>
          <div className="list">
            <p className="item_name">아동 및 추가 침대</p>
            <ul>
              <li>어린이 고객을 환영합니다.</li>
              <li>
                만 7세 이하의 아동은 부모 또는 보호자의 객실에서 기존 침구를
                사용하여 무료로 숙박할 수 있습니다.
              </li>
              <li>간이/추가 침대가 제공되지 않습니다.</li>
              <li>유아용 침대가 제공되지 않습니다.</li>
            </ul>
          </div>
        </ContentContainer>
      </div>

      <div className="wrapper">
        <h3>중요 정보</h3>
        <ContentContainer>
          <div className="list">
            <p className="item_name">알아두실 사항</p>
            <ul>
              <li>
                추가 인원에 대한 요금이 부과될 수 있 으며, 이는 숙박 시설 정책에
                따라 다릅 니다.
              </li>
              <li>
                체크인 시 부대 비용 발생에 대비해 정부에서 발급한 사진이 부착된
                신분증과 신용카드, 직불카드 또는 현금으로 보증 금이 필요할 수
                있습니다.
              </li>
              <li>
                특별 요청 사항은 체크인 시 이용 상황 에 따라 제공 여부가 달라질
                수 있으며 추가 요금이 부과될 수 있습니다. 또한, 반드시
                보장되지는 않습니다.
              </li>
              <li>
                이 숙박 시설 내에서 사용 가능한 결제 수단은 신용카드, 직불카드
                또는 현금입 니다.
              </li>
            </ul>
          </div>

          <div className="list">
            <p className="item_name">알려드릴 사항</p>
            <ul>
              <li>
                일부 시설의 경우 출입이 제한될 수 있습니다. 자세한 내용은 예약
                확인 메일에 나와 있는 연락처 정보로 해당 숙박 시설에 직접
                문의하실 수 있습니다.
              </li>
            </ul>
          </div>

          <div className="list">
            <p className="item_name">국가 공인 등급</p>
            <ul>
              <li>
                고객 편의를 위해 저희 등급 시스템을 기준으로 해당 정보를
                제공했습니다.
              </li>
            </ul>
          </div>
        </ContentContainer>
      </div>
    </Container>
  );
}

import { useAtom } from "jotai";
import { styled } from "styled-components";
import { guestCardAtom } from "../pages/PackageRequest";
import { ChangeEvent } from "react";
import useValidate from "../useValidate";

const Container = styled.div`
  width: 100%;
  background: #f8f8f8;
  border-radius: 10px;
  margin-bottom: 16px;
`;

export const ContentsBox = styled.div`
  padding: 24px 30px;
  letter-spacing: -0.56px;

  h3 {
    font-weight: 500;
    margin: 0 0 28px;
    display: flex;
    justify-content: space-between;

    img {
      width: 24px;
      cursor: pointer;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 12px;
    color: #161a3f;
    font-size: 14px;
    letter-spacing: -0.56px;

    .form {
      gap: 12px;
    }
  }
`;

export const InputBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    width: 322px;
    font-size: 16px;
    padding: 12px 12px;
    border: 1px solid #e7e7ee;
    border-radius: 5px;
  }
  input::placeholder {
    font-size: 14px;
  }

  .warning {
    color: #ff0000;
    text-align: left;
    margin: 8px 0 0 8px;
    font-size: 14px;
  }

  @media screen and (max-width: 768px) {
    display: block;
    input {
      width: 100%;
      margin-top: 8px;
      font-size: 14px;
    }
  }
`;

export default function PackageGuestCard(props: { no: number }) {
  const [guest, setGuset] = useAtom(guestCardAtom);

  const deleteCard = (idx: number) => {
    guest.splice(idx, 1);
    setGuset([...guest]);
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>, idx: number) => {
    let list = [...guest];

    if (e.target.name === "person") {
      list[idx].person = e.target.value;
      setGuset([...list]);
    }
    if (e.target.name === "birth") {
      list[idx].birth = e.target.value;
      setGuset([...list]);
    }
  };

  const valid = useValidate(guest[props.no - 1].birth);

  return (
    <Container>
      <ContentsBox>
        <h3>
          추가 여행객 {props.no}
          <img
            src="/img/close.svg"
            alt="close"
            onClick={() => {
              deleteCard(props.no - 1);
            }}
          />
        </h3>
        <div className="form">
          <InputBox>
            <div>이름</div>
            <input
              type="text"
              name="person"
              value={guest[props.no - 1].person}
              placeholder="이름을 입력해 주세요"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleInput(e, props.no - 1);
              }}
            />
          </InputBox>
          <InputBox>
            <div className="item">생년월일</div>
            <div>
              <input
                type="text"
                name="birth"
                maxLength={6}
                value={guest[props.no - 1].birth}
                placeholder="생년월일 6자리를 입력해 주세요"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleInput(e, props.no - 1);
                }}
              />
              {valid && <div className="warning">숫자만 입력하세요</div>}
            </div>
          </InputBox>
        </div>
      </ContentsBox>
    </Container>
  );
}

import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { addDays, format } from "date-fns";

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  margin-top: 100px;

  .bg {
    border-radius: 10px;
    height: 240px;
    background: url("/img/temp/temp.png") no-repeat center;
    background-size: cover;
    overflow: hidden;
  }

  a {
    text-decoration: none;
  }

  @media screen and (max-width: 680px) {
    padding: 0 16px;

    .bg {
      height: 180px;
      background: url("/img/temp/temp_mobile.png") no-repeat center;
      background-size: cover;
    }
  }
`;

const Content = styled.div`
  width: 600px;
  height: 240px;
  padding: 51px 0 51px 100px;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );

  .title {
    display: flex;
    margin-bottom: 6px;
    color: #fff;
    font-size: 18px;
    letter-spacing: -0.72px;
  }
  .name {
    margin: 0;
    margin-bottom: 15px;
    color: #fff;
    font-size: 34px;
    font-weight: 700;
    letter-spacing: -1.36px;
  }

  @media screen and (max-width: 680px) {
    width: 240px;
    height: 180px;
    padding: 28px 0 28px 20px;

    .title {
      display: block;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: -0.52px;
    }
    .name {
      font-size: 23px;
      letter-spacing: -0.92px;
    }
  }
`;

const ReservationBtn = styled.button`
  padding: 10px 29px;
  border: none;
  border-radius: 100px;
  background: #2d40ff;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.75px;
  cursor: pointer;

  color: #fff;

  @media screen and (max-width: 680px) {
    padding: 5px 14px;
    font-size: 13px;
    letter-spacing: -0.65px;
  }
`;

export default function TempBanner() {
  const today = new Date();
  const check_in = format(addDays(today, 2), "yyyy-MM-dd");
  const check_out = format(addDays(today, 3), "yyyy-MM-dd");

  return (
    <Container>
      <div className="bg">
        <Content>
          <div className="title">
            <div>월미도 선착장이 보이는</div>
            <div>오션뷰 호텔에서 호캉스를 즐겨보자!</div>
          </div>
          <h3 className="name">인천 블루마린 호텔</h3>
          <Link
            to={`/temp?hsq=1&check_in=${check_in}&check_out=${check_out}&occupancy=2`}
          >
            <ReservationBtn>예약 바로가기</ReservationBtn>
          </Link>
        </Content>
      </div>
    </Container>
  );
}

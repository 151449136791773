import React from "react";
import { styled } from "styled-components";

const Container = styled.div<{ $mgt?: string }>`
  margin-top: ${(props) => (props.$mgt ? props.$mgt : "46px")};

  display: flex;
  align-items: center;
  gap: 5px;

  .rating {
    display: flex;
    color: #5c5f79;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.48px;
  }

  .review {
    margin-right: 4px;
  }

  @media screen and (max-width: 480px) {
    margin-top: 0;
    margin-bottom: 4px;
  }
`;

export const UserRate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 25px;
  border-radius: 100px;
  background: #2d40ff;

  .thumbs {
    width: 12px;
    height: 12px;
    border-radius: 0;
  }

  div {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: -0.48px;
  }

  @media screen and (max-width: 480px) {
    height: 23px;
  }
`;

interface RatingProps {
  item: {
    guest?: {
      count: number;
      overall: string;
    };
    property: {
      rating: string;
      type: string;
    };
  };
  mgt?: string;
}
export default function Rating({ item, mgt }: RatingProps) {
  return (
    <Container $mgt={mgt}>
      <UserRate>
        <div>
          <img className="thumbs" src="/img/thumbsup.svg" alt="thumbs" />{" "}
          {item.guest ? item.guest.overall : 0}
        </div>
      </UserRate>
      <div className="rating">
        <div className="review">
          {item.guest ? item.guest.count : 0}개의 리뷰
        </div>
        <div> / {Number(item.property.rating)}성급</div>
      </div>
    </Container>
  );
}

import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

export const ModlalContainer = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ContentBox = styled.div`
  width: 900px;
  height: 800px;

  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.03);
  border-radius: 4px;

  table {
    table-layout: initial;
  }
  @media screen and (max-width: 768px) {
    height: 100%;
  }
`;

const Close = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    padding: 0.4rem 0.7rem;
    cursor: pointer;
    font-size: 20px;

    border: 0 none;
    background-color: transparent;
    outline: none;
  }

  @media screen and (max-width: 768px) {
    margin-top: 10px;
    padding: 0 10px;
  }
`;

const Content = styled.div`
  padding: 16px;
`;

const Iframe = styled.iframe`
  border: none;
  width: 100%;
  height: 720px;
`;

interface ModalProps {
  setModal: Dispatch<SetStateAction<boolean>>;
  modalData: {
    txt: string;
    link: string;
  };
}

export default function Modal({ setModal, modalData }: ModalProps) {
  return (
    <ModlalContainer>
      <ContentBox>
        <Close>
          <button onClick={() => setModal(false)}>×</button>
        </Close>
        <Content style={{ padding: "16px" }}>
          <Iframe title={modalData.txt} src={modalData.link}></Iframe>
        </Content>
      </ContentBox>
    </ModlalContainer>
  );
}

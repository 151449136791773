import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 100px;
  width: 1000px;

  .masking {
    -webkit-mask-image: linear-gradient(180deg, #000 50%, transparent);
  }

  @media screen and (max-width: 768px) {
    width: 390px;
    padding: 0 16px;
  }
`;

const BtnBox = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 30px;

  @media screen and (max-width: 768px) {
    padding-top: 36px;
  }
`;

export const Button = styled.button<{ color: string }>`
  width: 100%;
  height: 45px;
  cursor: pointer;
  margin-top: 10px;
  color: #ffffff;
  background: ${(props) => props.color};
  border: none;
  border-radius: 5px;

  font-size: 14px;
  letter-spacing: -0.42px;
`;

interface Image {
  n: string;
  w: string;
  h: string;
  img: string;
}

declare global {
  interface Window {
    sayHello: any;
    NativeInterface: any;
  }
}

export default function HotdealDetails() {
  const [showMore, setShowMore] = useState(false);
  const [images, setImages] = useState({
    topImg: { img: "" },
    imgArr: [],
    bottomImg: { img: "" },
  });
  const [payment, setPayment] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.split("/");

  const getDetail = async () => {
    const res = await axios.get(`/event/product/${path[path.length - 1]}`);
    const image_list = JSON.parse(res.data.data.product_image_list);

    setImages({
      topImg: image_list[0],
      imgArr: image_list.slice(1, -1),
      bottomImg: image_list[image_list.length - 1],
    });
    setPayment(res.data.data.product_payment);
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <>
      <Container>
        <img
          className={showMore ? "" : "masking"}
          src={images.topImg.img}
          alt="top"
        />
        {showMore &&
          images.imgArr.map((item: Image) => (
            <img key={item.img} src={item.img} alt={item.n} />
          ))}
        <Button
          color="#ffffff"
          style={{
            border: "2px solid #2D40FF",
            color: "#2D40FF",
            marginBottom: "10px",
          }}
          onClick={() => {
            setShowMore(!showMore);
          }}
        >
          {showMore ? "상세정보 접기" : "상세정보 더보기"}
        </Button>
        <img src={images.bottomImg.img} alt="bottom" />
        {images.imgArr.length !== 0 && (
          <BtnBox>
            {payment && (
              <Button
                color="#2D40FF"
                onClick={() => {
                  navigate(`/hotdealpayment/${path[path.length - 1]}`);
                }}
              >
                결제하기
              </Button>
            )}

            <Button
              color="#A8ABBF"
              onClick={() => {
                navigate("/hotdealreservation/");
              }}
            >
              예약조회
            </Button>
          </BtnBox>
        )}
      </Container>
    </>
  );
}

import { styled } from "styled-components";
import CryptoJS from "crypto-js";
import axios from "axios";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import LoadingScreen from "../components/LoadingScreen";
import { useLocation, useNavigate } from "react-router-dom";
import { loginAtom } from "../components/NavBar";
import { useAtom } from "jotai";

const Container = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1200px;
  margin-top: 85px;

  @media screen and (max-width: 768px) {
    margin-top: 45px;
    padding: 0 16px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  max-width: 480px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 60px;
  padding: 20px 40px 65px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(105, 88, 142, 0.1);
  border-radius: 10px;

  h3 {
    margin: 0;
    margin-top: 45px;
    margin-bottom: 60px;
    text-align: center;
    color: #122437;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: -1.08px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 20px;

    h3 {
      font-size: 30px;
    }
  }
`;

const InputWrap = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  margin-bottom: 20px;
  background: #fff;
  overflow: hidden;

  input {
    width: 100%;
    padding: 12px 20px 12px 8px;
    border: 0;
    border-bottom: 1px solid #ddd;
    outline: none;
    font-size: 16px;
  }

  input:focus {
    border-bottom: 1px solid #2d40ff;
  }

  input::placeholder {
    color: #aaa;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  input:disabled {
    -webkit-box-shadow: 0 0 0 30px rgb(250, 250, 250) inset !important;
    -webkit-text-fill-color: rgb(153, 153, 153);
  }
  input:disabled:hover,
  input:disabled:focus {
    -webkit-box-shadow: 0 0 0 30px rgb(250, 250, 250) inset !important;
    -webkit-text-fill-color: rgb(153, 153, 153);
  }
`;

const Button = styled.button<{ $bg?: string }>`
  width: 100%;
  max-width: 100px;
  padding: 14px 24px;
  border: 0;
  border-radius: 5px;
  background: ${(props) => (props.$bg ? props.$bg : "#2d40ff")};
  color: #fff;
  font-size: 14px;
  letter-spacing: -0.42px;
  cursor: pointer;
`;

const CheckBtn = styled(Button)`
  max-width: initial;
  margin-top: 40px;
`;

export default function Password() {
  const navigate = useNavigate();
  const change = useLocation().pathname.includes("change");
  const [email, setEmail] = useState("");
  const [auth, setAuth] = useState("");
  const [active, setActive] = useState([true, false]);
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);
  const [origin, setOrigin] = useState("");
  const [data, setData] = useState({
    pw: "",
    check: "",
  });
  const pwRef = useRef<HTMLInputElement>(null);
  const [login, setlogin] = useAtom(loginAtom);

  const sendAuth = async () => {
    if (!active[0]) return;

    let act = [...active];
    setLoading(true);
    try {
      const res = await axios.post("/v1/auth/email/request", { email: email });
      setLoading(false);
      if (res.data.code === 0) {
        act[0] = false;
        setActive(act);
        alert("입력하신 이메일로 인증번호가 전송되었습니다.");
      }
    } catch (e: any) {
      e.response.data.code === 1011
        ? alert("존재하지 않는 이메일입니다. 입력하신 이메일을 확인해 주세요.")
        : alert(e.response.data.message);
      setLoading(false);
    }
  };

  const verifyAuth = async () => {
    if (active[0] || !active[1]) return;

    try {
      const res = await axios.post("/v1/auth/email/verify", {
        email: email,
        auth_code: auth,
      });
      if (res.data.code === 0) {
        setReset(true);
      }
    } catch (e: any) {
      alert(e.response.data.message);
    }
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    (e.target.name === "pw" || "check") &&
      setData({ ...data, [e.target.name]: e.target.value });

    e.target.name === "origin" && setOrigin(e.target.value);

    e.target.name === "email"
      ? setEmail(e.target.value)
      : setAuth(e.target.value);
  };

  useEffect(() => {
    let act = [...active];
    if (auth.length === 6) {
      act[1] = true;
      setActive(act);
    } else {
      act[1] = false;
      setActive(act);
    }
  }, [auth]);

  useEffect(() => {
    if (reset && pwRef.current) {
      pwRef.current.value = "" || "";
    }
  }, [reset]);

  const resetPassword = async () => {
    const pw_regex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$/;
    if (change && !origin) return alert("현재 비밀번호를 입력해 주세요.");
    if (!data.pw || !data.check) return alert("비밀번호를 입력해 주세요.");
    if (!pw_regex.test(data.pw))
      return alert(
        "비밀번호는 영문,숫자 포함 6자리 이상 조합하여 사용해야 합니다."
      );
    if (data.pw !== data.check) return alert("비밀번호가 일치하지 않습니다.");

    const token = sessionStorage.getItem("actk");
    const msq = sessionStorage.getItem("msq");

    try {
      const url = change
        ? `/v1/members/${msq}/update-password`
        : "/v1/members/reset-password";
      const body = change
        ? {
            origin_password: CryptoJS.SHA256(origin).toString(),
            new_password: CryptoJS.SHA256(data.pw).toString(),
          }
        : {
            email: email,
            reset_password: CryptoJS.SHA256(data.pw).toString(),
          };

      const res = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.data.code === 0) {
        if (change) {
          setlogin("");
          sessionStorage.removeItem("actk");
        }
        navigate("/password/success");
      }
    } catch (e: any) {
      alert(e.response.data.message);
    }
  };

  return (
    <Container>
      {change ? (
        <InputContainer>
          <h3>비밀번호 변경</h3>
          <InputWrap>
            <input
              type="password"
              name="origin"
              placeholder="현재 비밀번호"
              onChange={handleInput}
            />
          </InputWrap>
          <InputWrap>
            <input
              type="password"
              name="pw"
              ref={pwRef}
              placeholder="새로운 비밀번호(영문,숫자 포함 6자리 이상)"
              onChange={handleInput}
            />
          </InputWrap>
          <InputWrap>
            <input
              type="password"
              name="check"
              placeholder="새로운 비밀번호 확인"
              onChange={handleInput}
            />
          </InputWrap>
          <CheckBtn onClick={resetPassword}>확인</CheckBtn>
        </InputContainer>
      ) : (
        <>
          {loading && <LoadingScreen />}
          <InputContainer>
            <h3>{reset ? "비밀번호 재설정" : "비밀번호 찾기"}</h3>

            {reset ? (
              <>
                <InputWrap>
                  <input
                    type="text"
                    name="id"
                    placeholder="아이디(이메일)"
                    disabled={true}
                  />
                </InputWrap>
                <InputWrap>
                  <input
                    type="password"
                    name="pw"
                    ref={pwRef}
                    placeholder="비밀번호(영문,숫자 포함 6자리 이상)"
                    onChange={handleInput}
                  />
                </InputWrap>
                <InputWrap>
                  <input
                    type="password"
                    name="check"
                    placeholder="비밀번호 확인"
                    onChange={handleInput}
                  />
                </InputWrap>
                <CheckBtn onClick={resetPassword}>확인</CheckBtn>
              </>
            ) : (
              <>
                <InputWrap>
                  <input
                    type="text"
                    name="email"
                    disabled={!active[0]}
                    placeholder="아이디(이메일)"
                    onChange={handleInput}
                  />
                  <Button $bg={active[0] ? "" : "#AAA"} onClick={sendAuth}>
                    인증요청
                  </Button>
                </InputWrap>

                <InputWrap>
                  <input
                    type="text"
                    name="auth"
                    inputMode="numeric"
                    placeholder="인증번호 6자리"
                    maxLength={6}
                    onChange={handleInput}
                  />
                  <Button
                    $bg={!active[0] && active[1] ? "" : "#AAA"}
                    onClick={verifyAuth}
                  >
                    인증확인
                  </Button>
                </InputWrap>
              </>
            )}
          </InputContainer>
        </>
      )}
    </Container>
  );
}

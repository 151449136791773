import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { styled } from "styled-components";
import { UserRate } from "./Rating";

const Container = styled.div`
  width: 100%;
  max-width: 375px;

  h3 {
    margin: 0;
    margin-bottom: 30px;
    color: #161a3f;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.8px;
  }

  img {
    width: 100%;
    border-radius: 10px;
  }
  .heading {
    padding-bottom: 30px;

    h3 {
      margin: 16px 0 6px;
      color: #161a3f;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.72px;
    }
  }

  .address {
    margin-bottom: 14px;
    color: #5c5f79;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.56px;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 30px 0;
    border-top: 1px solid #f6f6f9;

    h3 {
      margin: 0;
      margin-bottom: 8px;
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;

    color: #161a3f;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.64px;

    .title {
      word-break: keep-all;
      margin-right: 32px;
    }
    .room_name {
      word-break: break-all;
    }
    .detail {
      margin: 0;
      color: #161a3f;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.72px;
    }
  }
  .total {
    margin-top: 8px;
    font-size: 18px;
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    max-width: initial;

    h3 {
      margin-bottom: 14px;
    }
  }
`;

const Rate = styled(UserRate)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 23px;
  border-radius: 100px;
  background: #2d40ff;

  div {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: -0.48px;
  }
`;

export default function DetailCard({ info }: any) {
  const temp = useLocation().pathname.includes("temp");
  const [searchParams, setSearchParams] = useSearchParams();
  const check_in = searchParams.get("check_in");
  const check_out = searchParams.get("check_out");
  const occupancy = searchParams.getAll("occupancy");
  const [night, setNight] = useState(0);
  const exclusive =
    Math.max(
      ...info.pricing.map((el: any) =>
        Number(el.exclusive.billable_currency.value)
      )
    ) * occupancy.length;
  const total =
    Math.max(
      ...info.pricing.map((el: any) =>
        Number(el.inclusive.billable_currency.value)
      )
    ) * occupancy.length;

  useEffect(() => {
    const nights = Math.ceil(
      Math.abs(
        new Date(check_in ? check_in : "").getTime() -
          new Date(check_out ? check_out : "").getTime()
      ) /
        (1000 * 60 * 60 * 24)
    );
    setNight(nights);
  }, []);

  return (
    <Container>
      <h3>예약 세부내역</h3>
      {info.image && <img src={info.image} alt="hotel" />}

      {info.detail && (
        <div className="heading">
          <h3>{info.detail.name}</h3>
          {info.detail.address && (
            <div className="address">
              {info.detail.address.city + " " + info.detail.address.line_1}
            </div>
          )}
          {info.detail.ratings && (
            <Rate>
              <div>
                <img className="thumbs" src="/img/thumbsup.svg" alt="thumbs" />{" "}
                {info.detail.ratings.guest.overall}
              </div>
            </Rate>
          )}
        </div>
      )}

      {!temp && (
        <>
          <div className="container">
            <div className="flex">
              <span className="title">객실</span>
              <span className="room_name">{info.roomName}</span>
            </div>
            <div className="flex">
              <span className="title">체크인</span>
              <span>{check_in && check_in}</span>
            </div>
            <div className="flex">
              <span className="title">체크아웃</span>
              <span>{check_out && check_out}</span>
            </div>
          </div>

          <div className="container">
            <h3 className="detail">요금 세부 정보</h3>
            <div className="flex">
              <span>
                객실 {occupancy.length}개 x {night}박
              </span>
              <span>{"₩ " + exclusive.toLocaleString()}</span>
            </div>
            <div className="flex">
              <span>세금 및 수수료</span>
              <span>{"₩ " + (total - exclusive).toLocaleString()}</span>
            </div>

            <div className="flex total">
              <span>합계</span>
              <span>{"₩ " + total.toLocaleString()}</span>
            </div>
          </div>
        </>
      )}
    </Container>
  );
}

import axios from "axios";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";

const Container = styled.div`
  width: 100%;
  max-width: 500px;
  margin: auto;
  margin-top: 45px;
  margin-bottom: 60px;

  form {
    display: none;
  }

  @media screen and (max-width: 768px) {
    margin-top: 25px;
    padding: 0 16px;
  }
`;

const Content = styled.div`
  padding: 32px 30px;
  border-radius: 10px;
  background: rgba(247, 247, 252, 0.4);

  .product_name {
    margin: 0 0 30px;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
  }

  .balance {
    padding-bottom: 30px;
    p {
      margin: 8px 0;
      text-align: center;
    }
  }

  .input_wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
  }
  .won {
    font-size: 18px;
  }

  input {
    width: 140px;
    padding: 12px 0;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    border: none;
    outline: none;
  }

  input::placeholder {
    color: #a9a9a9;
    font-weight: 500;
  }

  .btn_container {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    gap: 4px;

    span {
      width: 100%;
      padding: 6px 8px;
      background: #fff;
      border: 1px solid #e7e7ee;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 500;
      color: #787878;
      text-align: center;
      cursor: pointer;
    }

    @media screen and (max-width: 480px) {
      span {
        font-size: 12px;
        border-radius: 8px;
      }
    }
  }
`;

const Keypad = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, 118px);
    justify-content: space-between;
    gap: 8px 0;
    padding-top: 20px;

    span {
      text-align: center;
      padding: 12px 0;
      border-radius: 5px;
      font-size: 20px;
      font-weight: 500;
    }
    .del {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 20px;
      }
    }
    span: active {
      background: #dddddd;
    }
  }

  @media screen and (max-width: 447px) {
    grid-template-columns: repeat(auto-fill, 89px);
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 10px 0;

  margin: auto;
  margin-top: 30px;
  width: 100%;
  padding: 12px 0;
  border-radius: 5px;
  background: #2d40ff;
  color: #fff;
  font-size: 14px;
  letter-spacing: -0.42px;
  border: none;
  cursor: pointer;
`;

export default function EventPayment() {
  const navigate = useNavigate();
  const [price, setPrice] = useState("");
  const id = useParams().id;
  const phone = sessionStorage.getItem("pd_pm");
  const [balance, setBalance] = useState(0);
  const [data, setData] = useState<any>();
  const keypad = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "00", "0"];
  const formRef = useRef<HTMLFormElement>(null);

  const getBalance = async () => {
    const res = await axios.get(
      `/product/reservation?reservation_id=${id}&phone=${phone}`
    );
    setBalance(res.data.data.balance_amount);
    setData(res.data.data);
  };

  useEffect(() => {
    getBalance();
  }, []);

  const addPrice = (p: number) => {
    const num = Number(price.replaceAll(",", ""));
    if (num + p > 99999999) return;
    setPrice((num + p).toString());
  };

  const handleKeypad = (num: string) => {
    const newPrice = price + num;
    if (newPrice.length > 8) return;
    if (!price && (num === "0" || num === "00")) return;
    setPrice(newPrice);
  };

  const mem_seq = sessionStorage.getItem("msq");
  // const uid = sessionStorage.getItem("uid");
  const user = navigator.userAgent;

  const handleSubmit = () => {
    if (Number(price) < 10000) return alert("최소 결제금액은 1만원 입니다.");
    if (Number(price) > balance)
      return alert(
        "결제금액이 잔여 결제 금액보다 큽니다. 결제금액을 다시 입력해 주세요."
      );
    if (balance - Number(price) < 10000 && balance - Number(price) > 0)
      return alert("잔여 결제금액을 1만원 미만으로 남길 수 없습니다.");

    formRef.current?.submit();
  };

  //잔여결제금액을 초과할 수 없습니다.
  //최소 결제금액은 1만원 입니다.
  //잔여 결제금액을 1만원 미만으로 남길 수 없습니다.

  return (
    <Container>
      <Content>
        <h3 className="product_name">{data?.product_name}</h3>
        <div className="balance">
          <p>잔여 결제 금액</p>
          <p>{balance.toLocaleString()} 원</p>
        </div>

        <div className="input_wrap">
          <input
            type="text"
            inputMode="none"
            maxLength={10}
            placeholder="결제금액"
            value={price ? Number(price).toLocaleString() : ""}
            onInput={(e: any) =>
              (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setPrice(e.target.value.replaceAll(",", ""));
            }}
          />
          {price && <span className="won">원</span>}
        </div>
        <div className="btn_container">
          <span onClick={() => addPrice(10000)}>+1만</span>
          <span onClick={() => addPrice(50000)}>+5만</span>
          <span onClick={() => addPrice(100000)}>+10만</span>
          <span onClick={() => setPrice(balance.toString())}>전액</span>
        </div>
        <Keypad>
          {keypad.map((num: string) => (
            <span key={num} onClick={() => handleKeypad(num)}>
              {num}
            </span>
          ))}
          <span className="del" onClick={() => setPrice(price.slice(0, -1))}>
            <img src="/img/arrow_left.svg" alt="delete" />
          </span>
        </Keypad>
      </Content>
      <form ref={formRef} action={process.env.REACT_APP_DANAL_API} method="GET">
        <input type="hidden" name="reservation_id" value={id} />
        <input
          type="hidden"
          name="member_seq"
          value={mem_seq || process.env.REACT_APP_MEMBER_SEQ}
        />
        <input type="hidden" name="credit_amount" value={price} />
        <input type="hidden" name="payment_type" value="CREDIT" />
        <input
          type="hidden"
          name="user_agent"
          value={
            user.indexOf("iPhone") > -1 || user.indexOf("Android") > -1
              ? "WM"
              : "WP"
          }
        />
        <input type="hidden" name="client_id" value="1" />
        {/* 비밀리 id = 518*/}
      </form>
      <Button onClick={handleSubmit}>다음</Button>
    </Container>
  );
}

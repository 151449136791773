import { styled } from "styled-components";
import Rating from "./Rating";
import Price from "./Price";
import { Link } from "react-router-dom";
import { useAtomValue } from "jotai";
import { defaultSelectedAtom } from "./SearchForm";
import { format } from "date-fns";
import { occuListAtom } from "./Occupancy";

const Container = styled.div`
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(242, 242, 246, 0.7);
  margin-bottom: 30px;
  cursor: pointer;

  img {
    width: 100%;
    max-width: 250px;
    height: 170px;
    object-fit: cover;
    border-radius: 10px;
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
    gap: 16px;
    img {
      width: 100%;
      max-width: 128px;
      height: 170px;
    }
  }
`;

const Title = styled.div`
  width: 100%;
  max-width: 560px;
  padding: 2px 0 0 0;

  h3 {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 18px;
    letter-spacing: -0.72px;
  }
  span {
    color: #5c5f79;
    font-size: 14px;
    letter-spacing: -0.56px;
  }

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 170px;
    padding: 0;

    .heading {
      display: flex;
      flex-direction: column;
    }

    h3 {
      margin-bottom: 0;
      font-size: 16px;
      letter-spacing: -0.64px;
      word-break: keep-all;
    }

    span {
      font-size: 12px;
      letter-spacing: -0.48px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  max-height: 94px;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    justify-content: flex-end;

    .days {
      display: none;
    }
  }
`;

const Night = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  img {
    width: 18px;
    height: 18px;
    margin-right: 2px;
  }
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

interface Item {
  item: {
    property_id: number;
    address: string;
    image: {
      links: {
        "350px": {
          href: string;
        };
      };
    };
    name: string;
    rating: {
      guest?: {
        count: number;
        overall: string;
      };
      property: {
        rating: string;
        type: string;
      };
    };
    occupancy_pricing: any;
    promotions: {
      deal: {
        description: string;
      };
    };
  };
}
export default function HotelCard({ item }: Item) {
  const date = useAtomValue(defaultSelectedAtom);
  const occuList = useAtomValue(occuListAtom);
  const check_in = date?.from && format(date.from, "yyyy-MM-dd");
  const check_out = date?.to && format(date.to, "yyyy-MM-dd");
  const occu_list = occuList
    .map(
      (item: any) =>
        `&occupancy=${item.adults}${
          item.children.length !== 0 ? "-" + item.children : ""
        }`
    )
    .join("");

  return (
    <Link
      style={{ textDecoration: "none", color: "initial" }}
      to={`/hotels/${item.property_id}/rooms/availability?check_in=${check_in}&check_out=${check_out}${occu_list}`}
    >
      <Container>
        <img src={item.image?.links["350px"].href} alt="hotel_main_img" />
        <Title>
          <div className="heading">
            <h3>{item.name}</h3>
            <span>{item.address}</span>
          </div>
          <Content>
            <div>
              <Rating item={item.rating} />
              <Night>
                <img src="/img/clock.svg" alt="1night" />
                <span>1 Days</span>
              </Night>
            </div>
            <Price price={item.occupancy_pricing} promo={item.promotions} />
          </Content>
        </Title>
      </Container>
    </Link>
  );
}

import React, { useState, ChangeEvent, useEffect, KeyboardEvent } from "react";
import styled from "styled-components";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Content, ContentCard } from "./Success";

const Container = styled.div`
  width: 100%;
  max-width: 500px;
  margin: auto;
  margin-bottom: 360px;

  .title {
    margin: 75px 0 40px;
    color: #161a3f;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -1.2px;
  }
  .img_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-top: 30px;

    span {
      color: #a3a5b8;
      font-size: 14px;
      letter-spacing: -0.42px;
    }
  }

  .etc {
    color: #161a3f;
    font-size: 14px;
    letter-spacing: -0.56px;
    word-wrap: break-word;

    div {
      margin-top: 8px;

      white-space: break-spaces;
      text-align: left;
      margin-left: 12px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

const InputContainer = styled.div`
  margin-bottom: 40px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 12px;
  border-radius: 100px;
  border: 1px solid #161a3f;
  overflow: hidden;

  img {
    position: absolute;
    right: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  input {
    width: 100%;
    height: 46px;
    padding-left: 8px;
    border: none;
    outline: none;
    font-size: 16px;
  }
`;

interface Data {
  product_banner_image: string;
  product_name: string;
  reservation_id: number;
  user_name: string;
  user_phone: string;
  user_email: string;
  product_etc: string;
}

export default function HotdealReservation() {
  const location = useLocation();
  const path = location.pathname.split("/");
  const [number, setNumber] = useState("");
  const [data, setData] = useState<Data>();

  const checkReservation = async (rid: string) => {
    try {
      const res = await axios.get(`/event/reservation/${rid}`);
      setData(res.data.data);
    } catch (e: any) {
      alert(e.response.data.message);
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      checkReservation(number);
    }
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setNumber(e.target.value);
  };

  useEffect(() => {
    path[path.length - 1] && checkReservation(path[path.length - 1]);
  }, []);

  return (
    <Container>
      <h3 className="title">핫딜상품 예약조회</h3>

      <InputContainer>
        <input
          type="text"
          placeholder="구매 번호를 입력하세요"
          onChange={handleInput}
          onKeyDown={onKeyDown}
        />
        <img
          src="/img/lens.svg"
          alt="search"
          onClick={() => checkReservation(number)}
        />
      </InputContainer>

      {!data && (
        <div className="img_wrapper">
          <img src="/img/finder.svg" alt="finder" />
          <span>구매 번호로 검색해 주세요</span>
        </div>
      )}
      {data && (
        <Content>
          <img src={data.product_banner_image} alt="product_img" />
          <ContentCard>
            <div>
              <span>상품명</span>
              <span>{data.product_name.replace("[진행중]", "")}</span>
            </div>
            <div>
              <span>구매 번호</span>
              <span>{data.reservation_id}</span>
            </div>
            <div>
              <span>구매자 이름</span>
              <span>{data.user_name}</span>
            </div>
            <div>
              <span>휴대폰 번호</span>
              <span>{data.user_phone}</span>
            </div>
            <div>
              <span>이메일</span>
              <span>{data.user_email}</span>
            </div>
          </ContentCard>
          <div className="etc">
            <span>상세정보</span>
            <div>{data.product_etc}</div>
          </div>
        </Content>
      )}
    </Container>
  );
}

import axios from "axios";
import { addDays, getUnixTime } from "date-fns";
import { useEffect } from "react";

export default function TestPage() {
  const apikey = "188464611d598df9";
  const email = "wisekey2020@gmail.com";
  const today = new Date();
  const expire = getUnixTime(addDays(today, 1));

  const auth_key = btoa(email + ":" + apikey);

  const getToken = async () => {
    const res = await axios.get(`/v2/token?expire=${expire}`, {
      headers: {
        Authorization: `Basic ${auth_key}`,
      },
    });
    sessionStorage.setItem("miditk", res.data.token);
  };

  useEffect(() => {
    getToken();
  }, []);

  return <div>TestPage</div>;
}

import { styled } from "styled-components";

const Container = styled.div`
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(242, 242, 246, 0.7);
  margin-bottom: 30px;
  cursor: pointer;

  .img {
    width: 100%;
    max-width: 250px;
    height: 170px;
  }

  .skeleton {
    animation: skeleton-gradient 1.5s infinite ease-in-out;
    border-radius: 10px;

    @keyframes skeleton-gradient {
      0% {
        background-color: rgba(50, 50, 50, 0.1);
      }
      50% {
        background-color: rgba(50, 50, 50, 0.3);
      }
      100% {
        background-color: rgba(50, 50, 50, 0.1);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .img {
      width: 100%;
      max-width: 128px;
      height: 170px;
    }
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 560px;
  padding: 2px 0 0 0;

  .heading {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .title {
    max-width: 560px;
    height: 28px;
  }
  .address {
    max-width: 200px;
    height: 20px;
  }
  .price {
    max-width: 200px;
    height: 22px;
  }

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 170px;
    padding: 0;

    .heading {
      display: flex;
      flex-direction: column;
    }

    .address {
      max-width: 140px;
      height: 20px;
    }
    .price {
      max-width: 100px;
      height: 18px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  height: 100%;
  max-height: 94px;
  justify-content: space-between;
  align-items: end;

  .wrap {
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    justify-content: flex-end;
    align-items: initial;

    .days {
      display: none;
    }
  }
`;

export default function LoadingCard() {
  return (
    <Container>
      <div className="img skeleton" />
      <Title>
        <div className="heading">
          <div className="title skeleton" />
          <div className="address skeleton" />
        </div>
        <Content>
          <div className="rating" />
          <div className="wrap">
            <div className="price skeleton" />
            <div className="price skeleton" />
          </div>
        </Content>
      </Title>
    </Container>
  );
}

import { styled } from "styled-components";
import { Container } from "./ImgCarousel";
import { useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { RoomAtom, RoomImgAtom } from "./RoomCard";

const Cont = styled(Container)`
  background-color: rgba(0, 0, 0, 0.8);

  .room_img {
    width: 100%;
    height: 100%;
    max-width: initial;
    object-fit: cover;
    border-radius: 0;
  }
`;

export default function RoomPhoto() {
  const [index, setIndex] = useState(0);
  const setRoomModal = useSetAtom(RoomAtom);
  const RoomImg = useAtomValue(RoomImgAtom);

  const handleCarousel = (e: any) => {
    if (e.target.className.includes("left")) {
      index !== 0 && setIndex(index - 1);
    } else {
      RoomImg.length - 1 > index && setIndex(index + 1);
    }
  };

  return (
    <Cont>
      <button
        className="close"
        onClick={() => {
          setRoomModal(false);
        }}
      />
      <div className="btn_box">
        <button className="arrow left" onClick={handleCarousel} />
        <button className="arrow right" onClick={handleCarousel} />
      </div>
      <div className="wrapper">
        <div className="box">
          <img
            className="room_img"
            src={RoomImg[index].links["1000px"].href}
            alt={RoomImg[index].caption}
          />
        </div>
      </div>
    </Cont>
  );
}

import { Link } from "react-router-dom";
import { styled } from "styled-components";

const Container = styled.div`
  margin-top: 30px;

  width: 100%;
  height: 200px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 680px) {
    height: 70px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export default function TempAnnounce({ src }: any) {
  return (
    <Container>
      <Link to={src?.image_redirect_url} target="_blank">
        <img src={src?.image_url} alt="공지사항" />
      </Link>
    </Container>
  );
}

import { styled } from "styled-components";
import HomeHotelinventory from "../home_components/inventory/HomeHotelinventory";
import HomeSearch from "../components/HomeSearch";
import HomeAirtel from "../components/HomeAirtel";
import HomeEvent from "../components/HomeEvent";
import HomeHotDeal from "../components/HomeHotDeal";
import TempBanner from "./temp/components/TempBanner";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  margin-bottom: 100px;
`;

export const ScrollTopBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: none;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(206, 210, 255, 0.6);
  cursor: pointer;

  img {
    width: 32px;
    transform: rotate(180deg);
  }

  @media screen and (max-width: 768px) {
    bottom: 20px;
    right: 16px;
  }
`;

export default function Home() {
  return (
    <Container>
      <HomeSearch />
      <TempBanner />
      {/* <HomeHotDeal /> */}
      {/* <HomeAirtel /> */}
      <HomeEvent />
      {/* <HomeHotelinventory /> */}
    </Container>
  );
}

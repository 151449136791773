import { useState, ChangeEvent, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Modal from "../components/Modal";
import axios from "axios";
import useValidate from "../useValidate";

export const CenterBox = styled.div`
  margin: auto;
  width: 700px;

  .divide {
    margin: 0 40px;
    padding-left: 0;
  }

  .terms {
    display: block;
    font-size: 24px;
    margin-top: 24px;
  }

  @media screen and (max-width: 999px) {
    width: 100%;
    padding: 0 20px;

    .divide {
      margin: 0;
      padding-left: 40px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 390px;
    .divide {
      margin: 20px;
      padding-left: 0;
      padding-right: 0;
    }
    .terms {
      font-size: 20px;
      margin: 18px 4px 18px 20px;
    }
  }
`;

export const Button = styled.button<{ color: string }>`
  width: 100%;
  height: 45px;
  cursor: pointer;
  margin-top: 10px;
  color: #ffffff;
  background: ${(props) => props.color};
  border: none;
  border-radius: 5px;
  font-size: 14px;
  letter-spacing: -0.42px;
`;

const Image = styled.div`
  .image {
    width: 100%;
  }
  margin-bottom: 40px;
`;

export const Container = styled.div`
  width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.03);
  margin-bottom: 20px;

  .wide {
    display: flex;
    justify-content: space-between;
  }
  .wafl {
    width: 375px;
  }

  .in-block {
    width: initial;
    display: inline-block;
  }
  @media screen and (max-width: 768px) {
    .wide {
      width: initial;
      font-size: 20px;
      margin: 16px 20px;
    }

    .in-block {
      margin-right: 4px;
    }
  }
`;

export const ContentsBox = styled.div`
  padding: 16px 40px;
  border-top: 1px solid #eeeeee;

  .warning {
    color: #ff0000;
    text-align: left;
    margin: 8px 0 0 8px;
    font-size: 14px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 20px 0;
    .block {
      display: block;
    }
    select {
      margin-top: 12px;
    }
    input {
      margin-top: 12px;
    }
  }
`;

const Title = styled.div`
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  margin: 24px 40px;
`;

export const ItemsBox = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 24px;
  justify-content: space-between;

  select {
    font-size: 15px;
    appearance: none;
    width: 322px;
    border: 1px solid #0074ff;
    border-radius: 5px;
    padding: 12px 12px;
    background: url("/img/select_arrow.svg") no-repeat right 19px center;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  input {
    width: 322px;
    font-size: 15px;
    padding: 12px 12px;
    border: 1px solid #0074ff;
    border-radius: 5px;
  }

  @media screen and (max-width: 768px) {
    justify-content: space-between;
    width: initial;
    .item {
      width: initial;
    }
    select {
      width: 100%;
    }
    input {
      width: 100%;
    }
  }
`;

export const DocumentBox = styled.div<{ color: string; agree?: boolean }>`
  display: flex;
  color: ${(props) => props.color};
  margin-bottom: 14px;
  span {
    color: ${(props) => props.agree && "#0074ff"};
    margin-left: 8px;
    cursor: pointer;
  }
`;

export const Policy = styled.div`
  margin-left: 8px;
  cursor: pointer;
`;

export const CheckBox = styled.input`
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 3px;
  margin-left: 0px;
  margin-right: 13px;
`;

export interface ModalData {
  txt: string;
  link: string;
}

export default function HotdealPayment() {
  const location = useLocation();
  const path = location.pathname.split("/");
  const [check, setCheck] = useState(false);
  const [userData, setUserData] = useState({
    user_name: "",
    user_phone: "",
    user_email: "",
  });
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState<ModalData>({
    txt: "",
    link: "",
  });
  const [reserveId, setReserveId] = useState();
  const [detail, setDetail] = useState({
    product_name: "",
    product_seq: null,
    is_vat: null,
    product_image_list: "",
    product_policy_list: "",
    product_price: "",
  });
  const [payment, setPayment] = useState(false);
  const policy_list =
    detail.product_policy_list && JSON.parse(detail.product_policy_list);
  const detail_img =
    detail.product_image_list && JSON.parse(detail.product_image_list);

  const total_price = Number(detail.product_price);

  const price =
    detail.is_vat === 1 ? Math.round(total_price / 1.1) : total_price;

  const vat = detail.is_vat === 1 ? total_price - price : 0;

  const getDetail = async () => {
    const res = await axios.get(`/event/product/${path[path.length - 1]}`);
    setDetail(res.data.data);
    setPayment(res.data.data.product_payment);
  };

  useEffect(() => {
    getDetail();
  }, []);

  const handleModal = (index: number) => {
    setModal(true);
    setModalData(policy_list[index]);
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const valid = useValidate(userData.user_phone);

  const handleSubmit = async () => {
    const mid = sessionStorage.getItem("mem_seq");

    if (!check) {
      alert("이용약관 및 정책에 동의하셔야 구매하실 수 있습니다.");
      return;
    }
    if (!userData.user_name) {
      alert("이름을 입력하세요.");
      return;
    }
    if (!userData.user_phone) {
      alert("휴대폰 번호를 입력하세요.");
      return;
    }
    if (!userData.user_email) {
      alert("이메일을 입력하세요.");
      return;
    }
    if (valid) {
      alert("휴대폰 번호를 확인하세요.");
      return;
    }

    const data = {
      event_product_seq: detail.product_seq,
      ...userData,
      is_policy_check: check,
      payment_type: "credit",
      payment_price: total_price,
      user_seq: mid,
    };

    const res = await axios.post("/event/reservation", data);
    setReserveId(res.data.data.reservation_id);
  };

  useEffect(() => {
    const user = navigator.userAgent;
    const ua =
      user.indexOf("iPhone") > -1 || user.indexOf("Android") > -1 ? "WM" : "WP";

    const mem_seq = sessionStorage.getItem("msq");

    const url = `/nicepay/credit/event?reservation_id=${reserveId}&member_seq=${
      mem_seq ? mem_seq : process.env.REACT_APP_MEMBER_SEQ
    }&language=KO&card_from=KO&payment_type=CREDIT&credit_amount=${total_price}&user_agent=${ua}`;

    if (reserveId) {
      window.open(url, "_blank", "width=600,height=500");
    }
  }, [reserveId]);

  return (
    <>
      {modal && <Modal setModal={setModal} modalData={modalData} />}
      <CenterBox>
        <Image>
          <img
            className="image"
            src={detail_img && detail_img[0].img}
            alt="detail_img"
          />
        </Image>
        <Container>
          <Title className="wide in-block">구매고객 정보 입력</Title>
          <ContentsBox>
            <ItemsBox className="block">
              <div className="item">이름</div>
              <input
                type="text"
                name="user_name"
                placeholder="이름을 입력하세요"
                onChange={handleInput}
              />
            </ItemsBox>
            <ItemsBox className="block">
              <div className="item">휴대폰 번호</div>
              <div>
                <input
                  type="text"
                  name="user_phone"
                  placeholder="휴대폰 번호를 입력하세요"
                  onChange={handleInput}
                />
                {valid && <div className="warning">숫자만 입력하세요</div>}
              </div>
            </ItemsBox>

            <ItemsBox className="block">
              <div className="item">이메일</div>
              <input
                type="text"
                name="user_email"
                placeholder="이메일을 입력하세요"
                onChange={handleInput}
              />
            </ItemsBox>
          </ContentsBox>
        </Container>

        <Container>
          <Title className="wide">
            <div>최종 결제 금액</div>
            <div style={{ fontSize: "20px" }}>
              ₩ {total_price.toLocaleString("ko-KR")}
            </div>
          </Title>
          <ContentsBox>
            <ItemsBox className="block">
              <div className="item">결제수단</div>
              <select name="pay_type">
                <option value="CASH">신용/체크 카드</option>
              </select>
            </ItemsBox>
            <ItemsBox>
              <div className="item">구매금액</div>
              <div>₩ {price.toLocaleString("ko-KR")}</div>
            </ItemsBox>
            <ItemsBox>
              <div className="item">VAT 가격</div>
              <div>₩ {vat.toLocaleString("ko-KR")}</div>
            </ItemsBox>
          </ContentsBox>
        </Container>

        <Container>
          <Title className="terms">결제 주요사항 및 정책, 약관</Title>
          <ContentsBox style={{ paddingBottom: "36px" }}>
            <p style={{ marginBottom: "10px" }}>
              · 핫딜 상품에 대한 결제입니다.
            </p>
            <p style={{ marginBottom: "10px" }}>
              · 핫딜 상품결제 시 실제 사용자 성함으로 기재해주세요.
            </p>
            <p style={{ marginBottom: "10px" }}>
              · 핫딜 상품에 대한 환불은 환불규정을 참조해주세요.
            </p>
            <p style={{ marginBottom: "10px" }}>
              · 문의사항은 1533-7901로 부탁드립니다.
            </p>
            <p style={{ marginBottom: "10px" }}>
              · C/S 운영시간은 평일 오전 10시 ~ 17시 입니다.
            </p>
            <div style={{ marginTop: "22px" }}>
              {policy_list &&
                policy_list.map((policy: { txt: string }, i: number) => (
                  <DocumentBox key={policy.txt} color="#0074FF">
                    <img src="/img/doc_blue.svg" alt="document" />
                    <Policy
                      onClick={() => {
                        handleModal(i);
                      }}
                    >
                      {policy.txt}
                    </Policy>
                  </DocumentBox>
                ))}
            </div>
            <div
              style={{
                marginTop: "26px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CheckBox
                type="checkbox"
                name="agree"
                onChange={() => {
                  setCheck(!check);
                }}
              />
              <label>상기 내용에 모두 동의합니다.</label>
            </div>
          </ContentsBox>
        </Container>
        {payment ? (
          <Button
            // style={{ height: "60px", marginBottom: "120px" }}
            color="#2D40FF"
            onClick={handleSubmit}
          >
            상품 구매
          </Button>
        ) : (
          <Button
            // style={{ height: "60px", marginBottom: "120px" }}
            color="#A8ABBF"
          >
            구매가 불가능한 상품입니다
          </Button>
        )}
      </CenterBox>
    </>
  );
}

import { atom, useAtom } from "jotai";
import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { pageAtom } from "./SearchForm";

const Container = styled.div`
  margin-top: 40px;
  position: relative;
  width: 100%;

  @media screen and (max-width: 1100px) {
    padding: 0 16px;
    margin-top: 20px;
  }
`;

const Heading = styled.div`
  display: none;

  @media screen and (max-width: 1100px) {
    position: relative;
    display: block;
    text-align: center;
    padding-bottom: 4px;

    h3 {
      color: #161a3f;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.48px;
      margin: 0;
    }
    span {
      position: absolute;
      top: 4px;
      right: 0;
      color: #2d40ff;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.42px;
      cursor: pointer;
    }
  }
`;

const FilterBox = styled.div`
  padding-bottom: 6px;
  border-bottom: 1px solid #f6f6f9;

  .check {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 14px;
  }

  h3 {
    color: #161a3f;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.64px;
  }

  input {
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-bottom: 1px;
  }

  .radio {
    width: 18px;
    height: 18px;
  }

  label {
    color: #161a3f;
    font-size: 14px;
    letter-spacing: -0.42px;
  }

  .stars {
    display: flex;
    img {
      width: 16px;
      height: 16px;
      margin-right: 2px;
    }
  }
  .show_less {
    display: none;
  }
  .up {
    transform: rotate(180deg);
  }
`;

const ShowMore = styled.button`
  display: flex;
  gap: 4px;
  margin-bottom: 14px;
  padding-left: 4px;
  color: #5c5f79;
  font-size: 14px;
  letter-spacing: -0.42px;
  background-color: #fff;
  border: none;
  cursor: pointer;
`;

const BtnContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 24px 16px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, #fff 100%);

  @media screen and (min-width: 1100px) {
    display: none;
  }
`;

const CompleteBtn = styled.button`
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-top: 16px;
  width: 100%;
  padding: 12px 0;
  color: #fff;
  border-radius: 5px;
  background: #2d40ff;
  border: none;
  cursor: pointer;
`;

export const pricesAtom = atom<Number[]>([]);
export const ratingsAtom = atom("0");
export const starsAtom = atom<Number[]>([]);
export const typesAtom = atom<string[]>([]);
export const amenitiesAtom = atom<string[]>([]);
export const accessAtom = atom<string[]>([]);
export default function Filter() {
  const navigate = useNavigate();
  const [moreType, setMoreType] = useState(false);
  const [moreAmenities, setMoreAmenities] = useState(false);
  const [moreAccess, setMoreAccess] = useState(false);
  const [prices, setPrices] = useAtom(pricesAtom);
  const [ratings, setRatings] = useAtom(ratingsAtom);
  const [star, setStar] = useAtom(starsAtom);
  const [types, setTypes] = useAtom(typesAtom);
  const [amenities, setAmenities] = useAtom(amenitiesAtom);
  const [access, setAccess] = useAtom(accessAtom);
  const [page, setPage] = useAtom(pageAtom);

  const price = [
    "₩80,000 미만",
    "₩80,000 ~ ₩160,000",
    "₩160,000 ~ ₩270,000",
    "₩270,000 ~ ₩380,000",
    "₩380,000 이상",
  ];

  const rating = [
    { name: "전체", id: "0" },
    { name: "최고 좋음 4.5 이상", id: "45" },
    { name: "매우 좋음 4.0 이상", id: "40" },
    { name: "좋음 3.5 이상", id: "35" },
  ];

  const stars = [
    [5, 0],
    [4, 1],
    [3, 2],
    [2, 3],
    [1, 4],
  ];

  const type = [
    { name: "펜션", id: "PENSION" },
    { name: "호텔", id: "HOTEL" },
    { name: "빌라", id: "VILLA" },
    { name: "콘도", id: "CONDO" },
    { name: "콘도리조트", id: "CONDO_RESORT" },
    { name: "호텔리조트", id: "HOTEL_RESORT" },
    { name: "료칸", id: "RYOKAN" },
    { name: "휴가용 주택", id: "VACATION_HOME" },
    { name: "레지던스", id: "RESIDENCE" },
  ];

  const ameities = [
    { name: "반려동물 동반 가능", id: "PETS" },
    { name: "스파", id: "SPA_ON_SITE" },
    { name: "무료 공항 셔틀", id: "FREE_AIRPORT_TRANSPORTATION" },
    { name: "무료 WIFI", id: "WIFI" },
    { name: "수영장", id: "POOL" },
    { name: "워터파크", id: "WATER_PARK" },
    { name: "카지노", id: "CASINO" },
    { name: "레스토랑", id: "RESTAURANT_IN_HOTEL" },
    { name: "주차", id: "PARKING" },
    { name: "전기차 충전", id: "ELECTRIC_CAR" },
    { name: "야외 공간", id: "BALCONY_OR_TERRACE" },
    { name: "피트니스 센터", id: "GYM" },
  ];

  const accessibility = [
    { name: "휠체어로 이용 가능한 주차장", id: "ACCESSIBLE_PARKING" },
    { name: "엘리베이터", id: "ELEVATOR" },
    { name: "장애인 지원 욕실", id: "ACCESSIBLE_BATHROOM" },
    { name: "롤인 샤워", id: "ROLL_IN_SHOWER" },
    { name: "객실 내 장애인 편의 시설", id: "IN_ROOM_ACCESSIBLE" },
    { name: "장애인 안내 동물 동반 가능", id: "SERVICE_ANIMAL" },
    { name: "출입구까지 통로에 계단 없음", id: "STAIR_FREE_PATH" },
    { name: "수화 가능 직원", id: "SIGN_LANGUAGE_INTERPRETER" },
  ];

  const handlePrices = (e: ChangeEvent<HTMLInputElement>, i: number) => {
    if (page > 1) setPage(1);
    if (!e.target.checked) {
      setPrices([...prices.filter((el) => el !== i)]);
    } else {
      if (prices.length === 2) {
        e.target.checked = false;
        alert("가격 옵션은 2개까지만 선택할 수 있습니다.");
        return;
      }
      setPrices([...prices, i]);
    }
  };

  const handleStars = (e: ChangeEvent<HTMLInputElement>, item: number) => {
    if (page > 1) setPage(1);
    e.target.checked
      ? setStar([...star, item])
      : setStar([...star.filter((el) => el !== item)]);
  };

  const handleTypes = (e: ChangeEvent<HTMLInputElement>, item: string) => {
    if (page > 1) setPage(1);
    e.target.checked
      ? setTypes([...types, item])
      : setTypes([...types.filter((el) => el !== item)]);
  };

  const handleAmenities = (e: ChangeEvent<HTMLInputElement>, item: string) => {
    if (page > 1) setPage(1);
    e.target.checked
      ? setAmenities([...amenities, item])
      : setAmenities([...amenities.filter((el) => el !== item)]);
  };

  const handleAccess = (e: ChangeEvent<HTMLInputElement>, item: string) => {
    if (page > 1) setPage(1);
    e.target.checked
      ? setAccess([...access, item])
      : setAccess([...access.filter((el) => el !== item)]);
  };

  const handleReset = () => {
    setPrices([]);
    setRatings("0");
    setStar([]);
    setTypes([]);
    setAmenities([]);
    setAccess([]);
  };

  return (
    <Container>
      <Heading>
        <h3>검색 조건</h3>
        <span onClick={handleReset}>초기화</span>
      </Heading>
      <FilterBox>
        <h3>1박당 요금</h3>
        {price.map((item, i) => (
          <div className="check" key={item}>
            <input
              type="checkbox"
              name={item}
              id={i.toString()}
              checked={prices.includes(i)}
              onChange={(e) => handlePrices(e, i)}
            />
            <label>{item}</label>
          </div>
        ))}
      </FilterBox>
      <FilterBox>
        <h3>고객 평점</h3>
        {rating.map((item, i) => (
          <div className="check" key={item.id}>
            <input
              className="radio"
              type="radio"
              name="rating"
              id={item.id}
              value={item.id}
              checked={ratings === item.id}
              onChange={(e: any) => {
                setRatings(e.target.id);
              }}
            />
            <label>{item.name}</label>
          </div>
        ))}
      </FilterBox>
      <FilterBox>
        <h3>숙박 시설 등급</h3>
        {stars.map((item, i) => (
          <div className="check" key={item[0]}>
            <input
              type="checkbox"
              name={item[0].toString()}
              id={i.toString()}
              checked={star.includes(item[0])}
              onChange={(e) => handleStars(e, item[0])}
            />
            <label className="stars">
              {new Array(item[0]).fill("").map((item, i) => (
                <img key={i} src="/img/star_fill.svg" alt="star_fill" />
              ))}
              {new Array(item[1]).fill("").map((item, i) => (
                <img key={i} src="/img/star_empty.svg" alt="star_empty" />
              ))}
            </label>
          </div>
        ))}
      </FilterBox>
      <FilterBox>
        <h3>숙박 시설 유형</h3>
        {type.map((item, i) => (
          <div
            className={"check " + (i > 4 && !moreType ? "show_less" : "")}
            key={item.id}
          >
            <input
              type="checkbox"
              name={item.id}
              id={i.toString()}
              checked={types.includes(item.id)}
              onChange={(e) => handleTypes(e, item.id)}
            />
            <label>{item.name}</label>
          </div>
        ))}
        <ShowMore
          onClick={() => {
            setMoreType(!moreType);
          }}
        >
          {moreType ? "간단히 보기" : "더 보기"}
          <img
            className={moreType ? "up" : ""}
            src="/img/arrow_down.svg"
            alt="show_more"
          />
        </ShowMore>
      </FilterBox>
      <FilterBox>
        <h3>편의시설 서비스</h3>
        {ameities.map((item, i) => (
          <div
            className={"check " + (i > 4 && !moreAmenities ? "show_less" : "")}
            key={item.id}
          >
            <input
              type="checkbox"
              name={item.id}
              id={i.toString()}
              checked={amenities.includes(item.id)}
              onChange={(e) => handleAmenities(e, item.id)}
            />
            <label>{item.name}</label>
          </div>
        ))}
        <ShowMore
          onClick={() => {
            setMoreAmenities(!moreAmenities);
          }}
        >
          {moreAmenities ? "간단히 보기" : "더 보기"}
          <img
            className={moreAmenities ? "up" : ""}
            src="/img/arrow_down.svg"
            alt="show_more"
          />
        </ShowMore>
      </FilterBox>
      <FilterBox>
        <h3>장애인 편의 시설</h3>
        {accessibility.map((item, i) => (
          <div
            className={"check " + (i > 4 && !moreAccess ? "show_less" : "")}
            key={item.id}
          >
            <input
              type="checkbox"
              name={item.id}
              id={i.toString()}
              checked={access.includes(item.id)}
              onChange={(e) => handleAccess(e, item.id)}
            />
            <label>{item.name}</label>
          </div>
        ))}
        <ShowMore
          onClick={() => {
            setMoreAccess(!moreAccess);
          }}
        >
          {moreAmenities ? "간단히 보기" : "더 보기"}
          <img
            className={moreAccess ? "up" : ""}
            src="/img/arrow_down.svg"
            alt="show_more"
          />
        </ShowMore>
      </FilterBox>
      <BtnContainer>
        <CompleteBtn
          onClick={() => {
            navigate(-1);
          }}
        >
          완료
        </CompleteBtn>
      </BtnContainer>
    </Container>
  );
}

import { ChangeEvent, useEffect, useRef, useState } from "react";
import { styled } from "styled-components";

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 280px;
  height: 497px;
  border-radius: 10px;
  overflow: hidden;

  p {
    position: absolute;
    z-index: 10;
    margin: 20px 0;
    padding: 0 20px;
    color: #fff;
    font-weight: 500;
    letter-spacing: -0.56px;
  }

  .name {
    top: 0;
    width: 100%;
    font-size: 14px;
    height: 42px;
  }
  .title {
    bottom: 5px;
    margin-top: 0;
    font-size: 18px;
    letter-spacing: -0.72px;
  }

  .controls {
    position: absolute;
    z-index: 9;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 2px;
    width: 100%;
    height: 50px;
    padding: 0 8px 24px;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4), transparent);
    opacity: 0;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 1, 1);

    img {
      width: 24px;
      padding: 4px;
      cursor: pointer;
    }
    .speaker {
      width: 30px;
      height: 28px;

      img {
        width: 26px;
        transform: rotate(90deg);
      }
    }
  }
  .hover {
    opacity: 1;
    transition: opacity 0.5s cubic-bezier(0, 0, 0.2, 1);
  }

  .screener {
    position: absolute;
    z-index: 8;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: transparent;
  }
  video {
    width: 100%;
    height: 100%;
    background-color: #000;
  }
  video::-webkit-media-controls-play-button,
  ::-webkit-media-controls-timeline,
  ::-webkit-media-controls-current-time-display,
  ::-webkit-media-controls-time-remaining-display,
  ::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
  }
  // ::-webkit-media-controls-mute-button,
  // ::-webkit-media-controls-volume-slider
`;

const Status = styled.div<{ $play: boolean }>`
  display: ${(props) => (props.$play ? "block" : "none")};
  position: absolute;
  z-index: 7;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  transform: translate(-50%, -50%);

  div {
    opacity: 0;
    display: flex;
    width: 100%;
    max-width: 60px;
    height: 100%;
    max-height: 60px;
    border-radius: 50%;
    padding: 16px;
    background: rgba(0, 0, 0, 0.5);

    img {
      width: 100%;
      height: 100%;
    }

    animation: show 0.4s linear 1 normal forwards;

    @keyframes show {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        transform: scale(2);
      }
    }
  }
`;

const Volume = styled.div<{ $hover: boolean }>`
  transform: rotate(270deg);

  .stretch {
    width: 60px;
    padding: 4px 8px 4px 4px;
    display: flex;
    border-radius: 100px;
    background: ${(props) => props.$hover && "rgba(0, 0, 0, 0.85)"};

    input {
      width: 100%;
    }

    input[type="range"] {
      overflow: hidden;
      -webkit-appearance: none;
      background: transparent;
      cursor: pointer;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      height: 12px;
      background: #ffffff;
      border-radius: 10px;
      border: 4px solid rgba(0, 0, 0, 0.9);
    }

    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      margin-top: -4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 40px 0 0 34px rgba(0, 0, 0, 0.4);
    }
  }
  .show {
    width: 110px;
    transition: width 0.2s linear;
  }
`;

export default function VideoCard(props: { url: string }) {
  const videoRef = useRef<any>(null);
  const [mute, setMute] = useState(true);
  const [hover, setHover] = useState(false);
  const [speaker, setSpeaker] = useState(false);
  const [volume, setVolume] = useState(0.1);
  const [volBeforeMute, setVolBeforeMute] = useState(0.1);
  const [played, setPlayed] = useState("");

  const playAndPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current && videoRef.current.play();
        setPlayed("play");
      } else {
        videoRef.current && videoRef.current.pause();
        setPlayed("pause");
      }
    }
  };

  const handleLoadedData = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  useEffect(() => {
    const play = setTimeout(() => {
      if (played) setPlayed("");
    }, 400);
    return () => clearTimeout(play);
  }, [played]);

  const fullScreen = () => {
    const user = navigator.userAgent;

    if (videoRef.current) {
      if (user.indexOf("iPhone") > -1) {
        return videoRef.current.webkitEnterFullscreen();
      }
      videoRef.current.requestFullscreen();
    }
  };

  // const handleVolume = (e: ChangeEvent<HTMLInputElement>) => {
  //   const vol = Number(e.target.value);
  //   if (vol === 0) {
  //     setMute(true);
  //   } else setMute(false);
  //   setVolume(vol / 100);
  // };

  const handleMute = (param: boolean) => {
    if (param) {
      setMute(param);
      setVolBeforeMute(volume);
      setVolume(0);
    } else {
      setMute(param);
      setVolume(volBeforeMute);
    }
  };

  // useEffect(() => {
  //   if (videoRef.current) {
  //     videoRef.current.volume = volume;
  //   }
  // }, [volume]);

  // useEffect(() => {
  //   if (videoRef.current) {
  //     console.log(videoRef.current.muted);
  //   }

  //   if (videoRef.current.webkitIsFullScreen) {
  //     console.log(videoRef.current.muted);
  //     console.log(videoRef.current.webkitIsFullScreen);
  //   }
  // }, [videoRef]);

  const handleMutes = () => {
    // console.log("mute");
    // if (videoRef.current.webkitIsFullScreen) setMute(videoRef.current.muted);
    alert("full");
    // if (videoRef.current.webkitIsFullScreen) {
    //   console.log("풀스크린");
    // }
    // if (videoRef.current.fullscreenEnabled) {
    //   console.log("풀스크린??");
    // }
    // if (videoRef.current.isFullscreenNow) {
    //   console.log("풀스크린??");
    // }

    //webkitDisplayingFullscreen
  };

  useEffect(() => {
    window.addEventListener("webkitfullscreenchange", handleMutes);
    // window.addEventListener("fullscreenchange", handleMutes);
    return () => {
      window.removeEventListener("webkitfullscreenchange", handleMutes);
      // window.addEventListener("fullscreenchange", handleMutes);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("webkitDisplayingFullscreen", handleMutes);
    // window.addEventListener("fullscreenchange", handleMutes);
    return () => {
      window.removeEventListener("webkitDisplayingFullscreen", handleMutes);
      // window.addEventListener("fullscreenchange", handleMutes);
    };
  }, []);

  return (
    <Container
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <p className="name">베스트 웨스턴 하버 파머스 호텔</p>
      <p className="title">시원한 바다뷰 끝내줍니다!</p>

      <Status $play={played ? true : false}>
        <div>
          <img src={`/img/video_${played}.svg`} alt="play" />
        </div>
      </Status>

      <div className={"controls" + (hover ? " hover" : "")}>
        <div
          className="speaker"
          // onMouseOver={() => setSpeaker(true)}
          // onMouseOut={() => setSpeaker(false)}
        >
          <Volume $hover={speaker}>
            <div className={"stretch" + (speaker ? " show" : "")}>
              {mute ? (
                <img
                  src="/img/speaker-mute.svg"
                  alt="mute"
                  onClick={() => handleMute(false)}
                />
              ) : (
                <img
                  src="/img/speaker.svg"
                  alt="speaker"
                  onClick={() => handleMute(true)}
                />
              )}
              {/* {speaker && (
                <input
                  type="range"
                  name="volume"
                  value={volume * 100}
                  min={0}
                  max={100}
                  onChange={handleVolume}
                />
              )} */}
            </div>
          </Volume>
        </div>
        <img src="/img/fullscreen.svg" alt="full-screen" onClick={fullScreen} />
      </div>

      <div className="screener" onClick={playAndPause} />
      <video
        ref={videoRef}
        // muted={mute}
        // controls
        muted={mute}
        controlsList="nodownload noplaybackrate"
        disablePictureInPicture
        autoPlay
        onLoadedData={handleLoadedData}
        playsInline
        src={props.url}
      ></video>
    </Container>
  );
}

export const hotels = [
  {
    property_id: "1",
    name: "인천 호텔 블루마린",
    address: {
      line_1: "월미로234번길 12",
      line_2: "중구",
      city: "인천광역시",
      postal_code: "22303",
      country_code: "KR",
      obfuscation_required: false,
      localized: {
        links: {
          "ko-KR": {
            method: "GET",
            href: "https://api.ean.com/v3/properties/content?language=ko-KR&property_id=21899602&include=address&supply_source=expedia",
          },
        },
      },
    },
    ratings: {
      property: { rating: "2.5", type: "Star" },
      guest: {
        count: 13,
        overall: "4.0",
        cleanliness: "3.7",
        service: "3.7",
        comfort: "3.6",
        condition: "3.8",
        neighborhood: "3.7",
        amenities: "4.0",
        recommendation_percent: "100.0",
      },
    },
    location: {
      coordinates: { latitude: 37.475392, longitude: 126.598045 },
      obfuscation_required: false,
    },
    phone: "82-32-765 7142",
    fax: "82-032-7657144",
    category: { id: "1", name: "호텔" },
    business_model: { expedia_collect: true, property_collect: false },
    rank: 191772,
    checkin: {
      begin_time: "15:00 (토요일 : 17:00)",
      // end_time: "23:00",
      instructions:
        "<ul>  <li>추가 인원에 대한 요금이 부과될 수 있으며, 이는 숙박 시설 정책에 따라 다릅니다.</li><li>체크인 시 부대 비용 발생에 대비해 정부에서 발급한 사진이 부착된 신분증과 신용카드, 직불카드 또는 현금으로 보증금이 필요할 수 있습니다.</li><li>특별 요청 사항은 체크인 시 이용 상황에 따라 제공 여부가 달라질 수 있으며 추가 요금이 부과될 수 있습니다. 또한, 반드시 보장되지는 않습니다.</li><li>이 숙박 시설에서 사용 가능한 결제 수단은 신용카드, 직불카드, 현금입니다.</li>  </ul>",
      special_instructions:
        "예약 확인 메일에 나와 있는 연락처로 숙박 시설에 미리 연락하여 체크인 안내를 받으시기 바랍니다. 23:00 이후에 도착 예정이신 경우 예약 확인 메일에 나와 있는 연락처로 미리 숙박 시설에 연락해 주시기 바랍니다. 숙박 시설에 미리 연락해 체크인 지침을 꼭 확인해 주세요. 도착 시에는 프런트 데스크 직원이 도와드립니다.",
      min_age: 19,
    },
    checkout: { time: "11:00 (토요일 : 12:00) " },
    policies: {
      know_before_you_go:
        "<ul>  <li>만 7 세 이하 어린이는 부모 또는 보호자와 같은 객실에서 침구를 추가하지 않고 이용할 경우 무료로 숙박할 수 있습니다. </li><li>일부 시설의 경우 출입이 제한될 수 있습니다. 자세한 내용은 예약 확인 메일에 나와 있는 연락처 정보로 해당 숙박 시설에 직접 문의하실 수 있습니다. </li><li>이 숙박 시설은 현재 강화된 청소 및 고객 안전 조치를 시행 중입니다.</li><li>청소 시 소독제를 사용하고, 고객 숙박 간에 접촉이 많은 표면을 소독하며 침대 시트 및 타월을 60℃ 이상에서 세탁합니다.</li><li>고객의 안전을 위해 사회적 거리두기, 숙박 시설 직원의 개인 보호 장비 착용, 정기적으로 직원 체온 측정, 고객 체온 측정 가능, 고객에게 손 소독제 제공 등의 조치를 시행 중입니다.</li>  </ul>",
    },
    attributes: {
      pets: { 2050: { id: "2050", name: "반려동물 동반 불가" } },
      general: {
        2544: { id: "2544", name: "간이/추가 침대 이용 불가" },
        2545: { id: "2545", name: "유아용 침대 이용 불가" },
        1073744364: {
          id: "1073744364",
          name: "유니코드 지원 true",
          value: "true",
        },
        1073744992: { id: "1073744992", name: "숙박 시설을 소독제로 청소함" },
        1073744994: { id: "1073744994", name: "직원이 개인 보호 장비 착용함" },
        1073745002: { id: "1073745002", name: "고객에게 무료 손 소독제 제공" },
        1073745004: { id: "1073745004", name: "사회적 거리두기 시행 중" },
        1073745013: {
          id: "1073745013",
          name: "숙박 시설에서 현재 강화된 청소 조치 시행 중",
        },
        1073745045: {
          id: "1073745045",
          name: "지원 대상 체온 검사를 정기적으로 실시함",
        },
        1073745047: { id: "1073745047", name: "고객 체온 검사 실시 가능" },
        1073745049: {
          id: "1073745049",
          name: "침구 및 타월을 60°C 이상의 고온으로 세탁함",
        },
        1073745051: {
          id: "1073745051",
          name: "자주 만지는 표면을 소독제로 청소함",
        },
        1073745053: {
          id: "1073745053",
          name: "숙박 시설에서 현재 고객 안전 조치 시행 중",
        },
      },
    },
    amenities: {
      43: { id: "43", name: "콘시어지 서비스" },
      2016: { id: "2016", name: "프런트 데스크의 안전 금고" },
      2063: { id: "2063", name: "24시간 운영 프런트 데스크" },
      2137: { id: "2137", name: "금연 숙박 시설" },
      2387: { id: "2387", name: "투어/티켓 안내" },
      2390: { id: "2390", name: "무료 WiFi", categories: ["wifi"] },
      2392: { id: "2392", name: "무료 유선 인터넷" },
      2419: {
        id: "2419",
        name: "휠체어로 이동 가능한 통로",
        categories: ["accessibility"],
      },
      2422: {
        id: "2422",
        name: "휠체어로 이용 가능한 주차장",
        categories: ["accessible_parking"],
      },
      3861: { id: "3861", name: "무료 셀프 주차", categories: ["parking"] },
      4003: { id: "4003", name: "짐 보관 서비스" },
    },
    images: [
      {
        hero_image: true,
        category: 3,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9758fb63_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9758fb63_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9758fb63_z.jpg",
          },
        },
        caption: "기본 이미지",
      },
      {
        hero_image: false,
        category: 10001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/b38280f6_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/b38280f6_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/b38280f6_z.jpg",
          },
        },
        caption: "로비",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/78f39237_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/78f39237_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/78f39237_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/01974042_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/01974042_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/01974042_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9a524ad3_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9a524ad3_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9a524ad3_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/cb414c5a_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/cb414c5a_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/cb414c5a_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9ea8be51_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9ea8be51_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9ea8be51_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/8b64bafa_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/8b64bafa_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/8b64bafa_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/eee2c74b_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/eee2c74b_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/eee2c74b_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a09d42cf_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a09d42cf_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a09d42cf_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/5bea3c4a_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/5bea3c4a_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/5bea3c4a_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0c9639db_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0c9639db_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0c9639db_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/7dd22221_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/7dd22221_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/7dd22221_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/e5096a9d_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/e5096a9d_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/e5096a9d_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/cac3067a_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/cac3067a_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/cac3067a_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a3390119_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a3390119_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a3390119_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9c38d1f2_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9c38d1f2_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9c38d1f2_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/638f0184_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/638f0184_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/638f0184_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0a1b802c_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0a1b802c_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0a1b802c_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a0fd4e19_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a0fd4e19_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a0fd4e19_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/523dcea2_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/523dcea2_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/523dcea2_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/51d18965_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/51d18965_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/51d18965_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/bf780180_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/bf780180_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/bf780180_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/b7a7b3ac_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/b7a7b3ac_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/b7a7b3ac_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/14bb4658_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/14bb4658_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/14bb4658_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9ad372cd_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9ad372cd_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9ad372cd_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/75c553f9_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/75c553f9_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/75c553f9_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/2041b36c_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/2041b36c_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/2041b36c_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/e0d4c3ce_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/e0d4c3ce_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/e0d4c3ce_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/07e6d430_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/07e6d430_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/07e6d430_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/af71fa29_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/af71fa29_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/af71fa29_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/75ff13ee_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/75ff13ee_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/75ff13ee_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/31cd6431_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/31cd6431_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/31cd6431_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 21001,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/64edb7d8_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/64edb7d8_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/64edb7d8_z.jpg",
          },
        },
        caption: "객실",
      },
      {
        hero_image: false,
        category: 23000,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0e5e28db_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0e5e28db_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0e5e28db_z.jpg",
          },
        },
        caption: "욕실",
      },
      {
        hero_image: false,
        category: 23000,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/de4ea7b6_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/de4ea7b6_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/de4ea7b6_z.jpg",
          },
        },
        caption: "욕실",
      },
      {
        hero_image: false,
        category: 23000,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/98181875_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/98181875_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/98181875_z.jpg",
          },
        },
        caption: "욕실",
      },
      {
        hero_image: false,
        category: 23000,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/346fb0f3_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/346fb0f3_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/346fb0f3_z.jpg",
          },
        },
        caption: "욕실",
      },
      {
        hero_image: false,
        category: 23000,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/ca048b37_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/ca048b37_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/ca048b37_z.jpg",
          },
        },
        caption: "욕실",
      },
      {
        hero_image: false,
        category: 23000,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/8aaf80bf_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/8aaf80bf_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/8aaf80bf_z.jpg",
          },
        },
        caption: "욕실",
      },
      {
        hero_image: false,
        category: 23000,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/beb3ddcc_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/beb3ddcc_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/beb3ddcc_z.jpg",
          },
        },
        caption: "욕실",
      },
      {
        hero_image: false,
        category: 23003,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/6fe8955c_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/6fe8955c_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/6fe8955c_z.jpg",
          },
        },
        caption: "욕실 세면대",
      },
      {
        hero_image: false,
        category: 23003,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/bc85dde8_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/bc85dde8_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/bc85dde8_z.jpg",
          },
        },
        caption: "욕실 세면대",
      },
      {
        hero_image: false,
        category: 23003,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/301e0bf9_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/301e0bf9_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/301e0bf9_z.jpg",
          },
        },
        caption: "욕실 세면대",
      },
      {
        hero_image: false,
        category: 23004,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/934548fe_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/934548fe_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/934548fe_z.jpg",
          },
        },
        caption: "욕실 샤워",
      },
      {
        hero_image: false,
        category: 23004,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/f109eaee_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/f109eaee_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/f109eaee_z.jpg",
          },
        },
        caption: "욕실 샤워",
      },
      {
        hero_image: false,
        category: 72004,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/22f0c0e5_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/22f0c0e5_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/22f0c0e5_z.jpg",
          },
        },
        caption: "어린이 놀이 공간 - 야외",
      },
      {
        hero_image: false,
        category: 83007,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/67a56633_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/67a56633_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/67a56633_z.jpg",
          },
        },
        caption: "복도",
      },
      {
        hero_image: false,
        category: 91000,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0d065042_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0d065042_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0d065042_z.jpg",
          },
        },
        caption: "숙박 시설 구내",
      },
      {
        hero_image: false,
        category: 91003,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/889c9f4e_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/889c9f4e_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/889c9f4e_z.jpg",
          },
        },
        caption: "숙박 시설 정면 - 저녁/밤",
      },
      {
        hero_image: false,
        category: 91006,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/377a60d1_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/377a60d1_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/377a60d1_z.jpg",
          },
        },
        caption: "안뜰",
      },
      {
        hero_image: false,
        category: 91017,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9c37a881_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9c37a881_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9c37a881_z.jpg",
          },
        },
        caption: "호수",
      },
      {
        hero_image: false,
        category: 92002,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/ea4bc3df_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/ea4bc3df_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/ea4bc3df_z.jpg",
          },
        },
        caption: "숙박 시설의 전망",
      },
      {
        hero_image: false,
        category: 92002,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/01c63ea4_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/01c63ea4_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/01c63ea4_z.jpg",
          },
        },
        caption: "숙박 시설의 전망",
      },
      {
        hero_image: false,
        category: 92030,
        links: {
          "350px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/18723212_b.jpg",
          },
          "70px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/18723212_t.jpg",
          },
          "1000px": {
            method: "GET",
            href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/18723212_z.jpg",
          },
        },
        caption: "숙박 시설에서 보는 주변 전망",
      },
    ],
    onsite_payments: {
      currency: "KRW",
      types: {
        171: { id: "171", name: "American Express" },
        179: { id: "179", name: "Diners Club" },
        184: { id: "184", name: "Mastercard" },
        189: { id: "189", name: "Visa" },
        407: { id: "407", name: "현금" },
        1073742461: { id: "1073742461", name: "직불카드" },
        1073743307: { id: "1073743307", name: "UnionPay" },
      },
    },
    rooms: {
      202399176: {
        id: "202399176",
        name: "Yellow Marine (City View Double)",
        descriptions: {
          overview:
            "<p><strong>퀸사이즈침대 1개</strong></p><p>28.4 제곱미터 크기의 객실, 시내 전망</p><br/><p><b>인터넷</b> - 무료 WiFi 및 유선 인터넷</p><p><b>엔터테인먼트</b> - 디지털 채널 및 유료 영화 시청이 가능한 50인치 평면 TV</p><p><b>식음료</b> - 냉장고, 전기 주전자 및 무료 생수</p><p><b>편안한 잠자리</b> - 암막 커튼 </p><p><b>욕실</b> - 전용 욕실(샤워 욕조, 무료 세면용품 및 헤어드라이어 있음)</p><p><b>기타 편의 시설</b> - 컴퓨터 및 노트북 작업 공간</p><p><b>편의 서비스/시설</b> - 에어컨 및 하우스키핑(매일)</p><p>금연</p>",
        },
        amenities: {
          1: { id: "1", name: "에어컨", categories: ["air_conditioning"] },
          26: { id: "26", name: "TV" },
          130: { id: "130", name: "냉장고" },
          133: { id: "133", name: "매일 하우스키핑" },
          141: { id: "141", name: "전용 욕실" },
          143: { id: "143", name: "무료 세면용품" },
          144: { id: "144", name: "헤어드라이어" },
          2026: { id: "2026", name: "책상" },
          2039: { id: "2039", name: "유료 영화" },
          2057: { id: "2057", name: "무료 생수" },
          2150: { id: "2150", name: "암막 커튼" },
          2183: { id: "2183", name: "샤워기가 달린 욕조" },
          2397: { id: "2397", name: "디지털 TV 서비스" },
          2399: { id: "2399", name: "평면 TV" },
          2403: {
            id: "2403",
            name: "무료 WiFi",
            categories: ["free_wifi", "wifi"],
          },
          2405: { id: "2405", name: "무료 유선 인터넷" },
          2567: { id: "2567", name: "각각 다르게 가구가 비치된 객실" },
          2568: { id: "2568", name: "각각 다른 스타일의 객실" },
          2579: { id: "2579", name: "TV 크기: 50", value: "50" },
          2580: { id: "2580", name: "TV 크기 단위: 인치", value: "인치" },
          3856: { id: "3856", name: "객실 내 컴퓨터" },
          6176: { id: "6176", name: "금연" },
          1073743289: { id: "1073743289", name: "전기 주전자" },
          1073743370: { id: "1073743370", name: "타월 제공됨" },
          1073744237: { id: "1073744237", name: "노트북 작업 공간" },
        },
        images: [
          {
            hero_image: true,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a3390119_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a3390119_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a3390119_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a3390119_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9c38d1f2_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9c38d1f2_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9c38d1f2_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9c38d1f2_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/638f0184_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/638f0184_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/638f0184_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/638f0184_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0a1b802c_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0a1b802c_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0a1b802c_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0a1b802c_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a0fd4e19_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a0fd4e19_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a0fd4e19_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a0fd4e19_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/523dcea2_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/523dcea2_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/523dcea2_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/523dcea2_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 23000,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/98181875_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/98181875_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/98181875_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/98181875_z.jpg",
              },
            },
            caption: "욕실",
          },
          {
            hero_image: false,
            category: 23000,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/346fb0f3_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/346fb0f3_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/346fb0f3_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/346fb0f3_z.jpg",
              },
            },
            caption: "욕실",
          },
        ],
        bed_groups: {
          37310: {
            id: "37310",
            description: "퀸사이즈침대 1개",
            configuration: [{ quantity: 1, size: "Queen", type: "QueenBed" }],
          },
        },
        area: { square_meters: 28.4, square_feet: 306 },
        views: { 4134: { id: "4134", name: "시내 전망" } },
        occupancy: {
          max_allowed: { total: 2, children: 0, adults: 2 },
          age_categories: {
            ChildAgeA: { name: "ChildAgeA", minimum_age: 7 },
            Adult: { name: "Adult", minimum_age: 18 },
            Infant: { name: "Infant", minimum_age: 0 },
          },
        },
      },
      202399177: {
        id: "202399177",
        name: "Blue Marine (Full Ocean View)",
        descriptions: {
          overview:
            "<p><strong>퀸사이즈침대 1개 및 슈퍼싱글침대 1개</strong></p><p>32 제곱미터 크기의 객실, 오션 전망</p><br/><p><b>인터넷</b> - 무료 WiFi 및 유선 인터넷</p><p><b>엔터테인먼트</b> - 디지털 채널 및 유료 영화 시청이 가능한 50인치 평면 TV</p><p><b>식음료</b> - 냉장고, 전기 주전자 및 무료 생수</p><p><b>편안한 잠자리</b> - 암막 커튼 </p><p><b>욕실</b> - 전용 욕실(샤워 욕조, 무료 세면용품 및 헤어드라이어 있음)</p><p><b>기타 편의 시설</b> - 컴퓨터 및 노트북 작업 공간</p><p><b>편의 서비스/시설</b> - 에어컨 및 하우스키핑(매일)</p><p>금연</p>",
        },
        amenities: {
          1: { id: "1", name: "에어컨", categories: ["air_conditioning"] },
          26: { id: "26", name: "TV" },
          130: { id: "130", name: "냉장고" },
          133: { id: "133", name: "매일 하우스키핑" },
          141: { id: "141", name: "전용 욕실" },
          143: { id: "143", name: "무료 세면용품" },
          144: { id: "144", name: "헤어드라이어" },
          2026: { id: "2026", name: "책상" },
          2039: { id: "2039", name: "유료 영화" },
          2057: { id: "2057", name: "무료 생수" },
          2150: { id: "2150", name: "암막 커튼" },
          2183: { id: "2183", name: "샤워기가 달린 욕조" },
          2397: { id: "2397", name: "디지털 TV 서비스" },
          2399: { id: "2399", name: "평면 TV" },
          2403: {
            id: "2403",
            name: "무료 WiFi",
            categories: ["free_wifi", "wifi"],
          },
          2405: { id: "2405", name: "무료 유선 인터넷" },
          2567: { id: "2567", name: "각각 다르게 가구가 비치된 객실" },
          2568: { id: "2568", name: "각각 다른 스타일의 객실" },
          2579: { id: "2579", name: "TV 크기: 50", value: "50" },
          2580: { id: "2580", name: "TV 크기 단위: 인치", value: "인치" },
          3856: { id: "3856", name: "객실 내 컴퓨터" },
          6176: { id: "6176", name: "금연" },
          1073743289: { id: "1073743289", name: "전기 주전자" },
          1073743370: { id: "1073743370", name: "타월 제공됨" },
          1073744237: { id: "1073744237", name: "노트북 작업 공간" },
        },
        images: [
          {
            hero_image: true,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9ad372cd_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9ad372cd_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9ad372cd_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9ad372cd_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/78f39237_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/78f39237_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/78f39237_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/78f39237_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/51d18965_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/51d18965_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/51d18965_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/51d18965_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/bf780180_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/bf780180_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/bf780180_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/bf780180_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/b7a7b3ac_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/b7a7b3ac_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/b7a7b3ac_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/b7a7b3ac_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/14bb4658_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/14bb4658_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/14bb4658_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/14bb4658_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/75c553f9_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/75c553f9_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/75c553f9_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/75c553f9_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/2041b36c_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/2041b36c_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/2041b36c_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/2041b36c_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/e0d4c3ce_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/e0d4c3ce_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/e0d4c3ce_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/e0d4c3ce_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/07e6d430_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/07e6d430_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/07e6d430_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/07e6d430_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/af71fa29_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/af71fa29_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/af71fa29_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/af71fa29_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/75ff13ee_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/75ff13ee_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/75ff13ee_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/75ff13ee_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/31cd6431_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/31cd6431_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/31cd6431_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/31cd6431_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 23000,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/ca048b37_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/ca048b37_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/ca048b37_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/ca048b37_z.jpg",
              },
            },
            caption: "욕실",
          },
          {
            hero_image: false,
            category: 23000,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/8aaf80bf_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/8aaf80bf_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/8aaf80bf_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/8aaf80bf_z.jpg",
              },
            },
            caption: "욕실",
          },
          {
            hero_image: false,
            category: 23000,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/beb3ddcc_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/beb3ddcc_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/beb3ddcc_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/beb3ddcc_z.jpg",
              },
            },
            caption: "욕실",
          },
          {
            hero_image: false,
            category: 23003,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/bc85dde8_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/bc85dde8_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/bc85dde8_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/bc85dde8_z.jpg",
              },
            },
            caption: "욕실 세면대",
          },
          {
            hero_image: false,
            category: 23003,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/301e0bf9_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/301e0bf9_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/301e0bf9_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/301e0bf9_z.jpg",
              },
            },
            caption: "욕실 세면대",
          },
        ],
        bed_groups: {
          38209: {
            id: "38209",
            description: "퀸사이즈침대 1개 및 슈퍼싱글침대 1개",
            configuration: [
              { quantity: 1, size: "Queen", type: "QueenBed" },
              { quantity: 1, size: "TwinXL", type: "TwinXLBed" },
            ],
          },
        },
        area: { square_meters: 32, square_feet: 344 },
        views: { 4101: { id: "4101", name: "바다 전망" } },
        occupancy: {
          max_allowed: { total: 2, children: 0, adults: 2 },
          age_categories: {
            ChildAgeA: { name: "ChildAgeA", minimum_age: 7 },
            Adult: { name: "Adult", minimum_age: 18 },
            Infant: { name: "Infant", minimum_age: 0 },
          },
        },
      },
      202399180: {
        id: "202399180",
        name: "Blue Marine (Half Ocean View)",
        descriptions: {
          overview:
            "<p><strong>퀸사이즈침대 1개 및 슈퍼싱글침대 1개</strong></p><p>32 제곱미터 크기의 객실, 오션 전망</p><br/><p><b>인터넷</b> - 무료 WiFi 및 유선 인터넷</p><p><b>엔터테인먼트</b> - 디지털 채널 및 유료 영화 시청이 가능한 50인치 평면 TV</p><p><b>식음료</b> - 냉장고, 전기 주전자 및 무료 생수</p><p><b>편안한 잠자리</b> - 암막 커튼 </p><p><b>욕실</b> - 전용 욕실(샤워 욕조, 무료 세면용품 및 헤어드라이어 있음)</p><p><b>기타 편의 시설</b> - 컴퓨터 및 노트북 작업 공간</p><p><b>편의 서비스/시설</b> - 에어컨 및 하우스키핑(매일)</p><p>금연</p>",
        },
        amenities: {
          1: { id: "1", name: "에어컨", categories: ["air_conditioning"] },
          26: { id: "26", name: "TV" },
          130: { id: "130", name: "냉장고" },
          133: { id: "133", name: "매일 하우스키핑" },
          141: { id: "141", name: "전용 욕실" },
          143: { id: "143", name: "무료 세면용품" },
          144: { id: "144", name: "헤어드라이어" },
          2026: { id: "2026", name: "책상" },
          2039: { id: "2039", name: "유료 영화" },
          2057: { id: "2057", name: "무료 생수" },
          2150: { id: "2150", name: "암막 커튼" },
          2183: { id: "2183", name: "샤워기가 달린 욕조" },
          2397: { id: "2397", name: "디지털 TV 서비스" },
          2399: { id: "2399", name: "평면 TV" },
          2403: {
            id: "2403",
            name: "무료 WiFi",
            categories: ["free_wifi", "wifi"],
          },
          2405: { id: "2405", name: "무료 유선 인터넷" },
          2567: { id: "2567", name: "각각 다르게 가구가 비치된 객실" },
          2568: { id: "2568", name: "각각 다른 스타일의 객실" },
          2579: { id: "2579", name: "TV 크기: 50", value: "50" },
          2580: { id: "2580", name: "TV 크기 단위: 인치", value: "인치" },
          3856: { id: "3856", name: "객실 내 컴퓨터" },
          6176: { id: "6176", name: "금연" },
          1073743289: { id: "1073743289", name: "전기 주전자" },
          1073743370: { id: "1073743370", name: "타월 제공됨" },
          1073744237: { id: "1073744237", name: "노트북 작업 공간" },
        },
        images: [
          {
            hero_image: true,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/01974042_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/01974042_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/01974042_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/01974042_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9a524ad3_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9a524ad3_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9a524ad3_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9a524ad3_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/cb414c5a_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/cb414c5a_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/cb414c5a_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/cb414c5a_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9ea8be51_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9ea8be51_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9ea8be51_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/9ea8be51_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/8b64bafa_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/8b64bafa_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/8b64bafa_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/8b64bafa_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/eee2c74b_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/eee2c74b_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/eee2c74b_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/eee2c74b_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a09d42cf_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a09d42cf_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a09d42cf_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/a09d42cf_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 23000,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0e5e28db_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0e5e28db_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0e5e28db_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0e5e28db_z.jpg",
              },
            },
            caption: "욕실",
          },
          {
            hero_image: false,
            category: 23003,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/6fe8955c_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/6fe8955c_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/6fe8955c_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/6fe8955c_z.jpg",
              },
            },
            caption: "욕실 세면대",
          },
          {
            hero_image: false,
            category: 23004,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/934548fe_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/934548fe_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/934548fe_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/934548fe_z.jpg",
              },
            },
            caption: "욕실 샤워",
          },
          {
            hero_image: false,
            category: 23004,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/f109eaee_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/f109eaee_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/f109eaee_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/f109eaee_z.jpg",
              },
            },
            caption: "욕실 샤워",
          },
        ],
        bed_groups: {
          38209: {
            id: "38209",
            description: "퀸사이즈침대 1개 및 슈퍼싱글침대 1개",
            configuration: [
              { quantity: 1, size: "Queen", type: "QueenBed" },
              { quantity: 1, size: "TwinXL", type: "TwinXLBed" },
            ],
          },
        },
        area: { square_meters: 32, square_feet: 344 },
        views: { 4101: { id: "4101", name: "바다 전망" } },
        occupancy: {
          max_allowed: { total: 2, children: 0, adults: 2 },
          age_categories: {
            ChildAgeA: { name: "ChildAgeA", minimum_age: 7 },
            Adult: { name: "Adult", minimum_age: 18 },
            Infant: { name: "Infant", minimum_age: 0 },
          },
        },
      },
      221743797: {
        id: "221743797",
        name: "Yellow Marine (City View)",
        descriptions: {
          overview:
            "<p><strong>퀸사이즈침대 1개 및 슈퍼싱글침대 1개</strong></p><p>32 제곱미터 크기의 각각 다르게 꾸며진 객실, 시내 및 바다 일부 전망</p><br/><p><b>인터넷</b> - 무료 WiFi 및 유선 인터넷</p><p><b>엔터테인먼트</b> - 디지털 채널 및 유료 영화 시청이 가능한 50인치 평면 TV</p><p><b>식음료</b> - 냉장고, 전기 주전자 및 무료 생수</p><p><b>편안한 잠자리</b> - 암막 커튼 </p><p><b>욕실</b> - 전용 욕실(샤워 욕조, 무료 세면용품 및 헤어드라이어 있음)</p><p><b>기타 편의 시설</b> - 컴퓨터 및 노트북 작업 공간</p><p><b>편의 서비스/시설</b> - 에어컨 및 하우스키핑(매일)</p><p>금연</p>",
        },
        amenities: {
          1: { id: "1", name: "에어컨", categories: ["air_conditioning"] },
          26: { id: "26", name: "TV" },
          130: { id: "130", name: "냉장고" },
          133: { id: "133", name: "매일 하우스키핑" },
          141: { id: "141", name: "전용 욕실" },
          143: { id: "143", name: "무료 세면용품" },
          144: { id: "144", name: "헤어드라이어" },
          2026: { id: "2026", name: "책상" },
          2039: { id: "2039", name: "유료 영화" },
          2057: { id: "2057", name: "무료 생수" },
          2150: { id: "2150", name: "암막 커튼" },
          2183: { id: "2183", name: "샤워기가 달린 욕조" },
          2397: { id: "2397", name: "디지털 TV 서비스" },
          2399: { id: "2399", name: "평면 TV" },
          2403: {
            id: "2403",
            name: "무료 WiFi",
            categories: ["free_wifi", "wifi"],
          },
          2405: { id: "2405", name: "무료 유선 인터넷" },
          2567: { id: "2567", name: "각각 다르게 가구가 비치된 객실" },
          2568: { id: "2568", name: "각각 다른 스타일의 객실" },
          2579: { id: "2579", name: "TV 크기: 50", value: "50" },
          2580: { id: "2580", name: "TV 크기 단위: 인치", value: "인치" },
          3856: { id: "3856", name: "객실 내 컴퓨터" },
          6176: { id: "6176", name: "금연" },
          1073743289: { id: "1073743289", name: "전기 주전자" },
          1073743370: { id: "1073743370", name: "타월 제공됨" },
          1073744237: { id: "1073744237", name: "노트북 작업 공간" },
        },
        images: [
          {
            hero_image: true,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/5bea3c4a_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/5bea3c4a_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/5bea3c4a_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/5bea3c4a_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0c9639db_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0c9639db_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0c9639db_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/0c9639db_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/7dd22221_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/7dd22221_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/7dd22221_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/7dd22221_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/e5096a9d_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/e5096a9d_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/e5096a9d_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/e5096a9d_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 21001,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/cac3067a_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/cac3067a_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/cac3067a_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/cac3067a_z.jpg",
              },
            },
            caption: "객실",
          },
          {
            hero_image: false,
            category: 23000,
            links: {
              "350px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/de4ea7b6_b.jpg",
              },
              "70px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/de4ea7b6_t.jpg",
              },
              "200px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/de4ea7b6_s.jpg",
              },
              "1000px": {
                method: "GET",
                href: "https://i.travelapi.com/lodging/22000000/21900000/21899700/21899602/de4ea7b6_z.jpg",
              },
            },
            caption: "욕실",
          },
        ],
        bed_groups: {
          38209: {
            id: "38209",
            description: "퀸사이즈침대 1개 및 슈퍼싱글침대 1개",
            configuration: [
              { quantity: 1, size: "Queen", type: "QueenBed" },
              { quantity: 1, size: "TwinXL", type: "TwinXLBed" },
            ],
          },
        },
        area: { square_meters: 32, square_feet: 344 },
        views: {
          4134: { id: "4134", name: "시내 전망" },
          1073742613: { id: "1073742613", name: "부분 바다 전망" },
        },
        occupancy: {
          max_allowed: { total: 2, children: 0, adults: 2 },
          age_categories: {
            ChildAgeA: { name: "ChildAgeA", minimum_age: 7 },
            Adult: { name: "Adult", minimum_age: 18 },
            Infant: { name: "Infant", minimum_age: 0 },
          },
        },
      },
    },
    rates: {
      212223885: {
        id: "212223885",
        amenities: {
          2109: { id: "2109", name: "무료 셀프 주차", categories: ["parking"] },
          2192: { id: "2192", name: "무료 WiFi", categories: ["free_wifi"] },
        },
      },
      298219860: {
        id: "298219860",
        amenities: {
          2109: { id: "2109", name: "무료 셀프 주차", categories: ["parking"] },
          2192: { id: "2192", name: "무료 WiFi", categories: ["free_wifi"] },
        },
      },
      298219861: {
        id: "298219861",
        amenities: {
          2109: { id: "2109", name: "무료 셀프 주차", categories: ["parking"] },
          2192: { id: "2192", name: "무료 WiFi", categories: ["free_wifi"] },
        },
      },
      298221124: {
        id: "298221124",
        amenities: {
          2109: { id: "2109", name: "무료 셀프 주차", categories: ["parking"] },
          2192: { id: "2192", name: "무료 WiFi", categories: ["free_wifi"] },
        },
      },
    },
    dates: {
      added: "2017-12-28T19:03:53.790Z",
      updated: "2023-10-21T15:43:42.440Z",
    },
    descriptions: {
      amenities:
        "무료 무선 인터넷 및 콘시어지 서비스 등의 편의 시설/서비스를 이용하실 수 있습니다.",
      national_ratings:
        "고객 편의를 위해 저희 등급 시스템을 기준으로 해당 정보를 제공했습니다.",
      business_amenities:
        "대표적인 편의 시설과 서비스로는 무료 유선 인터넷, 24시간 운영되는 프런트 데스크, 짐 보관 등이 있습니다. 시설 내에서 무료 셀프 주차 이용이 가능합니다.",
      rooms:
        "81개의 각각 다른 스타일의 인테리어에는 냉장고 및 평면 TV 등이 갖추어져 있어 편하게 머무실 수 있습니다. 컴퓨터에서는 무료 유선 및 무선 인터넷이 지원되며 디지털 프로그램도 시청하실 수 있습니다. 샤워기가 달린 욕조 시설을 갖춘 전용 욕실에는 무료 세면용품 및 헤어드라이어도 마련되어 있습니다. 편의 시설/서비스로는 책상 및 전기 주전자 등이 있으며 객실 정돈 서비스는 매일 제공됩니다.",
      attractions:
        "거리는 0.1km 단위로 최대한 가깝게 표시됩니다. <br /><p>월미도 부두 - 0.1km <br />월미테마파크 - 0.5km <br />월미전통공원 - 0.5km <br />인천항 - 1km <br />Incheon Chinatown - 2.2km <br />인천아트플랫폼 - 2.5km <br />송월동 동화마을 - 2.7km <br />자유공원 - 2.7km <br />영종 부두 - 3km <br />신포 국제시장 - 3.3km <br />펀 과학박물관 - 3.5km <br />화도진공원 - 3.9km <br />Nuri Bowling Alley - 4.2km <br />수도국산 달동네 박물관 - 4.3km <br />인하대학교병원 - 5.7km <br /></p><p>가장 가까운 공항:<br />인천국제공항 (ICN) - 36.3km<br />김포국제공항 (GMP) - 29.6km<br /></p><p></p>",
      location:
        "인천(중구)에 위치한 인천 호텔 블루마린의 경우 월미도 부두에서 아주 가까우며 걸어서 6분 거리에는 월미테마파크 등이 있습니다.  이 해변 호텔에서 인천항까지는 1km 떨어져 있으며, 5.7km 거리에는 인하대학교병원도 있습니다.",
      headline: "인천(중구)에 위치",
    },
    statistics: { 52: { id: "52", name: "총 객실 수 - 81", value: "81" } },
    themes: { 2325: { id: "2325", name: "해변 숙박 시설" } },
    chain: { id: "0", name: "Independent" },
    brand: { id: "0", name: "Independent" },
    multi_unit: true,
    payment_registration_recommended: false,
    vacation_rental_details: {
      private_host: false,
      house_rules: ["아동 동반 가능", "반려동물 동반 불가", "금연 건물"],
    },
    supply_source: "expedia",
    spoken_languages: {
      ko: { id: "ko", name: "한국어" },
      en: { id: "en", name: "영어" },
    },
  },
];

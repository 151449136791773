import { Route, Routes } from "react-router-dom";
import Search from "./pages/Search";
import Filter from "./components/Filter";
import Hotel from "./pages/Hotel";
import NavBar from "./components/NavBar";
import Booking from "./pages/Booking";
import Home from "./pages/Home";
import ReservationList from "./pages/ReservationList";
import ReservationDetail from "./pages/ReservationDetail";
import Success from "./pages/Success";
import Fail from "./pages/Fail";
import Footer from "./components/Footer";
import AirtelList from "./pages/AirtelList";
import AirtelDays from "./pages/AirtelDays";
import AirtelDetail from "./pages/AirtelDetail";
import PackageRequest from "./pages/PackageRequest";
import PackageSuccess from "./pages/PackageSuccess";
import Login from "./pages/Login";
import Password from "./pages/Password";
import AccountSuccess from "./pages/AccountSuccess";
import Account from "./pages/Account";
import Policy from "./pages/Policy";
import EventList from "./pages/EventList";
import HotDealList from "./pages/HotDealList";
import EventDetail from "./pages/EventDetail";
import EventReservation from "./pages/EventReservation";
import EventPayment from "./pages/EventPayment";
import HotdealPayment from "./pages/HotdealPayment";
import HotdealDetails from "./pages/HotdealDetails";
import HotdealReservation from "./pages/HotdealReservation";
import TestPage from "./pages/TestPage";
import VideoTest from "./pages/VideoTest";
import Temp from "./pages/temp/Temp";
import TempBooking from "./pages/temp/TempBooking";
import TempSuccess from "./pages/temp/TempSuccess";

function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/test" element={<TestPage />} />
        <Route path="/videotest" element={<VideoTest />} />
        <Route path="/temp" element={<Temp />} />
        <Route path="/temp/book" element={<TempBooking />} />
        <Route path="/temp/success" element={<TempSuccess />} />
        <Route path="/temp/fail" element={<Fail />} />
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Login />} />
        <Route path="/signup/success" element={<AccountSuccess />} />
        <Route path="/find/password" element={<Password />} />
        <Route path="/mypage/account" element={<Account />} />
        <Route path="/mypage/delete/account" element={<Account />} />
        <Route path="/mypage/change/password" element={<Password />} />
        <Route path="/mypage/reservationlist" element={<ReservationList />} />
        <Route path="/mypage/policy/:id" element={<Policy />} />
        <Route path="/password/success" element={<AccountSuccess />} />
        <Route path="/search" element={<Search />} />
        <Route path="/search/filter" element={<Filter />} />
        <Route path="/hotels/*" element={<Hotel />} />
        <Route path="/hotel/detail/:id" element={<Hotel />} />
        <Route path="/booking/*" element={<Booking />} />
        <Route path="/success" element={<Success />} />
        <Route path="/fail" element={<Fail />} />
        <Route path="/reservation/detail/:id" element={<ReservationDetail />} />
        <Route path="/cancel" element={<Success />} />
        <Route path="/airtellist" element={<AirtelList />} />
        <Route path="/airteldays/:id" element={<AirtelDays />} />
        <Route path="/airtel/:id" element={<AirtelDetail />} />
        <Route path="/eventlist" element={<EventList />} />
        <Route path="/eventitem/:id" element={<EventDetail />} />
        <Route path="/eventreservation" element={<EventReservation />} />
        <Route path="/eventpayment/:id" element={<EventPayment />} />
        <Route path="/hotdeallist" element={<HotDealList />} />
        <Route path="/hotdeal/:id" element={<HotdealDetails />} />
        <Route path="/hotdealpayment/:id" element={<HotdealPayment />} />
        <Route path="/hotdealreservation/*" element={<HotdealReservation />} />
        <Route path="/package" element={<PackageRequest />} />
        <Route path="/package/success/:id" element={<PackageSuccess />} />
        <Route path="/packagepayment/success" element={<PackageSuccess />} />
        <Route path="/packagepayment/fail" element={<Fail />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

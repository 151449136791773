import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { styled } from "styled-components";
import { Contents } from "./PackageSuccess";
import { useNavigate, useSearchParams } from "react-router-dom";
import { policy_list } from "./PackageRequest";
import { CheckBox, DocumentBox, ModalData, Policy } from "./PackageRequest";
import Modal from "../components/Modal";

const Container = styled.div`
  width: 100%;
  max-width: 500px;
  margin: auto;
  margin-bottom: 480px;

  .title {
    margin: 75px 0 40px;
    color: #161a3f;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -1.2px;
  }

  input {
    padding: 12px 20px;
    font-size: 16px;
    border: 1px solid #e7e7ee;
    border-radius: 10px;
  }

  .search_wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 40px 0 50px;
  }

  .search {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border: 1px solid #161a3f;
    border-radius: 100px;

    input {
      width: 100%;
      border: none;
      outline: none;
    }

    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .img_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 0;

    img {
      width: 180px;
    }
    p {
      margin: 20px 0;
      color: #a3a5b8;
      font-size: 14px;
      letter-spacing: -0.42px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 16px;

    h3 {
      margin: 20px 0 30px;
    }
  }
`;

const Button = styled.button<{ $mt?: string }>`
  width: 100%;
  padding: 10px 0;

  margin: auto;
  margin-top: ${(props) => (props.$mt ? props.$mt : "30px")};
  width: 100%;
  padding: 12px 0;
  border-radius: 5px;
  background: #2d40ff;
  color: #fff;
  font-size: 14px;
  letter-spacing: -0.42px;
  border: none;
  cursor: pointer;
`;

const Content = styled.div<{ $dl: boolean }>`
  padding: 32px 30px;
  border-radius: 10px;
  background: rgba(247, 247, 252, 0.4);

  div * {
    color: ${(props) => props.$dl && "#a3a5b8 !important"};
  }

  .complete {
    margin: 0;
    margin-bottom: 50px;
    text-align: center;
    color: #161a3f;
    font-weight: 700;
    font-size: 24px;
  }

  .product_name {
    margin: 0;
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 22px;
    color: ${(props) => props.$dl && "#a3a5b8"};
  }
  .deadline {
    margin: 0 0 30px;
    text-align: center;
    color: #06f;
    font-weight: 500;
    font-size: 24px;
  }
  .first {
    border-bottom: 1px solid #f6f6f9;
  }
  @media screen and (max-width: 768px) {
    h3 {
      font-size: 20px;
    }
  }
`;

const ContentCard = styled.div`
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 0;
  }

  span {
    color: #161a3f;
    font-size: 14px;
    letter-spacing: -0.56px;
  }
  .price {
    color: #161a3f;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.8px;
  }
  .warning {
    span {
      font-weight: 500;
      color: #ff0000;
    }
  }
`;

const Contact = styled.div`
  margin-bottom: 40px;
  padding: 16px 0 20px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  div * {
    color: #161a3f !important;
  }

  h3 {
    margin: 0;
    padding-bottom: 12px;
    text-align: center;
    color: #161a3f !important;
    font-size: 18px;
    font-weight: 500;
  }
  .call {
    font-size: 28px;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 24px;
      margin-right: 24px;
    }
    .operation {
      text-align: center;
    }
    p {
      margin: 0;
    }
    span {
      font-size: 14px;
    }
  }
`;

const Documents = styled(DocumentBox)`
  img {
    width: 14px;
  }
`;

const PolicyCard = styled(Policy)`
  font-size: 14px;
`;

export const Check = styled.div`
  margin-top: 26px;
  display: flex;
  align-items: center;

  input {
    width: 16px;
  }
  label {
    font-size: 14px;
  }
`;

const HistoryBtn = styled.button`
  display: block;
  margin: auto;
  margin-top: 20px;
  background: inherit;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: #06f;
  text-decoration: underline;
`;

const History = styled.div`
  padding: 18px 12px 0;

  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 0;

    color: #161a3f;
    font-size: 13px;
  }
`;

const ButtonEmpty = styled.button<{ $mt?: string }>`
  width: 100%;
  padding: 10px 0;

  margin: auto;
  margin-top: ${(props) => (props.$mt ? props.$mt : "40px")};
  width: 100%;
  padding: 12px 0;
  border: 1px solid #2d40ff;
  border-radius: 5px;
  background: #fff;
  color: #2d40ff;
  font-size: 14px;
  letter-spacing: -0.42px;
  cursor: pointer;
`;

export default function EventReservation() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const rn = searchParams.get("rn");
  const [data, setData] = useState({
    rid: "",
    phone: "",
  });
  const [reserveData, setReserveData] = useState<any>();
  const [deadline, setDeadline] = useState(false);
  const [history, setHistory] = useState(false);
  const [check, setCheck] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState<ModalData>({
    txt: "",
    link: "",
  });

  const getSearch = async () => {
    try {
      const res = await axios.get(
        `/product/reservation?reservation_id=${data.rid}&phone=${data.phone}`
      );
      setReserveData(res.data.data);

      if (new Date(res.data.data.last_payment_day) < new Date())
        setDeadline(true);
      if (res.data.data.cumulative_amount === res.data.data.product_price)
        setDeadline(false);
    } catch (e: any) {
      // console.log(e.response.data.message);
      alert("입력하신 정보를 확인해 주세요.");
      navigate(`/eventreservation`);
    }
  };

  const handleSearch = () => {
    if (!data.phone) return alert("휴대폰 번호를 입력해 주세요.");
    if (!data.rid) return alert("구매 번호를 입력해 주세요.");
    navigate(`/eventreservation?rn=${data.rid}`);
  };

  useEffect(() => {
    if (rn) getSearch();
  }, [rn]);

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleReset = () => {
    navigate(`/eventreservation`);
    setReserveData(null);
    setDeadline(false);
    setHistory(false);
  };

  const handleModal = (idx: number) => {
    setModal(true);
    setModalData(policy_list[idx]);
  };

  const handlePay = () => {
    if (!check) return alert("이용약관 및 정책에 동의해 주세요.");
    sessionStorage.setItem("pd_pm", data.phone);
    navigate(`/eventpayment/${data.rid}`);
  };

  return (
    <Container>
      {modal && <Modal setModal={setModal} modalData={modalData} />}
      <h3 className="title">기획상품 예약조회</h3>

      {rn && reserveData ? (
        reserveData.last_payment_day ? (
          <div>
            <Content $dl={deadline}>
              {deadline && (
                <>
                  <h3 className="deadline">결제시한이 지난 예약입니다</h3>
                  <Contact>
                    <h3>상담 문의</h3>
                    <h3 className="call">1533-7901</h3>

                    <div className="info">
                      <div>
                        <img src="/img/phone.svg" alt="call" />
                      </div>
                      <div className="operation">
                        <p>평일 오전 10시 ~ 오후 5시</p>
                        <span>(점심시간 11:30 ~ 13:00)</span>
                      </div>
                    </div>
                  </Contact>
                </>
              )}

              {reserveData &&
                reserveData.cumulative_amount === reserveData.product_price && (
                  <h3 className="complete">결제가 완료되었습니다</h3>
                )}
              <h3 className="product_name">{reserveData.product_name}</h3>

              <ContentCard>
                <div>
                  <span>예약자 성함</span>
                  <span>{reserveData.consumer_name}</span>
                </div>
                <div>
                  <span>이메일</span>
                  <span>{reserveData.consumer_email}</span>
                </div>

                {reserveData.cumulative_amount === reserveData.product_price ? (
                  <div>
                    <span>총 결제금액</span>
                    <span>
                      {reserveData.cumulative_amount.toLocaleString()}원
                    </span>
                  </div>
                ) : (
                  <>
                    <div>
                      <span>상품가격</span>
                      <span>
                        {reserveData.product_price.toLocaleString()}원
                      </span>
                    </div>
                    <div>
                      <span>결제된 금액</span>
                      <span>
                        {reserveData.cumulative_amount.toLocaleString()}원
                      </span>
                    </div>

                    <div>
                      <span>잔여 결제 금액</span>
                      <span>
                        {reserveData.balance_amount.toLocaleString()}원
                      </span>
                    </div>
                  </>
                )}
                {reserveData?.cumulative_amount !==
                  reserveData?.product_price && (
                  <div className={deadline ? "" : "warning"}>
                    <span>결제 마감일</span>
                    <span>{reserveData.last_payment_day}</span>
                  </div>
                )}
              </ContentCard>

              {reserveData.payment_history.length > 0 && (
                <HistoryBtn
                  onClick={() => {
                    setHistory(!history);
                  }}
                >
                  결제내역 상세보기
                </HistoryBtn>
              )}

              {history && (
                <History>
                  {reserveData.payment_history.map((item: any, i: number) => (
                    <div className="wrap" key={i}>
                      <span>{item.payment_date}</span>
                      <span>{item.payment_amount.toLocaleString()}원</span>
                    </div>
                  ))}
                </History>
              )}

              {!deadline &&
                reserveData?.cumulative_amount !==
                  reserveData?.product_price && (
                  <>
                    <div style={{ marginTop: "30px" }}>
                      {policy_list &&
                        policy_list.map(
                          (policy: { txt: string }, i: number) => (
                            <Documents key={policy.txt} color="#0074FF">
                              <img src="/img/doc_blue.svg" alt="document" />
                              <PolicyCard
                                onClick={() => {
                                  handleModal(i);
                                }}
                              >
                                {policy.txt}
                              </PolicyCard>
                            </Documents>
                          )
                        )}
                    </div>

                    <Check>
                      <CheckBox
                        type="checkbox"
                        name="agree"
                        onChange={() => {
                          setCheck(!check);
                        }}
                      />
                      <label>상기 내용에 모두 동의합니다.</label>
                    </Check>
                  </>
                )}
            </Content>

            {!deadline &&
              reserveData?.cumulative_amount !== reserveData?.product_price && (
                <Button onClick={handlePay}>결제하기</Button>
              )}

            <ButtonEmpty
              $mt={
                deadline ||
                reserveData?.cumulative_amount === reserveData?.product_price
                  ? ""
                  : "12px"
              }
              onClick={handleReset}
            >
              다시 조회하기
            </ButtonEmpty>
          </div>
        ) : (
          <>
            <Contents>
              <div className="em ct">
                <p>전화상담 후 결제진행이 가능합니다.</p>
                <p>상담번호 1533-7901</p>
              </div>
              <div className="ct">
                상담문의가 없으실 경우, 담당자가 확인 후 연락드립니다.
              </div>

              <div className="info_container">
                <h3>상담 가능 시간</h3>
                <div className="info">
                  <div>
                    <img src="/img/phone.svg" alt="call" />
                  </div>
                  <div className="operation">
                    <p>평일 오전 10시 ~ 오후 5시</p>
                    <span>(점심시간 11:30 ~ 13:00)</span>
                  </div>
                </div>
              </div>
            </Contents>
            <ButtonEmpty onClick={handleReset}>다시 조회하기</ButtonEmpty>
          </>
        )
      ) : (
        <>
          <div className="search_wrap">
            <label>휴대폰 번호</label>
            <input
              type="text"
              name="phone"
              inputMode="numeric"
              placeholder="휴대폰 번호를 입력하세요"
              value={data.phone}
              onChange={handleInput}
            />
            <label>구매 번호</label>
            <input
              type="text"
              name="rid"
              inputMode="numeric"
              placeholder="구매 번호를 입력하세요"
              value={data.rid}
              onChange={handleInput}
            />
          </div>

          <Button onClick={handleSearch}>예약 조회</Button>
        </>
      )}
    </Container>
  );
}

import { useAtomValue, useSetAtom } from "jotai";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { RateDetailAtom, RateModalAtom } from "./RoomCard";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 101;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.4);
`;

const Modal = styled.div`
  position: relative;
  width: 100%;
  max-width: 640px;
  background: #fff;
  border-radius: 12px;
  padding: 32px;

  h3 {
    margin: 0;
    font-weight: 500;
  }
  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    background: transparent url("/img/close.svg") no-repeat center;
    border: none;
    cursor: pointer;
  }
  .content_wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 32px 0;
    font-size: 14px;
    color: #191e3b;
  }
  .content {
    display: flex;
    justify-content: space-between;
  }
  .night {
    p {
      margin: 0;
    }
    .per_night {
      font-size: 12px;
      margin-left: 8px;
    }
  }
`;

const TotalRate = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  border-top: 1px solid #dfe0e4;
`;

export default function RateDetail() {
  const [searchParams, setSearchParams] = useSearchParams();
  const check_in = searchParams.get("check_in");
  const check_out = searchParams.get("check_out");
  const occupancy = searchParams.getAll("occupancy");
  const rateDetail = useAtomValue(RateDetailAtom);
  const setRateModal = useSetAtom(RateModalAtom);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const [night, setNight] = useState(0);
  const exclusive = occupancy.length * rateDetail[0];
  const total = occupancy.length * rateDetail[1];

  const clickOutside = (e: any) => {
    backgroundRef.current === e.target && setRateModal(false);
  };

  useEffect(() => {
    const nights = Math.ceil(
      Math.abs(
        new Date(check_in ? check_in : "").getTime() -
          new Date(check_out ? check_out : "").getTime()
      ) /
        (1000 * 60 * 60 * 24)
    );
    setNight(nights);

    document.addEventListener("click", clickOutside);
    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, []);

  // console.log(RateDetail);

  return (
    <Container ref={backgroundRef}>
      <Modal>
        <button
          className="close"
          onClick={() => {
            setRateModal(false);
          }}
        />
        <h3>요금 세부 정보</h3>

        <div className="content_wrapper">
          <div className="content">
            <div className="night">
              <p>
                {occupancy.length > 1 && `객실 ${occupancy.length}개 x `}
                {night}박
              </p>
              {night > 1 && (
                <p className="per_night">
                  ₩
                  {Math.round(
                    exclusive / night / occupancy.length
                  ).toLocaleString()}
                  /{occupancy.length > 1 && "객실 1개, "}
                  1박
                </p>
              )}
            </div>
            <span>₩ {exclusive.toLocaleString()}</span>
          </div>
          <div className="content">
            <span>세금 및 수수료</span>
            <span>₩ {(total - exclusive).toLocaleString()}</span>
          </div>
        </div>
        <TotalRate>
          <span>총 금액</span>
          <span>₩ {total.toLocaleString()}</span>
        </TotalRate>
      </Modal>
    </Container>
  );
}

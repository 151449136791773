import { atom, useAtom, useSetAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";

const Container = styled.div<{ $fixed: boolean }>`
    position: ${(props) => props.$fixed && "fixed"};
    top: ${(props) => props.$fixed && "0"};
    z-index: ${(props) => props.$fixed && "100"};
    overflow: hidden;
    border-bottom-left-radius: ${(props) => (props.$fixed ? "10px" : "")};
    border-bottom-right-radius: ${(props) => (props.$fixed ? "10px" : "")};
    border: ${(props) => props.$fixed && "0px solid #DFE0E4"};
    box-shadow: ${(props) => props.$fixed && "rgba(0, 0, 0, 0.07) 0px 2px 2px 1px"};
    border-top: 0;
    width: 100%;
    max-width: 1200px;
    padding: 0;
    margin: auto;
    margin-bottom: 50px;
    background: #fff;

    ul {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .underline {
        color: #2d40ff;
        font-weight: 500;
        border-bottom: 3px solid #2d40ff;
    }

    li {
        padding: ${(props) => (props.$fixed ? " 20px 30px " : "8px 16px")};
        color: #54555e;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        letter-spacing: -0.64px;
    }

    li:first-of-type {
        padding-left: ${(props) => !props.$fixed && "0"};
    }

    @media screen and (max-width: 680px) {
        padding: ${(props) => (props.$fixed ? "" : "0 16px")};
        margin-bottom: 30px;

        li {
            font-size: 15px;
            padding: ${(props) => (props.$fixed ? " 10px 20px " : "8px 16px")};
        }
    }
`;

export const tabAtom = atom("");
export const tabMenuAtom = atom("");
export default function TabMenu() {
    const tabRef = useRef<HTMLDivElement>(null);
    const [fixed, setFixed] = useState(false);
    const [tab, setTab] = useAtom(tabAtom);
    const [defaultTop, setDefaultTop] = useState(0);
    const setTabMenu = useSetAtom(tabMenuAtom);

    const handleScroll = () => {
        if (tabRef.current) {
            tabRef.current?.offsetTop > 0 && setDefaultTop(tabRef.current?.offsetTop);
            window.scrollY > tabRef.current?.offsetTop && setFixed(true);
        }
        if (window.scrollY <= defaultTop) setFixed(false);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [defaultTop]);

    return (
        <Container
            $fixed={fixed}
            ref={tabRef}
        >
            <ul>
                <li
                    className={tab === "room" ? "underline" : ""}
                    onClick={() => {
                        setTabMenu("room");
                    }}
                >
                    객실
                </li>
                <li
                    className={tab === "location" ? "underline" : ""}
                    onClick={() => {
                        setTabMenu("location");
                    }}
                >
                    위치
                </li>
                <li
                    className={tab === "amenities" ? "underline" : ""}
                    onClick={() => {
                        setTabMenu("amenities");
                    }}
                >
                    편의 시설/서비스
                </li>
                <li
                    className={tab === "policies" ? "underline" : ""}
                    onClick={() => {
                        setTabMenu("policies");
                    }}
                >
                    정책
                </li>
            </ul>
        </Container>
    );
}

import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import { policy_list } from "./PackageRequest";

const Container = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1200px;
  margin-top: 85px;

  @media screen and (max-width: 768px) {
    margin-top: 45px;
    padding: 0 16px;
  }
`;

const Contents = styled.div`
  width: 100%;
  max-width: 480px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 60px;
  padding: 20px 12px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(105, 88, 142, 0.1);
  border-radius: 10px;

  h3 {
    margin: 0;
    margin-top: 45px;
    margin-bottom: 60px;
    text-align: center;
    color: #122437;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: -0.84px;
  }

  .content {
    margin-bottom: 60px;
  }
`;

const Iframe = styled.iframe`
  border: none;
  width: 100%;
  height: 720px;
`;

export default function Policy() {
  const privacy = useLocation().pathname.includes("privacy");
  const location = useLocation().pathname.includes("location");

  return (
    <Container>
      <Contents>
        <h3>
          {privacy
            ? "개인정보 보호정책"
            : location
            ? "위치정보 이용약관"
            : "이용약관"}
        </h3>
        <div className="content">
          <Iframe
            title={
              privacy
                ? policy_list[2].txt
                : location
                ? policy_list[4].txt
                : policy_list[3].txt
            }
            src={
              privacy
                ? policy_list[2].link
                : location
                ? policy_list[4].link
                : policy_list[3].link
            }
          ></Iframe>
        </div>
      </Contents>
    </Container>
  );
}

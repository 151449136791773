import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Container, PayBtn } from "./Booking";
import GoogleMaps from "../components/GoogleMaps";
import { Address } from "./Hotel";
import { Spinner } from "../components/LoadingScreen";
import { LottieRefCurrentProps } from "lottie-react";
import animationData from "../loading.json";

const DetailContainer = styled(Container)`
  position: relative;
`;

const CancelLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 107;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const LoadingSpinner = styled.div`
  width: 100%;
  height: 500px;
  padding-top: 150px;
`;

const DetailCard = styled.div`
  margin: 0;
  width: 100%;
  max-width: 300px;

  img {
    width: 100%;
    border-radius: 10px;
  }

  .heading {
    padding-bottom: 30px;

    h3 {
      margin: 16px 0 6px;
      color: #161a3f;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.72px;
    }

    .address {
      margin-bottom: 14px;
      color: #5c5f79;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.56px;
    }
  }
  .btn_wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  a {
    text-decoration: none;
  }

  @media screen and (max-width: 768px) {
    max-width: initial;

    .btn_wrapper {
      margin-bottom: 40px;
    }
  }
`;

const Box = styled.div`
  padding: 12px 0;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #2d40ff;
  color: #2d40ff;
  font-size: 14px;
  letter-spacing: -0.42px;
`;

const Content = styled.div`
  width: 100%;
  max-width: 830px;
`;

const ContentCard = styled.div`
  padding-bottom: 30px;
  border-bottom: 1px solid #f6f6f9;

  h3 {
    margin-top: 0;
    color: #161a3f;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.8px;
  }

  div {
    display: flex;
    justify-content: space-between;

    padding: 9px 0;
  }

  span {
    color: #161a3f;
    font-size: 14px;
    letter-spacing: -0.56px;
  }
  .mt {
    margin-top: 40px;
  }
`;

const Location = styled(ContentCard)`
  border: 0;

  div {
    justify-content: initial;
    padding: 0;
  }
`;

const CancelBtn = styled(PayBtn)`
  margin-top: 70px;
  background: #a8abbf;

  @media screen and (max-width: 768px) {
    margin-top: 55px;
  }
`;

export default function ReservationDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [cancleLoading, setCancleLoading] = useState(false);
  const actk = sessionStorage.getItem("actk");
  const lottieRef = useRef<LottieRefCurrentProps>();

  const checkReservation = async () => {
    setLoading(true);
    const res = await axios.get(`/itineraries/${params.id}`);
    console.log(res.data.data);
    setData(res.data.data);
    setLoading(false);
  };

  const copyAddress = () => {
    navigator.clipboard.writeText(`${data.hotel.address}`).then(() => {
      alert("주소가 복사 되었습니다.");
    });
  };

  const handleCancel = async () => {
    setCancleLoading(true);

    const res = await axios.delete(
      `/v1/itineraries/${data.itinerary_id}/rooms`,
      {
        headers: {
          Authorization: `Bearer ${actk}`,
        },
      }
    );
    setCancleLoading(false);
    // res.data.code === "0" &&
    navigate(`/cancel?itinerary_id=${data.itinerary_id}`);
  };

  useEffect(() => {
    checkReservation();
  }, []);

  return (
    <DetailContainer>
      {loading ? (
        <LoadingSpinner>
          <Spinner animationData={animationData} lottieRef={lottieRef as any} />
        </LoadingSpinner>
      ) : (
        data && (
          <>
            {cancleLoading && (
              <CancelLoading>
                <LoadingSpinner>
                  <Spinner
                    animationData={animationData}
                    lottieRef={lottieRef as any}
                  />
                </LoadingSpinner>
              </CancelLoading>
            )}

            <DetailCard>
              {data.hotel.image !== undefined && (
                <img src={data.hotel.image?.links["1000px"].href} alt="hotel" />
              )}

              <div className="heading">
                <h3>{data.hotel.name}</h3>
                <div className="address">{data.hotel.address}</div>
              </div>

              <div className="btn_wrapper">
                <Link to={`/hotel/detail/${data.hotel.property_id}`}>
                  <Box>호텔 세부 정보 보기</Box>
                </Link>
                <Link to={`tel:${data.hotel.phone}`}>
                  <Box>{"전화문의 " + data.hotel.phone}</Box>
                </Link>
              </div>
            </DetailCard>
            <Content>
              <ContentCard>
                <h3>예약 세부 정보</h3>
                <div>
                  <span>객실</span>
                  <span>{data.rooms[0].room_name}</span>
                </div>
                <div>
                  <span>체크인/체크아웃</span>
                  <span>
                    {data.rooms[0].checkin + " ~ " + data.rooms[0].checkout}
                  </span>
                </div>
                <div>
                  <span>예약번호</span>
                  <span>{data.itinerary_id}</span>
                </div>
                <div>
                  <span>예약고객</span>
                  <span>
                    {data.rooms[0].family_name +
                      ", " +
                      data.rooms[0].given_name}
                  </span>
                </div>
              </ContentCard>
              <ContentCard>
                <h3 className="mt">결제 정보</h3>
                <div>
                  <span>신용카드</span>
                  <span>
                    {"₩ " +
                      data.payment_history.product_amount.toLocaleString()}
                  </span>
                </div>
              </ContentCard>
              <Location>
                <h3 className="mt">위치</h3>
                <GoogleMaps
                  coord={{
                    latitude: data.hotel.lat,
                    longitude: data.hotel.lng,
                  }}
                />
                <Address>
                  <div className="loca_wrapper">
                    <img src="/img/location.svg" alt="location" />

                    <div className="address">{data.hotel.address}</div>
                  </div>
                  <button className="copy" onClick={copyAddress}>
                    주소 복사
                  </button>
                </Address>
                {data.rooms[0].status === "booked" && (
                  <CancelBtn onClick={handleCancel}>예약 취소</CancelBtn>
                )}
              </Location>
            </Content>
          </>
        )
      )}
    </DetailContainer>
  );
}

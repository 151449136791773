import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { atom, useAtom, useSetAtom } from "jotai";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import RateDetail from "./RateDetail";

const Container = styled.div`
  border: 1px solid #eeeff3;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  .img_container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: 288px;
    background: #eee;

    .no_img {
      width: 70px;
      height: 70px;
      cursor: default;
    }
    p {
      color: #ccc;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.56px;
    }
  }

  img {
    max-width: 380px;
    height: 288px;
    object-fit: cover;
    cursor: pointer;
  }

  .name {
    height: 100%;
    // max-height: 78px;
    margin-bottom: 12px;
    color: #161a3f;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.72px;
  }
  .check {
    display: flex;
    align-items: start;
    padding: 8px 0;
    cursor: default;

    input {
      margin: 4px 8px 0 0;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
`;

const MoreRoomImgBtn = styled.button`
  position: absolute;
  bottom: 10px;
  right: 10px;

  display: flex;
  align-items: center;
  width: fit-content;
  padding: 8px 16px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.39px;
  border: none;
  border-radius: 100px;
  background-color: rgba(12, 14, 28, 0.56);
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }

  img {
    width: 19px;
    height: 19px;
    margin-right: 6px;
  }
`;

const RoomInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px;
`;

const Common = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 12px;

  p {
    margin: 0;
  }
`;

const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  p {
    margin: 0;
    font-size: 14px;
    color: #191e3b;
  }
  .amenities {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: end;
`;

const Totals = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin: 16px 0 8px;

  .total_wrap {
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 150px;
  }
  p {
    margin: 0;
  }

  .no_refund {
    color: #e20000;
    font-size: 13px;
    letter-spacing: -0.52px;
  }

  .deadline {
    color: #009d3f;
    font-size: 13px;
    letter-spacing: -0.52px;
  }

  .strike {
    text-decoration: line-through;
  }
  .exc {
    color: #161a3f;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: -1.04px;
  }
  .rooms,
  .tax {
    font-size: 13px;
    color: #191e3b;
    letter-spacing: -0.52px;
  }
  .inc {
    color: #191e3b;
    font-size: 13px;
    letter-spacing: -0.52px;
  }

  .rate_detail {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #06f;
    padding: 8px 0 0;
    letter-spacing: -0.52px;
    background-color: #fff;
    border: none;
    cursor: pointer;

    img {
      margin-left: 4px;
      width: 20px;
      height: 20px;
      padding-top: 1px;
    }
  }
`;
const ReservationBtn = styled.button`
  width: 100px;
  height: 45px;
  border: none;
  border-radius: 5px;
  background-color: #2d40ff;
  color: #fff;
  font-size: 14px;
  letter-spacing: -0.42px;
  cursor: pointer;
`;

interface RoomCardProps {
  item: any;
  room: any;
}

export const RoomAtom = atom(false);
export const RateModalAtom = atom(false);
export const RateDetailAtom = atom<any>([]);
export const RoomImgAtom = atom<any>([]);
export default function RoomCard({ item, room }: RoomCardProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const check_in = searchParams.get("check_in");
  const check_out = searchParams.get("check_out");
  const occupancy = searchParams.getAll("occupancy");
  const hotel_id = useLocation().pathname.split("/")[2];
  const search = useLocation().search.slice(1);
  const navigate = useNavigate();
  const [check, setCheck] = useState(item.rates[0].id);
  const [refundable, setRefundable] = useState(item.rates[0].refundable);
  const [deadline, setDeadline] = useState("");
  const [common, setCommon] = useState<string[]>([]);
  const setRoomModal = useSetAtom(RoomAtom);
  const setRoomImg = useSetAtom(RoomImgAtom);
  const setRateModal = useSetAtom(RateModalAtom);
  const setRateDetail = useSetAtom(RateDetailAtom);
  const [night, setNight] = useState(0);

  const [price, setPrice] = useState(item.rates[0].occupancy_pricing);
  const strikethrough = Math.max(
    ...Object.keys(price).map((el) =>
      Number(price[`${el}`].totals.strikethrough?.billable_currency.value)
    )
  );
  const exclusive = Math.max(
    ...Object.keys(price).map((el) =>
      Number(price[`${el}`].totals.exclusive.billable_currency.value)
    )
  );

  const inclusive = Math.max(
    ...Object.keys(price).map((el) =>
      Number(price[`${el}`].totals.inclusive.billable_currency.value)
    )
  );

  useEffect(() => {
    const nights = Math.ceil(
      Math.abs(
        new Date(check_in ? check_in : "").getTime() -
          new Date(check_out ? check_out : "").getTime()
      ) /
        (1000 * 60 * 60 * 24)
    );
    setNight(nights);
  }, []);

  useEffect(() => {
    const total = item.rates.find(
      (el: any) => el.id === check
    ).occupancy_pricing;
    setPrice(total);

    const refund = item.rates.find((el: any) => el.id === check).refundable;
    setRefundable(refund);

    const cancel = new Date(
      item.rates.find((el: any) => el.id === check).cancel_penalties[0].start
    );

    const dl =
      cancel.getMonth() +
      1 +
      "월 " +
      cancel.getDate() +
      "일 / " +
      cancel.toLocaleTimeString().split(":")[0] +
      ":" +
      cancel.toLocaleTimeString().split(":")[1] +
      " 전까지 환불 가능";
    setDeadline(dl);
  }, [check]);

  useEffect(() => {
    const a = item.rates.map((rate: any) =>
      rate.amenities
        ? Object.values(rate.amenities).map((item: any) => item.name)
        : null
    );

    let arr = [];
    if (a[0] === null) return;
    for (let i = 0; i < a[0].length; i++) {
      let state = [];
      for (let j = 0; j < a.length; j++) {
        if (!a[j]?.includes(a[0][i])) {
          state.push(false);
        }
      }
      if (!state.includes(false)) arr.push(a[0][i]);
    }
    setCommon([...arr]);
  }, [item]);

  const handleRateDetail = () => {
    setRateModal(true);
    setRateDetail([exclusive, inclusive]);
  };

  const handleBooking = () => {
    const rate = item.rates.find((el: any) => el.id === check);
    const rate_id = rate.id;
    const bed_groups: any = Object.values(rate.bed_groups);
    sessionStorage.setItem(
      "tk",
      bed_groups[0].links.price_check.href.split("token=")[1]
    );
    const actk = sessionStorage.getItem("actk");

    actk
      ? navigate(
          `/booking/book?${search}&h_id=${hotel_id}&r_id=${item.id}&rate_id=${rate_id}`
        )
      : navigate("/login");
  };

  // console.log(item.rates);

  return (
    <Container>
      {room?.images === undefined ? (
        <div className="img_container">
          <img className="no_img" src="/img/no_image.svg" alt="이미지 없음" />
          <p>이미지가 없습니다</p>
        </div>
      ) : (
        <div
          className="img_container"
          onClick={() => {
            setRoomModal(true);
            setRoomImg(room.images);
          }}
        >
          <img src={room.images[0].links["1000px"].href} alt="room" />
          {room.images.length > 1 && (
            <MoreRoomImgBtn>
              <img src="/img/more_photo.svg" alt="show_more_room_photo" />{" "}
              {room.images.length}
            </MoreRoomImgBtn>
          )}
        </div>
      )}
      <RoomInfo>
        <div className="name">{item.room_name}</div>

        <Common>
          {common.map((item) => (
            <p key={item}>{item}</p>
          ))}
        </Common>

        <>
          {item.rates.length > 1 ? (
            item.rates.map((rate: any) => (
              <div
                className="check"
                key={rate.id}
                onClick={() => {
                  setCheck(rate.id);
                }}
              >
                <input
                  type="radio"
                  name={item.id}
                  id={item.id}
                  value={rate.id}
                  checked={rate.id === check}
                  onChange={(e: any) => {
                    setCheck(e.target.value);
                  }}
                />
                <InfoContainer>
                  <div className="amenities">
                    {rate.refundable ? <p>전액 환불 가능</p> : <p>환불 불가</p>}
                    {rate.amenities &&
                      Object.values(rate.amenities)
                        .filter((el: any) => !common.includes(el.name))
                        .map((item: any) => <p key={item.name}>{item.name}</p>)}
                    {rate.promotions?.value_adds && (
                      <>
                        {Object.values(rate.promotions?.value_adds).map(
                          (item: any) => (
                            <p key={item.id}>{item.description}</p>
                          )
                        )}
                      </>
                    )}
                  </div>

                  <PriceContainer>
                    <p className="exclusive">
                      {"₩ " +
                        Math.round(
                          Math.max(
                            ...Object.values(rate.occupancy_pricing).map(
                              (el: any) =>
                                Number(
                                  el.totals.exclusive.billable_currency.value
                                )
                            )
                          ) / night
                        ).toLocaleString()}
                    </p>
                  </PriceContainer>
                </InfoContainer>
              </div>
            ))
          ) : (
            <InfoContainer></InfoContainer>
          )}

          <Totals>
            <div className="total_wrap">
              {refundable === false ? (
                <div className="no_refund">환불 불가능</div>
              ) : (
                <div className="deadline">{deadline}</div>
              )}
              {!isNaN(strikethrough) && (
                <p className="strike">
                  {"₩ " + Math.round(strikethrough / night).toLocaleString()}
                </p>
              )}
              <p className="exc">
                {"₩ " + Math.round(exclusive / night).toLocaleString()}
              </p>

              {occupancy.length > 1 && (
                <p className="rooms">객실 {occupancy.length}개</p>
              )}

              <p className="inc">
                {"총 요금 ₩ " + (inclusive * occupancy.length).toLocaleString()}
              </p>

              <p className="tax">세금 및 수수료 포함</p>

              <button className="rate_detail" onClick={handleRateDetail}>
                요금 세부 정보{" "}
                <img src="/img/arrow_right_blue.svg" alt="rate_detail" />
              </button>
            </div>
            <ReservationBtn onClick={handleBooking}>예약하기</ReservationBtn>
          </Totals>
        </>
      </RoomInfo>
    </Container>
  );
}

import { useAtomValue } from "jotai";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { occuListAtom } from "./Occupancy";
import { useSearchParams } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;

  .promo {
    display: flex;
    align-items: center;
  }
  .deal {
    margin-right: 6px;
    width: 62px;
    height: 24px;
    border-radius: 5px;
    background: #fff06b;
    text-align: center;
    color: #161a3f;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.48px;
  }

  .strikethrough {
    text-decoration: line-through;
  }
  .exclusive {
    color: #161a3f;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: -1.04px;
  }
  .inclusive {
    color: #06f;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.52px;
  }

  @media screen and (max-width: 480px) {
    align-items: start;

    .exclusive {
      font-size: 20px;
      letter-spacing: -0.8px;
    }
    .inclusive {
      font-size: 12px;
      letter-spacing: -0.48px;
    }
  }
`;

interface PriceProps {
  price: any;
  promo?: {
    deal: {
      description: string;
    };
  };
}

export default function Price({ price, promo }: PriceProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const check_in = searchParams.get("check_in");
  const check_out = searchParams.get("check_out");
  const occuList = useAtomValue(occuListAtom);
  const regex = /[^0-9]/g;
  const deal = promo?.deal?.description.replace(regex, "");
  const [night, setNight] = useState(0);

  const strikethrough = Math.max(
    ...Object.keys(price).map((item) =>
      Number(price[`${item}`].totals.strikethrough?.billable_currency.value)
    )
  );

  const exclusive = Math.max(
    ...Object.keys(price).map((item) =>
      Number(price[`${item}`].totals.exclusive.billable_currency.value)
    )
  );

  const inclusive = Math.max(
    ...Object.keys(price).map((item) =>
      Number(price[`${item}`].totals.inclusive.billable_currency.value)
    )
  );

  useEffect(() => {
    const nights = Math.ceil(
      Math.abs(
        new Date(check_in ? check_in : "").getTime() -
          new Date(check_out ? check_out : "").getTime()
      ) /
        (1000 * 60 * 60 * 24)
    );
    setNight(nights);
  }, []);

  return (
    <Container>
      <div className="promo">
        {promo && (
          <>
            <span className="deal">{deal && deal + "% 할인"}</span>
            <span className="strikethrough">
              {"₩ " + !isNaN(strikethrough) &&
                Math.round(strikethrough / night).toLocaleString()}
            </span>
          </>
        )}
      </div>
      <span className="exclusive">
        {"₩ " + Math.round(exclusive / night).toLocaleString()}
      </span>
      <span className="inclusive">
        {"총 요금 ₩ " + (inclusive * occuList.length).toLocaleString() + " ~"}
      </span>
    </Container>
  );
}

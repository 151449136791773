import { useEffect, useState } from "react";

const useValidate = (value: string) => {
    const [valid, setValid] = useState(false);
    const regex = /[^0-9]/g;
    useEffect(() => {
        if (regex.test(value)) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [value]);

    return valid;
};

export default useValidate;

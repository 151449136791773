export const in_room = {
  //인터넷

  internet: {
    name: "인터넷",
    list: [
      "2046",
      "2191",
      "2192",
      "2390",
      "2391",
      "2392",
      "2393",
      "2403",
      "2404",
      "1073742907",
      "1073742908",
      "1073743392",
      "1073743393",
      "1073743394",
      "1073743395",
      "1073743396",
      "1073743397",
      "1073743398",
      "1073743399",
    ],
  },

  //음식

  food: {
    name: "식사 및 음료",
    list: [
      "3",
      "5",
      "19",
      "131",
      "132",
      "321",
      "324",
      "361",
      "2001",
      "2004",
      "2057",
      "2102",
      "2103",
      "2104",
      "2105",
      "2106",
      "2107",
      "2108",
      "2193",
      "2194",
      "2203",
      "2205",
      "2537",
      "3500",
      "3547",
      "3913",
      "3929",
      "6142",
      "6143",
      "10006",
      "22003",
      "22022",
      "81003",
      "81006",
      "81007",
      "81008",
      "81009",
      "81010",
      "81011",
      "81012",
      "81020",
      "81021",
      "81030",
      "81031",
      "82000",
      "82001",
      "82002",
      "1073742551",
      "1073742552",
      "1073742786",
      "1073742857",
      "1073743287",
      "1073743289",
      "1073743291",
      "1073743316",
      "1073743373",
      "1073743948",
      "1073744141",
      "1073744185",
      "1073744186",
      "1073744187",
      "1073744188",
      "1073744189",
      "1073744190",
      "1073744191",
      "1073744244",
      "1073744382",
      "1073744459",
      "1073744462",
      "1073744464",
      "1073744465",
      "1073744466",
      "1073744560",
      "1073744572",
      "1073744734",
      "1073744735",
      "1073744736",
      "1073744737",
      "1073744738",
      "1073744739",
      "1073744750",
      "1073744751",
      "1073744752",
      "1073744753",
      "1073744754",
      "1073744755",
      "1073744795",
    ],
  },

  //즐길거리

  enjoy: {
    name: "엔터테인먼트",
    list: [
      "9",
      "14",
      "45",
      "123",
      "347",
      "375",
      "2008",
      "2039",
      "2048",
      "2081",
      "2086",
      "2135",
      "2178",
      "2200",
      "2202",
      "2399",
      "2807",
      "2820",
      "3373",
      "3374",
      "4468",
      "5018",
      "5054",
      "10002",
      "41002",
      "41003",
      "41004",
      "41005",
      "42001",
      "42002",
      "51020",
      "51021",
      "60000",
      "60001",
      "60002",
      "60003",
      "60004",
      "60005",
      "60006",
      "60007",
      "60008",
      "60009",
      "60011",
      "60021",
      "60022",
      "60023",
      "60024",
      "61000",
      "61001",
      "1073742602",
      "1073742765",
      "1073742766",
      "1073742768",
      "1073742861",
      "1073744066",
      "1073744067",
      "1073744069",
      "1073744070",
      "1073744071",
      "1073744117",
      "1073744370",
      "1073744528",
      "1073744529",
      "1073744825",
    ],
  },

  //가족 여행 및 편의시설

  familyFriendly: {
    name: "가족여행 편의시설",
    list: [
      "130",
      "2386",
      "1073742599",
      "1073744443",
      "2054",
      "2045",
      "1073745174",
      "4950",
      "4951",
      "4954",
      "1073742815",
      "1073742816",
      "4642",
    ],
  },

  //편의시설

  convenience: {
    name: "편의시설",
    list: [
      "8",
      "10005",
      "1073743291",
      "10003",
      "22001",
      "22002",
      "21002",
      "1073743293",
      "1073743311",
      "1073743372",
      "1073743373",
      "1073742604",
      "1073742771",
      "1073742772",
      "1073742773",
      "1073742774",
      "1073742776",
      "1073744110",
      "1073744904",
      "2177",
      "41",
      "1073744111",
      "1073744112",
      "1073744113",
      "44",
      "1073742791",
      "2047",
      "2067",
      "128",
      "2031",
      "146",
      "1073742775",
      "22012",
      "22070",
      "22072",
      "22009",
      "1073744283",
      "4111",
    ],
  },

  //고객 서비스

  guestService: {
    name: "고객 서비스",
    list: [
      "1073742676",
      "2199",
      "2208",
      "2197",
      "1073743274",
      "1073743275",
      "1073742770",
      "1073743406",
      "1073743926",
      "133",
      "3616",
      "3984",
      "3985",
      "4008",
      "2189",
      "43",
      "10004",
      "1073742601",
      "2016",
      "2063",
      "2064",
      "2070",
      "40",
      "51001",
      "71000",
      "71007",
      "71008",
      "71009",
      "71051",
      "71011",
      "83001",
      "83002",
      "83003",
      "83004",
      "83005",
      "83006",
      "83012",
      "91014",
      "93001",
      "93002",
      "93003",
      "1073744077",
      "1073745171",
      "20",
      "2015",
      "2053",
      "2056",
      "1073744218",
      "22071",
      "369",
      "2387",
      "2167",
      "1073744643",
      "2043",
      "4003",
      "2066",
    ],
  },

  // 침실

  bedroom: {
    name: "침실",
    list: [
      "2559",
      "1073743371",
      "1073744831",
      "1073745067",
      "135",
      "2034",
      "2035",
      "2036",
      "2038",
      "21050",
      "22080",
      "1",
      "1073742764",
      "2030",
      "2150",
      "1073743284",
      "1073743285",
      "22081",
      "22082",
    ],
  },

  //욕실

  bathroom: {
    name: "욕실",
    list: [
      "143",
      "2025",
      "1073744241",
      "1073744241",
      "2166",
      "2170",
      "2183",
      "3858",
      "2421",
      "1073743882",
      "2149",
      "1073742865",
      "1073743370",
      "1073743928",
      "1073744828",
      "144",
      "2180",
      "3502",
      "23004",
      "1073743312",
      "2396",
      "2397",
      "2398",
      "1073744282",
      "142",
      "3501",
      "23002",
      "1073744657",
      "1073744658",
      "1073744660",
      "5013",
      "2168",
      "148",
      "1073744202",
      "4467",
      "1073744201",
      "1073744203",
      "1073744380",
    ],
  },

  //기타

  etc: {
    name: "기타",
    list: [
      "1073742862",
      "1073742863",
      "2201",
      "2206",
      "2207",
      "2137",
      "1073742617",
      "2349",
      "6176",
      "1073743288",
      "2234",
      "2235",
      "2236",
      "2204",
      "6212",
      "6213",
      "3269",
      "1073744900",
      "1073744237",
      "1073744216",
      "1073744217",
      "1073744573",
      "1073744574",
      "2162",
      "1073743569",
      "1073743571",
      "4643",
      "1073744653",
      "6147",
      "2575",
      "2576",
      "2402",
      "2081",
      "1073744327",
      "136",
      "1073744687",
      "2329",
      "1073744356",
      "71001",
      "2112",
      "1073743961",
      "145",
      "2206",
      "2207",
      "2082",
      "10000",
      "100000",
      "10001",
      "21001",
      "21003",
      "2111",
      "2198",
      "1073742858",
      "1073743391",
      "1073743424",
      "1073743425",
    ],
  },
};

import React, { useEffect } from "react";
import { atom, useAtom } from "jotai";
import { styled } from "styled-components";
import GuestNum from "./GuestNum";
import ChildrenAge from "./ChildrenAge";
import { occuListAtom } from "./Occupancy";
import { useLocation } from "react-router-dom";

const Container = styled.div`
  padding: 10px;
  margin-bottom: 12px;
  background: #fcfcfc;
  border-radius: 4px;
  .rooms {
    display: flex;
    justify-content: space-between;
    padding: 4px 4px 8px;
    font-size: 15px;
    font-weight: 500;
    img {
      width: 24px;
      padding: 2px;
      cursor: pointer;
    }
  }
  .guests {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    p {
      margin: 0;
      padding: 8px 0;
    }
    .children_wrapper {
      p {
        padding: 8px 0 0;
      }
    }
    span {
      font-size: 12px;
      color: #5d646d;
    }
  }
`;

const SelectGrid = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 122px);
  gap: 4px 8px;

  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(auto-fill, 140px);
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(auto-fill, 128px);
  }
`;

interface List {
  no: number;
}

interface RoomNumProps {
  item: { adults: number; children: number[] };
  idx: number;
}

export const childrenListAtom = atom<List[]>([]);
export default function RoomNum({ item, idx }: RoomNumProps) {
  const [occuList, setOccuList] = useAtom(occuListAtom);
  const temp = useLocation().pathname.includes("temp");

  // useEffect(() => {
  //   console.log(occuList);
  // }, [occuList]);

  return (
    <Container>
      <div className="rooms">
        {temp ? "객실" : `객실 ${idx + 1}`}
        {occuList.length > 1 && (
          <img
            src="/img/close.svg"
            alt="delete_room"
            onClick={() => {
              occuList.splice(idx, 1);
              setOccuList([...occuList]);
            }}
          />
        )}
      </div>
      <div className="guests">
        <p>{temp ? "인원" : "성인"}</p>
        <GuestNum name="adults" num={item.adults} idx={idx} />
      </div>
      {!temp && (
        <div className="guests">
          <div className="children_wrapper">
            <p>아동</p>
            <span>만 0~17세</span>
          </div>
          <GuestNum name="children" c_num={item.children} idx={idx} />
        </div>
      )}

      <SelectGrid>
        {item.children.map((item, i) => (
          <ChildrenAge key={i} p_idx={idx} item={item} idx={i} />
        ))}
      </SelectGrid>
    </Container>
  );
}

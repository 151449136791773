import styled from "styled-components";
import { useAtom, useSetAtom } from "jotai";
import { reservFlagAtom, reservListAtom } from "../../pages/ReservationList";

export const Contain = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  gap: 6px;

  @media screen and (max-width: 480px) {
    gap: 4px;
  }
`;

export const TabBtn = styled.button<{ $clr?: boolean }>`
  width: 100%;
  padding: 14px 0;
  font-size: 16px;
  color: ${(props) => (props.$clr ? "#8d90ae" : "#ffffff")};
  font-weight: 500;
  letter-spacing: -0.64px;
  background-color: ${(props) => (props.$clr ? "#ffffff" : "#2d40ff")};
  border: ${(props) => (props.$clr ? "1px solid #e7e7ee" : "none")};
  border-radius: 5px;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    font-size: 16px;
    padding: 10px 0;
  }
`;

export default function ReservationCategory() {
  const [reservCategory, setReservCategory] = useAtom(reservFlagAtom);
  const setReservList = useSetAtom(reservListAtom);

  return (
    <Contain>
      <TabBtn
        $clr={reservCategory}
        onClick={() => {
          if (reservCategory) {
            setReservList([]);
            setReservCategory(false);
          }
        }}
      >
        예약 현황
      </TabBtn>
      <TabBtn
        $clr={!reservCategory}
        onClick={() => {
          if (!reservCategory) {
            setReservList([]);
            setReservCategory(true);
          }
        }}
      >
        지난 예약
      </TabBtn>
    </Contain>
  );
}

import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { styled } from "styled-components";
import DatePicker, { dateRangeAtom } from "../components/DatePicker";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { addDays, format } from "date-fns";
import { DateRange } from "react-day-picker";
import SearchInput, {
  destAtom,
  inputFocusAtom,
  keywordAtom,
} from "./SearchInput";
import Occupancy, { occuListAtom } from "./Occupancy";
import Filter, {
  accessAtom,
  amenitiesAtom,
  pricesAtom,
  ratingsAtom,
  starsAtom,
  typesAtom,
} from "./Filter";
import { useNavigate, useSearchParams } from "react-router-dom";
import { loadingAtom, noResultAtom } from "../pages/Search";

const Container = styled.div`
  max-width: 300px;
  width: 100%;
  // height: 100%;
  border-radius: 4px;
  .mobile {
    display: none;
  }

  .heading {
    margin-bottom: 20px;

    strong {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.64px;
    }
  }

  @media screen and (max-width: 1100px) {
    max-width: 830px;
    margin: auto;
    margin-bottom: 14px;

    .heading {
      display: none;
    }
    .mobile {
      display: block;
      padding: 12px 16px;
      border: 1px solid #e7e7ee;
      border-radius: 5px;

      .name {
        color: #161a3f;
        font-size: 14px;
        letter-spacing: -0.56px;
        margin-bottom: 4px;
      }
      .range {
        color: #5c5f79;
        font-size: 12px;
        letter-spacing: -0.48px;
      }
    }
    .dp_none {
      display: none;
    }
    .dp_bk {
      width: 100%;
      display: block;
      top: 0;
      background-color: #fff;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const InputBox = styled.div`
  position: relative;
  margin-top: 10px;
  input,
  .occupancy {
    width: 100%;
    line-height: 28px;
    border: 1px solid #e7e7ee;
    border-radius: 4px;
    padding: 10px 20px 10px 40px;
    outline: none;
    font-size: 14px;
    cursor: pointer;
  }
  .icon {
    position: absolute;
    top: 15px;
    left: 10px;
    color: #5d646d;
  }
`;

export const SearchBtn = styled.button`
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-top: 16px;
  width: 100%;
  padding: 12px 0;
  color: #fff;
  border-radius: 5px;
  background: #2d40ff;
  border: none;
  cursor: pointer;
`;

const CloseBtn = styled.button`
  display: none;
  width: 100%;
  padding: 16px 0;
  color: #2d40ff;
  font-size: 14px;
  letter-spacing: -0.42px;
  border: none;
  border-bottom: 1px solid #f6f6f9;
  background-color: #fff;
  cursor: pointer;

  @media screen and (max-width: 1100px) {
    display: block;
  }
`;

const FilterBtn = styled.button`
  display: none;
  width: 110px;
  margin: 14px 0 20px;
  padding: 7px 0;
  border-radius: 100px;
  border: 1px solid #a8b0ff;
  background-color: #fff;

  @media screen and (max-width: 1100px) {
    display: flex;
    justify-content: center;
    gap: 4px;
  }
`;

export const occupancyAtom = atom(false);
export const defaultSelectedAtom = atom({
  from: addDays(new Date(), 7),
  to: addDays(new Date(), 8),
});
export const HotelListAtom = atom<any>([]);
export const isEndAtom = atom(true);
export const pageAtom = atom(1);
export default function SearchForm() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const check_in = searchParams.get("check_in");
  const check_out = searchParams.get("check_out");
  const search_type = searchParams.get("search_type");
  const lng = searchParams.get("lng");
  const lat = searchParams.get("lat");
  const region_type = searchParams.get("search_type");
  const occu_list = searchParams.getAll("occupancy");
  const dest_name = searchParams.get("dest");

  const [dp, setDp] = useState(false);
  const [defaultSelected, setDefault] = useAtom(defaultSelectedAtom);
  const [focused, setFocused] = useState(false);
  const [inputFocus, setInputFocus] = useAtom(inputFocusAtom);
  const [occupancy, setOccupancy] = useAtom(occupancyAtom);
  const [occuList, setOccuList] = useAtom(occuListAtom);
  const [dateRange, setDateRange] = useAtom(dateRangeAtom);
  const setLoading = useSetAtom(loadingAtom);
  const [hotelList, setHotelList] = useAtom(HotelListAtom);
  const [page, setPage] = useAtom(pageAtom);
  const [dest, setDest] = useAtom(destAtom);
  const setKeyword = useSetAtom(keywordAtom);
  const setIsEnd = useSetAtom(isEndAtom);
  const setNoResult = useSetAtom(noResultAtom);
  const prices = useAtomValue(pricesAtom);
  const ratings = useAtomValue(ratingsAtom);
  const stars = useAtomValue(starsAtom);
  const types = useAtomValue(typesAtom);
  const amenities = useAtomValue(amenitiesAtom);
  const access = useAtomValue(accessAtom);
  const dateRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (check_in && check_out) {
      setDefault({ from: new Date(check_in), to: new Date(check_out) });
    }
    if (dest_name) {
      setKeyword(dest_name);
    }
    if (lat && lng) {
      let destination = { ...dest };
      destination.name = dest_name ? dest_name : "";
      destination.lat = Number(lat);
      destination.lng = Number(lng);
      destination.region_type = region_type;
      setDest(destination);
    }
    if (occu_list) {
      const occ = occu_list.map((item: any) => ({
        adults: Number(item.split("-")[0]),
        children: item.split("-")[1]
          ? [
              ...item
                .split("-")[1]
                .split(",")
                .map((el: any) => Number(el)),
            ]
          : [],
      }));
      setOccuList(occ);
    }
  }, []);

  useEffect(() => {
    getList();
    if (page < 2) window.scrollTo(0, 0);
  }, [searchParams, page, prices, ratings, stars, types, amenities, access]);

  useEffect(() => {
    setDateRange(
      format(defaultSelected.from, "yyyy-MM-dd") +
        "\u00A0~" +
        "\u00A0" +
        format(defaultSelected.to, "yyyy-MM-dd")
    );
  }, [defaultSelected]);

  const getList = async () => {
    setLoading(true);
    const occu = occu_list.map((item: any) => `&occupancy=${item}`).join("");

    const res = await axios.get(
      `/v1/search/hotels?search_type=${search_type}&lng=${lng}&lat=${lat}&page=${page}&category_type=GENERAL&device=ANDROID${occu}&check_in=${check_in}&check_out=${check_out}` +
        `${prices.length > 0 ? `&price=${prices}` : ""}` +
        `${ratings !== "0" ? `&guest_rating=${ratings}` : ""}` +
        `${stars.length > 0 ? `&star=${stars}` : ""}` +
        `${types.length > 0 ? `&lodging=${types}` : ""}` +
        `${amenities.length > 0 ? `&amenities=${amenities}` : ""}` +
        `${access.length > 0 ? `&accessibility=${access}` : ""}`
    );
    setLoading(false);

    setIsEnd(res.data.is_end);

    if (res.data.data.data_list.length === 0) {
      return setNoResult(true);
    } else {
      setNoResult(false);
    }
    // console.log(res.data.data.data_list);

    const regex = /[^0-9]/g;
    const list = [...res.data.data.data_list];
    list.sort((a: any, b: any) => {
      return (
        b.rating.guest?.count - a.rating.guest?.count ||
        b.promotions?.deal.description.replace(regex, "") -
          a.promotions?.deal.description.replace(regex, "")
      );
    });

    if (dest.region_type === "HOTEL") {
      if (page > 1) {
        setHotelList([
          ...hotelList,
          ...list
            .filter((item: any) => item.name === dest.name)
            .concat(list.filter((item: any) => item.name !== dest.name)),
        ]);
      } else {
        setHotelList(
          list
            .filter((item: any) => item.name === dest.name)
            .concat(list.filter((item: any) => item.name !== dest.name))
        );
      }
    } else {
      if (page > 1) {
        setHotelList([...hotelList, ...list]);
      } else {
        setHotelList(list);
      }
    }
  };

  const clickDateOutside = (e: any) => {
    dateRef.current && !dateRef.current.contains(e.target)
      ? setFocused(false)
      : setFocused(!focused);
  };

  useEffect(() => {
    if (focused) setOccupancy(false);
    if (inputFocus) {
      setOccupancy(false);
      setInputFocus(!inputFocus);
    }
  }, [focused, inputFocus]);

  useEffect(() => {
    document.addEventListener("click", clickDateOutside);
    return () => {
      document.removeEventListener("click", clickDateOutside);
    };
  }, []);

  const handleClick = () => {
    if (dest.name === "") return alert("여행지를 입력해주세요.");
    setPage(1);

    const ck_in =
      defaultSelected?.from && format(defaultSelected.from, "yyyy-MM-dd");
    const ck_out =
      defaultSelected?.to && format(defaultSelected.to, "yyyy-MM-dd");
    const occu_list = occuList
      .map(
        (item: any) =>
          `&occupancy=${item.adults}${
            item.children.length !== 0 ? "-" + item.children : ""
          }`
      )
      .join("");

    navigate(
      `/search?search_type=${dest.region_type}&lng=${dest.lng}&lat=${dest.lat}&category_type=GENERAL${occu_list}&device=ANDROID&check_in=${ck_in}&check_out=${ck_out}&dest=${dest.name}`
    );
  };

  return (
    <Container>
      <div
        className={dp ? "dp_none" : "mobile"}
        onClick={() => {
          setDp(!dp);
        }}
      >
        <div className="name">{dest.name}</div>
        <div className="range">{dateRange}</div>
      </div>
      <div className="heading">
        <strong>호텔 검색</strong>
      </div>

      <div className={dp ? "dp_bk" : "dp_none"}>
        <SearchInput />
        <InputBox ref={dateRef}>
          <img
            className="icon"
            src="/img/calendar.svg"
            alt="check_in&check_out"
          />
          <input
            type="text"
            placeholder="체크인-체크아웃"
            value={dateRange}
            readOnly
          />
          {focused && <DatePicker setFocused={setFocused} />}
        </InputBox>

        <InputBox
          onClick={() => {
            setOccupancy(true);
          }}
        >
          <img className="icon" src="/img/person.svg" alt="room_occupancy" />
          <div className="occupancy">
            객실 {occuList.length}개, 인원수{" "}
            {occuList
              .map((item: any) => item.adults)
              .reduce((a: number, b: number) => a + b) +
              occuList
                .map((item: any) => item.children.length)
                .reduce((a: number, b: number) => a + b)}
            명
          </div>
          {occupancy && <Occupancy />}
        </InputBox>

        <SearchBtn onClick={handleClick}>
          <img className="icon" src="/img/search.svg" alt="search" />
          <span>호텔 검색</span>
        </SearchBtn>
        <CloseBtn
          onClick={() => {
            setDp(false);
          }}
        >
          닫기
        </CloseBtn>
      </div>
      <FilterBtn
        onClick={() => {
          navigate("/search/filter");
        }}
      >
        <img src="/img/filter.svg" alt="filter" />
        <span>검색 조건</span>
      </FilterBtn>

      <div className="dp_none">
        <Filter />
      </div>
    </Container>
  );
}

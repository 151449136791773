// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "Noto Sans KR", sans-serif;
  box-sizing: border-box;
  letter-spacing: -0.03em;
}

body {
  margin: 0;
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  background-color: #2d40ff !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,uCAAuC;EACvC,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,SAAS;AACX;;AAEA;;;EAGE,oCAAoC;AACtC","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap\");\n\n* {\n  font-family: \"Noto Sans KR\", sans-serif;\n  box-sizing: border-box;\n  letter-spacing: -0.03em;\n}\n\nbody {\n  margin: 0;\n}\n\n.rdp-day_selected,\n.rdp-day_selected:focus-visible,\n.rdp-day_selected:hover {\n  background-color: #2d40ff !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

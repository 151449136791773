import { styled } from "styled-components";
import { obj } from "../Amenities";
import { in_room } from "../Facilities";
import Parser from "html-react-parser";
import { useEffect, useRef } from "react";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { tabAtom, tabMenuAtom } from "./TabMenu";

const Container = styled.div`
  .wrapper {
    padding: 70px 0;
    border-bottom: 1px solid #f6f6f9;
  }

  @media screen and (max-width: 680px) {
    padding: 0 16px;

    .wrapper {
      padding: 40px 0;
    }
  }

  h3 {
    margin: 0;
    margin-bottom: 32px;
    color: #161a3f;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.8px;
  }

  .list {
    width: 276px;

    @media screen and (max-width: 680px) {
      width: 100%;
    }

    .cards {
      display: grid;
      grid-template-columns: repeat(auto-fill, 80px);
      gap: 24px 0;
    }

    .item_name {
      margin: 0;
      margin-bottom: 14px;
      color: #161a3f;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.64px;
    }

    p {
      margin: 12px 0 0;
      color: #5c5f79;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: -0.52px;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin: 0;
    padding-left: 16px;
  }

  li {
    color: #5c5f79;
    font-size: 13px;
    letter-spacing: -0.52px;
  }
  li::marker {
    color: #959fff;
  }
  .no_bullet {
    list-style: none;
    padding: 0;
    line-height: 20px;
  }
`;

const ContentContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 276px);
  gap: 32px;

  @media screen and (max-width: 680px) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const AmenitiesTopAtom = atom(0);
export const policiesTopAtom = atom(0);
export default function RoomInfo({ detail, amenities, inroom }: any) {
  const amenitiesRef = useRef<HTMLDivElement>(null);
  const policiesRef = useRef<HTMLDivElement>(null);
  const setTab = useSetAtom(tabAtom);
  const tabMenu = useAtomValue(tabMenuAtom);
  // const list = Object.values(amenities, inroom);
  // console.log(list);
  // console.log(obj.parking.includes(item.id) && item.name)

  // console.log(Object.values(obj));

  const handleScroll = () => {
    if (amenitiesRef.current) {
      amenitiesRef.current?.offsetTop < window.scrollY - 90 &&
        setTab("amenities");
    }
    if (policiesRef.current) {
      policiesRef.current?.offsetTop < window.scrollY - 90 &&
        setTab("policies");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    tabMenu === "amenities" &&
      amenitiesRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    tabMenu === "policies" &&
      policiesRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  }, [tabMenu]);

  return (
    <Container>
      <div ref={amenitiesRef} className="wrapper">
        <h3>숙박시설 내 편의시설</h3>
        <ContentContainer>
          {Object.values(obj).map((item) => (
            <div className="list" key={item.name}>
              <p className="item_name">{item.name}</p>
              <ul>
                {Object.values(amenities).map(
                  (el: any) =>
                    item.list.includes(el.id) && <li key={el.id}>{el.name}</li>
                )}
              </ul>
            </div>
          ))}
        </ContentContainer>
      </div>

      <div className="wrapper">
        <h3>객실 내 편의시설</h3>
        <ContentContainer>
          {Object.values(in_room).map((item) => (
            <div className="list" key={item.name}>
              <p className="item_name">{item.name}</p>
              <ul>
                {inroom.map(
                  (el: any) =>
                    item.list.includes(el.id) && <li key={el.id}>{el.name}</li>
                )}
              </ul>
            </div>
          ))}
        </ContentContainer>
      </div>

      <div ref={policiesRef} className="wrapper">
        <h3>정책</h3>
        <ContentContainer>
          <div className="list">
            <p className="item_name">체크인</p>
            <ul>
              <li>
                체크인: {detail.checkin.begin_time} ~ {detail.checkin.end_time}
              </li>
              <li>최소 체크인 나이: 만 {detail.checkin.min_age}세</li>
            </ul>
          </div>
          <div className="list">
            <p className="item_name">체크아웃</p>
            <ul>
              <li>체크아웃: {detail.checkout.time}까지</li>
            </ul>
          </div>
          <div className="list">
            <p className="item_name">특별 체크인 지침</p>
            <ul className="no_bullet">
              <li>{detail.checkin.special_instructions}</li>
            </ul>
          </div>
          {detail.attributes.pets && (
            <div className="list">
              <p className="item_name">반려동물</p>
              <ul>
                {Object.values(detail.attributes.pets).map((item: any) => (
                  <li key={item.name}>{item.name}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="list">
            <p className="item_name">아동 및 추가 침대</p>
            <ul>
              <li>어린이 고객을 환영합니다.</li>
            </ul>
          </div>
          <div className="list">
            <p className="item_name">결제유형</p>
            <div className="cards">
              {Object.values(detail.onsite_payments.types).map(
                (item: any) =>
                  (item.id === "171" ||
                    item.id === "179" ||
                    item.id === "180" ||
                    item.id === "183" ||
                    item.id === "184" ||
                    item.id === "189") && (
                    <img
                      key={item.id}
                      src={`/img/${item.id}.svg`}
                      alt={item.name}
                    />
                  )
              )}
            </div>
          </div>
        </ContentContainer>
      </div>

      <div className="wrapper">
        <h3>중요 정보</h3>
        <ContentContainer>
          {detail.fees?.optional && (
            <div className="list">
              <p className="item_name">기타 선택 사항</p>
              {Parser(detail.fees.optional)}
            </div>
          )}

          <div className="list">
            <p className="item_name">알아두실 사항</p>
            {Parser(detail.checkin.instructions)}
          </div>

          <div className="list">
            <p className="item_name">알려드릴 사항</p>
            {Parser(detail.policies.know_before_you_go)}
          </div>
        </ContentContainer>
      </div>
    </Container>
  );
}

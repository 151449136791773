import { styled } from "styled-components";
import {
  GoogleMap,
  LoadScript,
  MarkerF,
  InfoBox,
} from "@react-google-maps/api";

const Container = styled.div`
  margin: auto;
  width: 100%;
  height: 400px;

  @media screen and (max-width: 480px) {
    max-width: 345px;
    height: 230px;
  }
`;

const containerStyle = {
  width: "100%",
  height: "100%",
};

interface GoogleMapsProps {
  coord: {
    latitude: number;
    longitude: number;
  };
}

export default function GoogleMaps({ coord }: GoogleMapsProps) {
  const position = { lat: coord.latitude, lng: coord.longitude };
  const key = process.env.REACT_APP_GOOGLEMAPS_API_KEY || "";

  return (
    <Container>
      <LoadScript googleMapsApiKey={key}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={position}
          zoom={16}
        >
          <MarkerF
            position={position}
            icon={{ url: "/img/location_large.svg" }}
          />
        </GoogleMap>
      </LoadScript>
    </Container>
  );
}

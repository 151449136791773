import { useSetAtom } from "jotai";
import { useState } from "react";
import { styled } from "styled-components";
import { modalAtom } from "./ImageGallery";
import { useLocation } from "react-router-dom";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 101;
  background-color: #0c0e1c;

  .wrapper {
    height: 100%;
    min-height: 666px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box {
    width: 100%;
    max-width: 1200px;
    aspect-ratio: 12 / 8;
  }

  .carousel_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0;
  }

  .close {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    background: transparent url("/img/close_wh.svg") no-repeat center;
    border: none;
    cursor: pointer;
  }

  .btn_box {
    position: absolute;
    top: 50%;
    bottom: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 36px;

    button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: none;
        cursor: pointer;
    }

    @media screen and (max-width: 768px) { 
      top: initial;
      bottom: 20%;
    }

  }
  .left {
      transform: rotate(180deg);
      background: #fff url("/img/arrow_right.svg") no-repeat center;
      background-size: 30px;
    }
    .right {
      background: #fff url("/img/arrow_right.svg") no-repeat center;
      background-size: 30px;
    }
  }
`;

const CaptionContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;

  span {
    color: #fff;
    padding: 0 16px;
  }
`;

interface ImgCarouselProps {
  idx: number;
  list: any;
}

export default function ImgCarousel({ idx, list }: ImgCarouselProps) {
  const airtellist = useLocation().pathname.includes("airtel");
  const [index, setIndex] = useState(idx);
  const setModal = useSetAtom(modalAtom);

  const handleCarousel = (e: any) => {
    if (e.target.className.includes("left")) {
      index !== 0 && setIndex(index - 1);
    } else {
      list.images.length - 1 > index && setIndex(index + 1);
    }
  };

  return (
    <Container>
      <button
        className="close"
        onClick={() => {
          setModal(false);
        }}
      />
      <div className="btn_box">
        <button className="arrow left" onClick={handleCarousel} />
        <button className="arrow right" onClick={handleCarousel} />
      </div>
      <div className="wrapper">
        <div className="box">
          <img
            className="carousel_img"
            src={
              airtellist
                ? list.images[index].image_url
                : list.images[index].links["1000px"].href
            }
            alt={airtellist ? `image${index}` : list.images[index].caption}
          />
        </div>
      </div>
      {!airtellist && (
        <CaptionContainer>
          <span>{list.images[index].caption}</span>
          <span>
            {index + 1} / {list.images.length}
          </span>
        </CaptionContainer>
      )}
    </Container>
  );
}

import { styled } from "styled-components";
import { EventListMoveBtn } from "../home_components/event/HomeEvent";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";

const Container = styled.div<{ $mg?: boolean }>`
  margin: auto;
  width: 100%;
  max-width: 1200px;
  margin-top: ${(props) => (props.$mg ? "85px" : "100px")};
  margin-bottom: ${(props) => props.$mg && "40px"};

  a {
    text-decoration: none;
  }

  @media screen and (max-width: 768px) {
    margin-top: ${(props) => (props.$mg ? "24px" : "60px")};
  }

  @media screen and (max-width: 480px) {
    padding: 0 16px;

    h3 {
      font-size: 20px;
      justify-content: ${(props) => !props.$mg && "start"};
    }
  }
`;

const Title = styled.h3`
  width: fit-content;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
  color: #161a3f;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -1.2px;

  @media screen and (max-width: 768px) {
    margin: initial;
    padding: 0;
    font-size: 20px;
    letter-spacing: -0.8px;
  }
`;

const CardContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 30px 0 40px;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 380px);
  gap: 30px;

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(auto-fill, 172px);
    gap: 14px;
    padding: 20px 0 30px;
  }
`;

const Card = styled.div`
  width: 100%;
  max-width: 380px;
  height: 100%;

  div {
    width: 380px;
    height: 288px;
    border-radius: 10px;
    overflow: hidden;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .title {
    height: fit-content;

    p {
      margin-top: 10px;
      margin-bottom: 3px;
      color: #161a3f;
      font-size: 16px;
      letter-spacing: -0.64px;
    }
    span {
      color: #161a3f;
      font-size: 22px;
      font-weight: 700;
      letter-spacing: -0.88px;
    }
  }
  @media screen and (max-width: 480px) {
    div {
      width: 172px;
      height: 130px;
    }
    .title {
      p {
        font-size: 14px;
      }
      span {
        font-size: 18px;
      }
    }
  }
`;

export default function HomeEvent() {
  const eventlist = useLocation().pathname.includes("eventlist");
  const [list, setList] = useState<any>([]);
  const [btn, setBtn] = useState(false);

  const getList = async () => {
    const res = await axios.get(
      "/product/list?client_id=1&product_sort=PACKAGE&size=30&page=1"
    );
    const length = res.data.data.package_list.length;

    const sorted_list = res.data.data.package_list.sort(
      (a: any, b: any) => a.order_by - b.order_by
    );

    if (!eventlist && length > 6) {
      setBtn(true);
      setList(sorted_list.slice(0, 6));
    } else {
      setList(sorted_list);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Container $mg={eventlist}>
      <Title>기획 상품</Title>
      <CardContainer>
        {list.map((item: any, i: number) => (
          <Link key={i + item.title} to={`/eventitem/${item.package_seq}`}>
            <Card>
              <div>
                <img src={item.main_img} alt={item.title} />
              </div>
              <div className="title">
                <p>{item.title}</p>
                <span>{"₩ " + item.price.toLocaleString() + " ~"}</span>
              </div>
            </Card>
          </Link>
        ))}
      </CardContainer>

      {btn && (
        <Link to={`/eventlist`}>
          <EventListMoveBtn>
            기획 상품 더보기
            <img src="/img/arrow_right.svg" alt="show_more_deal" />
          </EventListMoveBtn>
        </Link>
      )}
    </Container>
  );
}

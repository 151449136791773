import axios from "axios";
import { atom, useAtom, useAtomValue } from "jotai";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ReservNoResult from "../hotel_reservation/common/ReservNoResult";
import ReservationCategory from "../hotel_reservation/list/ReservationCategory";
import ReservationCard from "../components/ReservationCard";
import { LottieRefCurrentProps } from "lottie-react";
import { LoadingSpinner } from "./ReservationDetail";
import { Spinner } from "../components/LoadingScreen";
import animationData from "../loading.json";
import { loginAtom } from "../components/NavBar";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 480px) {
    padding: 0 16px;
  }
`;

const Title = styled.h3`
  margin: 90px 0 70px;
  color: #161a3f;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -1.2px;

  @media screen and (max-width: 768px) {
    margin: 40px 0 30px;
    font-size: 20px;
    letter-spacing: -0.8px;
  }
`;

const CardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 380px);
  justify-content: center;

  gap: 30px;

  padding: 50px 0;

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(auto-fill, 358px);
    padding: 40px 0;
  }
`;

const ListMoreBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 220px;
  padding: 12px 0;
  border-radius: 100px;
  border: 1px solid #a8b0ff;
  background: #fff;
  margin-top: 40px;

  color: #6f7cff;
  font-size: 14px;
  letter-spacing: -0.56px;

  background-color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #f3f5ff;
  }
`;

export const reservListAtom = atom<any>([]);
export const reservFlagAtom = atom(false);
export const ReservationNoResultAtom = atom(false);
export const pageAtom = atom(1);
export const isEndAtom = atom(false);
export default function ReservationList() {
  const navigate = useNavigate();
  const [reservList, setReservList] = useAtom(reservListAtom);
  const reservFlag = useAtomValue(reservFlagAtom);
  const [page, setPage] = useAtom(pageAtom);
  const [isEnd, setEnd] = useAtom(isEndAtom);
  const [noResult, setNoResult] = useAtom(ReservationNoResultAtom);
  const [loading, setLoading] = useState(false);
  const lottieRef = useRef<LottieRefCurrentProps>();
  const login = useAtomValue(loginAtom);

  const actk = sessionStorage.getItem("actk");

  useEffect(() => {
    if (!login) return navigate("/login");
  }, [login]);

  const getList = async () => {
    setLoading(true);
    setNoResult(false);
    const res = await axios.get(
      `/v1/itineraries?size=10&page=${page}&status=${
        reservFlag ? "PAST" : "NOW"
      }`,
      {
        headers: {
          Authorization: `Bearer ${actk}`,
        },
      }
    );
    // console.log(res.data.data);
    res.data.data.is_end && setEnd(res.data.data.is_end);
    if (res.data.data.booking_list.length === 0) setNoResult(true);
    if (page > 1) setReservList([...reservList, ...res.data.data.booking_list]);
    setReservList(res.data.data.booking_list);
    setLoading(false);
  };

  useEffect(() => {
    getList();
  }, [page, reservFlag]);

  const handleClick = () => {
    setPage(page + 1);
  };

  return (
    <Container>
      <Title>예약 내역</Title>
      <ReservationCategory />
      {loading ? (
        <LoadingSpinner>
          <Spinner animationData={animationData} lottieRef={lottieRef as any} />
        </LoadingSpinner>
      ) : noResult ? (
        <ReservNoResult />
      ) : (
        <CardContainer>
          {reservFlag
            ? reservList.map(
                (item: any, i: number) =>
                  item.booking_status !== "RESUME" && (
                    <ReservationCard key={item.itinerary_id + i} item={item} />
                  )
              )
            : reservList.map((item: any, i: number) => (
                <ReservationCard key={item.itinerary_id + i} item={item} />
              ))}
        </CardContainer>
      )}
      {!isEnd && (
        <ListMoreBtn onClick={handleClick}>리스트 더 보기</ListMoreBtn>
      )}
    </Container>
  );
}

import { styled } from "styled-components";
import animationData from "../../success-icon.json";
import { LottieRefCurrentProps } from "lottie-react";
import { CheckMark, HistoryBtn } from "../Success";
import { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 180px;

  .heading {
    padding: 40px 0;
    h3 {
      width: fit-content;
      margin: auto;
      padding: 12px 0;
      color: #161a3f;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.96px;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const Contents = styled.div`
  padding: 50px 60px;
  border-radius: 10px;
  background: rgba(247, 247, 252, 0.4);
  color: #161a3f;
  letter-spacing: -0.56px;

  .ct {
    text-align: center;
  }
  .em {
    color: #06f;
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 8px;

    p {
      margin: 0;
      padding: 4px;
    }
  }
  .info_container {
    margin-top: 40px;
    padding: 16px 0 20px;
    border: 1px solid #cccccc;
    border-radius: 4px;

    h3 {
      margin: 0;
      padding-bottom: 16px;
      text-align: center;
      color: #161a3f;
      font-size: 18px;
      font-weight: 500;
    }
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 24px;
      margin-right: 24px;
    }
    .operation {
      text-align: center;
    }
    p {
      margin: 0;
    }
    span {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 32px 40px;
    .ct {
      word-break: keep-all;
      font-size: 14px;
    }
    .em {
      font-size: 18px;
    }
    .info {
      img {
        margin-right: 16px;
      }
      p {
        font-size: 14px;
      }
      span {
        font-size: 12px;
      }
    }
  }
`;

export default function TempSuccess() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const company = searchParams.get("company");
  const lottieRef = useRef<LottieRefCurrentProps>();

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(1.4);
    }
  }, []);

  return (
    <Container>
      <>
        <div className="heading">
          <CheckMark
            animationData={animationData}
            lottieRef={lottieRef as any}
            loop={false}
          />
          <h3>예약요청이 완료되었습니다.</h3>
        </div>

        <HistoryBtn
          onClick={() => {
            company ? navigate("/temp?company=ymstar&hsq=1") : navigate(`/`);
          }}
        >
          홈으로 돌아가기
        </HistoryBtn>
      </>
    </Container>
  );
}

import { atom, useAtom } from "jotai";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { styled } from "styled-components";
import { useEffect, useRef, useState } from "react";

const Container = styled.nav`
  display: flex;
  width: 100%;
  max-width: 1200px;
  padding: 25px 0;
  margin: auto;
  justify-content: space-between;

  .menu {
    display: none;
  }

  @media screen and (max-width: 1080px) {
    padding: 20px 16px;

    .menu {
      display: block;
    }
  }
`;

const MenuContainer = styled.div<{ $dp: boolean }>`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 30px;
  width: 100%;

  a {
    text-decoration: none;
  }

  .hoverbox {
    height: 100%;
    display: flex;

    .flex_row {
      display: flex;

      .arrow {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1080px) {
    display: ${(props) => (props.$dp ? "flex" : "none")};
    position: absolute;
    z-index: 10;
    left: 0;
    top: 76px;
    flex-direction: column;
    align-items: start;
    gap: 0;
    width: 100%;
    padding: 24px 16px;
    background: #fff;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 15px 15px -15px rgba(105, 88, 142, 0.1);

    a {
      width: 100%;
    }

    .border {
      width: 100%;
      display: flex;
      justify-content: end;
      padding-top: 20px;
      border-top: 1px solid #f6f6f9;
    }

    .hoverbox {
      width: 100%;
      flex-direction: column;
      justify-content: space-between;

      .flex_row {
        display: flex;

        .arrow {
          display: block;
          margin-bottom: 30px;
          padding: 0 8px;
        }
        .rotate {
          transform: rotate(180deg);
          margin-bottom: 12px;
        }
      }
    }
  }
`;

const MenuBox = styled.div<{ $drop?: boolean }>`
  display: flex;
  align-items: center;
  gap: 7px;

  cursor: pointer;

  span {
    color: #161a3f;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.48px;
  }
  .icon {
    width: 20px;
    height: 20px;
  }

  @media screen and (max-width: 1080px) {
    width: 100%;
    margin-bottom: ${(props) => (props.$drop ? "12px" : "30px")};
  }
`;

const Dropdown = styled.ul`
  position: absolute;
  z-index: 10;
  top: 64px;
  width: 130px;
  margin: 0;
  padding: 16px;
  list-style: none;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(105, 88, 142, 0.1);

  li {
    padding: 8px 0;
    color: #122437;
    font-size: 13px;
    letter-spacing: -0.39px;
    cursor: pointer;
  }
  li:hover {
    color: #2d40ff;
  }

  @media screen and (max-width: 1080px) {
    width: 100%;
    position: initial;
    padding: 0 28px 16px;
    // padding: 16px 28px;
    box-shadow: none;
  }
`;

const LoginBtn = styled.button`
  padding: 9px 18px;
  border-radius: 5px;
  border: 1px solid #2d40ff;
  background: #fff;
  color: #2d40ff;
  font-size: 14px;
  letter-spacing: -0.42px;
  cursor: pointer;
`;

export const loginAtom = atom(sessionStorage.getItem("actk"));
export default function NavBar() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const company = searchParams.get("company");
  const booking = useLocation().pathname.includes("booking");
  const signup = useLocation().pathname.includes("signup");
  const find = useLocation().pathname.includes("find");
  const mypage = useLocation().pathname.includes("mypage");
  const [login, setlogin] = useAtom(loginAtom);
  const [menu, setMenu] = useState(false);
  const menuRef = useRef<HTMLImageElement>(null);
  const mypageRef = useRef<HTMLDivElement>(null);
  const [dropdown, setDropdown] = useState(false);

  const handleMenu = () => {
    setMenu(!menu);
  };

  const handleCloseMenu = (e: any) => {
    if (login) {
      mypageRef.current &&
        !mypageRef.current.contains(e.target) &&
        menuRef.current !== e.target &&
        setMenu(false);
    } else {
      menuRef.current !== e.target && setMenu(false);
    }
  };

  const handleLogout = () => {
    setlogin("");
    setMenu(false);
    sessionStorage.clear();
    mypage && navigate("/");
  };

  useEffect(() => {
    document.addEventListener("click", handleCloseMenu);
    return () => {
      document.removeEventListener("click", handleCloseMenu);
    };
  }, [login]);

  useEffect(() => {
    booking && !login && navigate("/");
  }, [login]);

  return (
    <header>
      <Container>
        {company ? (
          <Link
            style={{ textDecoration: "none", color: "initial" }}
            to={`/temp?company=ymstar&hsq=1`}
          >
            <img src="/img/logo_ym.svg" alt="logo" />
          </Link>
        ) : (
          <Link style={{ textDecoration: "none", color: "initial" }} to={`/`}>
            <img src="/img/logo.svg" alt="logo" />
          </Link>
        )}

        {!company && (
          <>
            <img
              ref={menuRef}
              className="menu"
              src="/img/hamburger.svg"
              alt="menu"
              onClick={handleMenu}
            />
            <MenuContainer $dp={menu}>
              {login && (
                <Link to={`/mypage/reservationlist`}>
                  <MenuBox>
                    <img
                      className="icon"
                      src="/img/nav_icon_hotel.svg"
                      alt="hotelreservation"
                    />
                    <span>호텔 예약내역</span>
                  </MenuBox>
                </Link>
              )}

              {/* <Link to={`/airtellist`}>
            <MenuBox>
              <img
                className="icon"
                src="/img/nav_icon_airtel.svg"
                alt="airtel"
              />
              <span>에어텔</span>
            </MenuBox>
          </Link> */}

              {/* <Link to={`/hotdeallist`}>
                <MenuBox>
                  <img className="icon" src="/img/product.svg" alt="hotdeal" />
                  <span>핫딜상품</span>
                </MenuBox>
              </Link>

              <Link to={`/hotdealreservation/`}>
                <MenuBox>
                  <img
                    className="icon"
                    src="/img/nav_icon_calendar.svg"
                    alt="hotelreservation"
                  />
                  <span>핫딜상품 예약조회</span>
                </MenuBox>
              </Link> */}

              <Link to={`/eventlist`}>
                <MenuBox>
                  <img
                    className="icon"
                    src="/img/nav_icon_thumbup.svg"
                    alt="event"
                  />
                  <span>기획상품</span>
                </MenuBox>
              </Link>
              <Link to={`/eventreservation`}>
                <MenuBox>
                  <img
                    className="icon"
                    src="/img/event_calendar.svg"
                    alt="packagereservation"
                  />
                  <span>기획상품 예약조회</span>
                </MenuBox>
              </Link>

              {login && (
                <div
                  ref={mypageRef}
                  className="hoverbox"
                  onMouseOver={() => setDropdown(true)}
                  onMouseOut={() => setDropdown(false)}
                  onClick={() => setDropdown(!dropdown)}
                >
                  <div className="flex_row">
                    <MenuBox $drop={dropdown}>
                      <img
                        className="icon"
                        src="/img/nav_icon_mypage.svg"
                        alt="mypage"
                      />
                      <span>마이페이지</span>
                    </MenuBox>
                    <img
                      className={dropdown ? "rotate arrow" : "arrow"}
                      src="/img/arrow_down.svg"
                      alt="dropdown"
                    />
                  </div>

                  {dropdown && (
                    <Dropdown>
                      <li onClick={() => navigate("/mypage/account")}>
                        내 계정 정보
                      </li>
                      <li onClick={() => navigate("/mypage/change/password")}>
                        비밀번호 변경
                      </li>
                      <li onClick={() => navigate("/mypage/reservationlist")}>
                        나의 예약정보
                      </li>
                    </Dropdown>
                  )}
                </div>
              )}

              <div className="border">
                {login ? (
                  <LoginBtn onClick={handleLogout}>로그아웃</LoginBtn>
                ) : (
                  <LoginBtn
                    onClick={() => {
                      setMenu(false);
                      navigate("/login", {
                        state: signup || find ? "main" : null,
                      });
                    }}
                  >
                    로그인
                  </LoginBtn>
                )}
              </div>
            </MenuContainer>
          </>
        )}
      </Container>
    </header>
  );
}

import { styled } from "styled-components";
import Parser from "html-react-parser";
import { useState } from "react";
import { atom, useAtom, useSetAtom } from "jotai";
import { termsModalAtom } from "../pages/Booking";
import { useLocation } from "react-router-dom";

const Container = styled.div`
  border-radius: 5px;
  border: 1px solid #f6f6f9;
  background: #fcfcfc;
  padding: 30px 20px;

  .heading {
    display: flex;
    align-items: center;
    gap: 6px;

    h3 {
      margin: 0;
      color: #161a3f;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.72px;
    }
  }
  ul {
    margin: 0;
    padding: 15px 0 15px 20px;
  }
  li {
    color: #5c5f79;
    font-size: 13px;
    letter-spacing: -0.52px;
    line-height: 24px;
  }
  li::marker {
    color: #959fff;
  }

  .cancel {
    color: #e20000;
    font-size: 13px;
    letter-spacing: -0.52px;
  }
  .no {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.1px;
  }
  input {
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-bottom: 1px;
  }
  .check {
    display: flex;
    align-items: start;
    gap: 10px;
    margin-bottom: 14px;
  }
  label {
    color: #161a3f;
    font-size: 14px;
    letter-spacing: -0.42px;
  }
  .terms {
    color: #06f;
    cursor: pointer;
  }
`;

const ContentBox = styled.div`
  h3 {
    color: #161a3f;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.64px;
  }
  p {
    margin: 0;
    color: #5c5f79;
    font-size: 13px;
    letter-spacing: -0.52px;
  }

  ul {
    padding: 0;
    padding-left: 20px;
  }
`;

export const termCheckAtom = atom(false);
export const termsIndexAtom = atom(0);
export default function ImportantInfo({ detail, cancel, refundable }: any) {
  const date = new Date(cancel);
  const [check, setCheck] = useAtom(termCheckAtom);
  const setModal = useSetAtom(termsModalAtom);
  const setIndex = useSetAtom(termsIndexAtom);
  const temp = useLocation().pathname.includes("temp");

  const handleModal = (i: number) => {
    setModal(true);
    setIndex(i);
  };

  return (
    <Container>
      <div className="heading">
        <img src="/img/exclamation.svg" alt="important_info" />
        <h3>예약에 대한 중요한 정보</h3>
      </div>
      <ul>
        <li>예약 금액이 직불/신용카드로 즉시 청구됩니다.</li>
        {temp ? (
          <li>
            얼리 체크인 및 레이트 체크아웃이 가능합니다. 현장에서 문의해 주시기
            바랍니다.
          </li>
        ) : (
          <li>
            정해진 시간보다 늦게 체크인하거나 일찍 체크아웃하실 경우 환불되지
            않습니다.
          </li>
        )}
        <li>숙박을 연장하려면 새로 예약하셔야 합니다.</li>
        <li>
          도착 시 프런트 데스크 직원이 도와드립니다. 자세한 내용은 예약 확인
          메일에 나와 있는 연락처 정보를 숙박 시설에 문의해 주시기 바랍니다.
        </li>
      </ul>

      <ContentBox>
        <h3>체크인</h3>
        <p>
          체크인: {detail.checkin?.begin_time} ~ {detail.checkin?.end_time}
        </p>
        <p>최소 체크인 나이: 만 {detail.checkin?.min_age}세</p>
      </ContentBox>
      <ContentBox>
        <h3>체크아웃</h3>
        <p>체크아웃: {detail.checkout?.time}까지</p>
      </ContentBox>
      <ContentBox>
        <h3>특별 체크인 지침</h3>
        <p>{detail.checkin?.special_instructions}</p>
      </ContentBox>
      {detail.checkin && (
        <ContentBox>
          <h3>알아두실 사항</h3>
          {Parser(detail.checkin.instructions)}
        </ContentBox>
      )}
      {detail.fees && (
        <ContentBox>
          <h3>기타 선택 사항</h3>
          {Parser(detail.fees.optional)}
        </ContentBox>
      )}

      {temp ? (
        <p className="cancel">
          환불은 체크인 2일 전(고객센터 운영일 기준) 14:00 까지 환불 가능
          (고객센터 운영시간 내 취소가능)
          <br />
          자세한 환불가능기간은 호텔 페이지 내 공지사항 이미지 참조
        </p>
      ) : refundable ? (
        <p className="cancel">
          {cancel.toString().split("T")[0]}
          {" / " +
            date.toLocaleTimeString().split(":")[0] +
            ":" +
            date.toLocaleTimeString().split(":")[1] +
            " 전까지 환불 가능합니다."}
        </p>
      ) : (
        <p className="cancel no">환불 불가능</p>
      )}

      <div className="check">
        <input
          type="checkbox"
          name="agree"
          checked={check}
          onChange={() => {
            setCheck(!check);
          }}
        />
        <label>
          <div>
            <span>약관에 모두 동의합니다.</span>{" "}
            <span className="terms" onClick={() => handleModal(0)}>
              서비스 이용약관
            </span>
            {" 및 "}
            <span className="terms" onClick={() => handleModal(1)}>
              개인정보 보호정책
            </span>
          </div>
        </label>
      </div>
    </Container>
  );
}

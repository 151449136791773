import { styled } from "styled-components";
import { ApplyBtn } from "./DatePicker";
import RoomNum from "./RoomNum";
import { atom, useAtom, useSetAtom } from "jotai";
import { occupancyAtom } from "./SearchForm";
import { useLocation } from "react-router-dom";

const Container = styled.div`
  position: absolute;
  z-index: 10;
  top: 54px;
  width: 100%;
  padding: 16px 14px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  font-size: 14px;

  .btn_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

const AddRoomBtn = styled.div`
  color: #2d40ff;
  font-weight: 500;
  padding: 8px 8px;
  border-radius: 18px;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #0c0e1c0d;
  }
`;

export const occuListAtom = atom<any>([{ adults: 2, children: [] }]);
export default function Occupancy() {
  const temp = useLocation().pathname.includes("temp");
  const setOccupancy = useSetAtom(occupancyAtom);
  const [occuList, setOccuList] = useAtom(occuListAtom);

  const addRoom = () => {
    setOccuList([...occuList, { adults: 2, children: [] }]);
  };

  return (
    <Container>
      {occuList.map((item: any, i: number) => (
        <RoomNum key={i} item={item} idx={i}></RoomNum>
      ))}
      {!temp && (
        <div className="btn_wrapper">
          {occuList.length < 8 && (
            <AddRoomBtn onClick={addRoom}>객실 추가</AddRoomBtn>
          )}
        </div>
      )}
      <div className="btn_wrapper">
        <ApplyBtn
          style={{ margin: "12px 0 0 0" }}
          onClick={(e: any) => {
            e.stopPropagation();
            setOccupancy(false);
          }}
        >
          적용
        </ApplyBtn>
      </div>
    </Container>
  );
}

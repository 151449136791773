import { styled } from "styled-components";
import DatePicker, { dateRangeAtom } from "../../../components/DatePicker";
import { addDays, format } from "date-fns";
import {
  InputBox,
  SearchBtn,
  defaultSelectedAtom,
  occupancyAtom,
} from "../../../components/SearchForm";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { atom, useAtom } from "jotai";
import Occupancy, { occuListAtom } from "../../../components/Occupancy";
// import { tabMenuAtom } from "./TabMenu";

const Container = styled.div`
  margin-top: 38px;
  display: flex;
  gap: 12px;

  @media screen and (max-width: 768px) {
    display: block;
    margin-top: 24px;
    padding: 0 16px;
  }
`;
const InputContainer = styled(InputBox)`
  width: 312px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const SearchButton = styled(SearchBtn)`
  width: 110px;
  padding: 15px 0 13px 0;
  margin-top: 10px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 16px;
  }
`;

export const TempRoomSearchAtom = atom(false);
export default function TempRoomSearch() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [focused, setFocused] = useState(false);
  const dateRef = useRef<HTMLDivElement>(null);
  const check_in = searchParams.get("check_in");
  const check_out = searchParams.get("check_out");
  const company = searchParams.get("company");
  const occuArr = searchParams.getAll("occupancy");

  const [defaultSelected, setDefault] = useAtom(defaultSelectedAtom);
  const [dateRange, setDateRange] = useAtom(dateRangeAtom);
  const [search, setSearch] = useAtom(TempRoomSearchAtom);
  const [occupancy, setOccupancy] = useAtom(occupancyAtom);
  const [occuList, setOccuList] = useAtom(occuListAtom);

  useEffect(() => {
    if (defaultSelected?.from && defaultSelected?.to) {
      setDateRange(
        format(
          check_in ? new Date(check_in) : new Date(addDays(new Date(), 2)),
          "yyyy-MM-dd"
        ) +
          "\u00A0~" +
          "\u00A0" +
          format(
            check_out ? new Date(check_out) : new Date(addDays(new Date(), 3)),
            "yyyy-MM-dd"
          )
      );
      setDefault({
        from: check_in ? new Date(check_in) : new Date(addDays(new Date(), 2)),
        to: check_out ? new Date(check_out) : new Date(addDays(new Date(), 3)),
      });
    }
  }, [check_in, check_out]);

  useEffect(() => {
    if (!company) {
      const occu = occuArr.map((item: any) =>
        item.includes("-")
          ? {
              adults: Number(item.split("-")[0]),
              children: [...item.split("-")[1].split(",").map(Number)],
            }
          : { adults: Number(item), children: [] }
      );
      setOccuList([...occu]);
    }
  }, [searchParams]);

  useEffect(() => {
    // console.log(search);
    if (search) {
      if (!company) {
        if (defaultSelected?.from && defaultSelected?.to) {
          searchParams.set(
            "check_in",
            format(defaultSelected.from, "yyyy-MM-dd")
          );
          searchParams.set(
            "check_out",
            format(defaultSelected.to, "yyyy-MM-dd")
          );
          setSearchParams(searchParams);
        }

        searchParams.delete("occupancy");
        occuList.map((item: any) =>
          searchParams.append("occupancy", `${item.adults}`)
        );
        setSearchParams(searchParams);
      }
    }
  }, [search]);

  useEffect(() => {
    if (focused) {
      setOccupancy(false);
    }
  }, [focused]);

  const clickDateOutside = (e: any) => {
    if (dateRef.current && !dateRef.current.contains(e.target)) {
      setFocused(false);
    } else {
      setFocused(!focused);
    }
  };

  useEffect(() => {
    document.addEventListener("click", clickDateOutside);
    return () => {
      document.removeEventListener("click", clickDateOutside);
    };
  }, []);

  return (
    <Container>
      <InputContainer ref={dateRef}>
        <img
          className="icon"
          src="/img/calendar.svg"
          alt="check_in&check_out"
        />
        <input
          type="text"
          placeholder="체크인-체크아웃"
          value={dateRange}
          readOnly
        />
        {focused && <DatePicker setFocused={setFocused} />}
      </InputContainer>

      <InputContainer
        onClick={() => {
          setOccupancy(true);
        }}
      >
        <img className="icon" src="/img/person.svg" alt="room_occupancy" />

        <div className="occupancy">
          객실 {occuList.length}개, 인원수{" "}
          {occuList
            .map((item: any) => item.adults)
            .reduce((a: number, b: number) => a + b) +
            occuList
              .map((item: any) => item.children.length)
              .reduce((a: number, b: number) => a + b)}
          명
        </div>

        {occupancy && <Occupancy />}
      </InputContainer>

      <SearchButton onClick={() => setSearch(true)}>
        <img className="icon" src="/img/search.svg" alt="search" />
        <span>호텔 검색</span>
      </SearchButton>
    </Container>
  );
}

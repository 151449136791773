import axios from "axios";
import { atom, useAtom } from "jotai";
import { ChangeEvent, useEffect } from "react";
import { styled } from "styled-components";

const Container = styled.div`
  width: 100%;

  h3 {
    margin: 0;
    margin-bottom: 30px;
    color: #161a3f;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.8px;
  }

  .container {
    padding: 40px 0;
    border-top: 1px solid #f6f6f9;
  }
  .container:first-of-type {
    padding-top: 0;
    border: none;
  }

  @media screen and (max-width: 768px) {
    .container:first-of-type {
      padding: 40px 0;
      border-top: 1px solid #f6f6f9;
    }
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  label {
    span {
      display: block;
      margin-bottom: 16px;
      color: #161a3f;
      font-size: 14px;
      letter-spacing: -0.56px;
    }
    input {
      width: 100%;
      max-width: 460px;
      padding: 12px;
      border: 1px solid #e7e7ee;
      border-radius: 5px;
      color: #161a3f;
      font-size: 16px;
      letter-spacing: -0.42px;
    }
    input::placeholder {
      font-size: 14px;
    }
    div {
      width: 100%;
      max-width: 460px;
      padding: 12px;
      border: 1px solid #e7e7ee;
      border-radius: 5px;

      select {
        width: 100%;
        border: none;
        outline: none;
        cursor: pointer;
        background-color: #fff;
      }
    }
  }
  .name {
    label {
      width: 100%;
      max-width: 220px;
    }
    width: 100%;
    max-width: 460px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 20px;

      label {
        max-width: initial;

        div {
          padding: 0 12px;
        }
        select {
          height: 50px;
        }
      }
    }
  }
  .rate {
    width: 100%;
    max-width: 460px;
    display: flex;
    justify-content: space-between;

    .em {
      color: #e20000;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -0.96px;
    }
  }
`;

export const BookingFormAtom = atom({
  phone: "",
  email: "",
  rooms: [{ given_name: "", family_name: "", smoking: false }],
});
export default function BookingForm({ total, occupancy }: any) {
  const [form, setForm] = useAtom(BookingFormAtom);
  const price = Math.max(
    ...total.map((el: any) => Number(el.inclusive.billable_currency.value))
  );

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    let data = { ...form };
    if (e.target.name === "phone") {
      data.phone = e.target.value;
      setForm(data);
    }
    if (e.target.name === "email") {
      data.email = e.target.value;
      setForm(data);
    }
    if (e.target.name === "given_name") {
      data.rooms[0].given_name = e.target.value;
      setForm(data);
    }
    if (e.target.name === "family_name") {
      data.rooms[0].family_name = e.target.value;
      setForm(data);
    }
  };

  const handleSmoking = (e: ChangeEvent<HTMLSelectElement>) => {
    let data = { ...form };
    e.target.value === "false"
      ? (data.rooms[0].smoking = false)
      : (data.rooms[0].smoking = true);
    setForm(data);
  };

  return (
    <Container>
      <div className="container">
        <h3>고객 정보</h3>
        <InfoContainer>
          <label>
            <span>휴대폰 번호</span>
            <input
              type="tel"
              name="phone"
              value={form.phone}
              placeholder="휴대폰 번호를 입력해 주세요."
              onChange={handleInput}
            />
          </label>
          <label>
            <span>예약확인 이메일</span>
            <input
              type="email"
              name="email"
              value={form.email}
              placeholder="이메일 주소를 입력해 주세요."
              onChange={handleInput}
            />
          </label>
        </InfoContainer>
      </div>

      <div className="container">
        <h3>예약 정보</h3>
        <InfoContainer>
          <div className="name">
            <label>
              <span>성(영문사용)</span>
              <input
                type="text"
                name="family_name"
                value={form.rooms[0].family_name}
                placeholder="예) Hong"
                onChange={handleInput}
              />
            </label>
            <label>
              <span>이름(영문사용)</span>
              <input
                type="text"
                name="given_name"
                value={form.rooms[0].given_name}
                placeholder="예) Gildong"
                onChange={handleInput}
              />
            </label>
          </div>
          <label>
            <span>흡연 여부</span>
            <div>
              <select name="smoking" onChange={handleSmoking}>
                <option value="false">금연</option>
                <option value="true">흡연</option>
              </select>
            </div>
          </label>
        </InfoContainer>
      </div>
      <div className="container">
        <h3>결제 수단</h3>
        <InfoContainer>
          <label>
            <div>
              <select name="payment">
                <option value="credit_card">신용카드</option>
              </select>
            </div>
          </label>
        </InfoContainer>
      </div>

      <div className="container">
        <h3>최종 결제금액</h3>
        <InfoContainer>
          <div className="rate">
            <span>결제 금액</span>
            <span className="em">
              {"₩ " + (price * occupancy.length).toLocaleString()}
            </span>
          </div>
        </InfoContainer>
      </div>
    </Container>
  );
}

import { Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";
const Container = styled.div`
  margin: auto;
  padding: 44px 0 74px;
  width: 100%;
  max-width: 1200px;

  @media screen and (max-width: 768px) {
    padding: 63px 16px 30px;
  }
`;

const Content = styled.div`
  .heading {
    display: flex;
    justify-content: space-between;
  }
  .contact {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  h3 {
    margin: 0;
    color: #161a3f;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.96px;
  }
  .information {
    padding: 10px 0 8px;

    span {
      position: relative;
      padding-right: 8px;
      color: #5b5d75;
      font-size: 13px;
      letter-spacing: -0.52px;
    }

    span:not(:first-of-type) {
      padding-left: 8px;
      &::after {
        content: "";
        position: absolute;
        top: 3px;
        left: 0;
        width: 1px;
        height: 13px;
        background: #e5e6ee;
      }
    }
  }

  .cs {
    padding-left: 8px;
    border-left: 1px solid #e5e6ee;
    color: #161a3f;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.56px;
  }

  .info,
  p {
    padding: 0 8px;
    color: #5b5d75;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.52px;
  }
  .info:first-of-type,
  p {
    padding-left: 0;
    border-left: 0;
  }
  p {
    margin-top: 6px;
  }

  .copyright {
    display: flex;
    gap: 6px;

    span {
      padding-left: 4px;
      color: #5b5d75;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: -0.52px;
      border: 0;
    }
  }

  @media screen and (max-width: 480px) {
    h3 {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.72px;
    }
    span,
    .info {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: -0.48px;
    }
  }
`;

const Social = styled.div`
  display: flex;
  gap: 10px;
  height: fit-content;

  a {
    height: fit-content;
  }
`;

const SnsBtn = styled.button<{ $url: string }>`
  width: 38px;
  height: 38px;
  border: none;
  border-radius: 50%;
  background: url(${(props) => props.$url}) no-repeat center #ededf8;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    width: 34px;
    height: 34px;
  }
`;

const TermsContainer = styled.div`
  padding: 20px 0 0;
  a {
    text-decoration: none;
    color: inherit;
  }

  span {
    color: #2d40ff;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.52px;

    position: relative;
    padding-right: 8px;
    cursor: pointer;
  }

  span:not(:first-of-type) {
    padding-left: 8px;
    &::after {
      content: "";
      position: absolute;
      top: 3px;
      left: 0;
      width: 1px;
      height: 13px;
      background: #e5e6ee;
    }
  }
`;

export default function Footer() {
  const [searchParams] = useSearchParams();
  const company = searchParams.get("company");

  return (
    <Container>
      <footer>
        <Content>
          <div className="heading">
            <div className="contact">
              <img src="/img/phone.svg" alt="tel" />
              <h3>1533-7901</h3>
              <span className="cs">고객센터</span>
            </div>
            <Social>
              <Link to="https://blog.naver.com/wafflestay7" target="_blank">
                <SnsBtn $url="/img/blog.svg"></SnsBtn>
              </Link>
              <Link
                to="https://www.instagram.com/wafflestay_official/"
                target="_blank"
              >
                <SnsBtn $url="/img/instagram.svg"></SnsBtn>
              </Link>
              <Link to="https://pf.kakao.com/_HBAxeb" target="_blank">
                <SnsBtn $url="/img/kakao.svg"></SnsBtn>
              </Link>
            </Social>
          </div>

          <TermsContainer>
            <span>
              <Link to="/mypage/policy/service">이용약관</Link>
            </span>
            <span>
              <Link to="/mypage/policy/privacy">개인정보 보호정책</Link>
            </span>

            <span>
              <Link to="/mypage/policy/location">위치정보 이용약관</Link>
            </span>
          </TermsContainer>

          <div className="information">
            <span className="info">
              {company ? "(주)와이엠스타" : "(주)시냅틱웨이브"}
            </span>
            <span className="info">
              {company ? "대표 : 조윤미" : "대표 : 김세일"}
            </span>
            <span className="info">
              사업자등록번호 : {company ? "576-88-02382" : "422-86-01896"}{" "}
            </span>
            {!company && (
              <>
                <span className="info">
                  통신판매신고번호 : 제 2022-안양동안-0425 호
                </span>
                <span className="info">문의메일 : wafl@wafflestay.kr</span>
              </>
            )}
            <p>
              {company
                ? "서울특별시 강남구 테헤란로88길 22, 3층 (대치동)"
                : "경기도 안양시 동안구 엘에스로 116번길 118, 안양2차 SK V1 센터 1030호"}
            </p>
          </div>
          {!company && (
            <div className="copyright">
              <img src="/img/footer_logo.svg" alt="logo" />
              <span>Copyright ©2023. Wafflestay Inc All rights reserved.</span>
            </div>
          )}

          <script
            type="text/javascript"
            src="http://wcs.naver.net/wcslog.js"
          ></script>
          <script type="text/javascript">
            if (!wcs_add) var wcs_add = {}; wcs_add["wa"] = "s_52eb1dbaff76"; if
            (!_nasa) var _nasa = {}; wcs.inflow("https://www.wafflestay.co.kr");
            wcs_do(_nasa);
          </script>
        </Content>
      </footer>
    </Container>
  );
}

import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import VideoCard from "../components/VideoCard";

const Container = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1200px;
  margin-top: 100px;

  @media screen and (max-width: 1200px) {
    padding: 0 16px;
  }
`;

const Contents = styled.div`
  margin-top: 100px;
  width: 100%;
  justify-content: center;
  display: grid;
  row-gap: 36px;
  column-gap: 26px;
  grid-template-columns: repeat(auto-fill, 280px);
`;

const Card = styled.div`
  position: relative;
  width: 100%;
  max-width: 280px;
  height: 497px;
  border-radius: 10px;
  overflow: hidden;

  p {
    position: absolute;
    z-index: 9;
    margin: 20px 0;
    padding: 0 20px;
    color: #fff;
    font-weight: 500;
    letter-spacing: -0.56px;
  }

  .name {
    top: 0;
    width: 100%;
    font-size: 14px;
    height: 42px;
  }
  .title {
    bottom: 10px;
    font-size: 18px;
    letter-spacing: -0.72px;
  }

  div {
    position: absolute;
    z-index: 9;
    top: 20px;
    right: 10px;
    // padding: 10px 0;
    img {
      width: 18px;
      margin: 4px;
    }
  }
  .screener {
    position: absolute;
    z-index: 8;
    width: 100%;
    height: 100%;
    color: transparent;
  }
  video {
    width: 100%;
    height: 100%;
    background-color: #000;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .youtube_copy {
    opacity: 0;
    transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
    padding: 16px 16px 32px 16px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), transparent);
  }
`;

// const cat1 = { category_id: 4541, category_name: "waflstay" };
const cat2 = { category_id: 4774, category_name: "wafflervideo" };

export default function VideoTest() {
  const token = sessionStorage.getItem("miditk");

  const getList = async () => {
    // const res = await axios.get(`/v2/media/4774`, {
    const res = await axios.get(`/v2/channel/ch_1888e4f5`, {
      headers: {
        "X-Mbus-Token": `${token}`,
      },
    });
    console.log(res.data);
    //https://play.mbus.tv/hls/189d2fdd93e33d0a
  };

  useEffect(() => {
    // getList();
  }, []);

  const playAndPause = () => {
    if (videoRef.current) {
      console.log(videoRef.current.volume);
      videoRef.current.volume = 0.2;
      if (videoRef.current.paused) {
        videoRef.current && videoRef.current.play();
      } else {
        videoRef.current && videoRef.current.pause();
      }
    }
  };

  // const detectPlaying = () => {
  //   videoRef.current && console.log(videoRef.current.paused);
  // };

  // useEffect(() => {
  //   document.addEventListener("click", detectPlaying);
  //   return () => {
  //     document.removeEventListener("click", detectPlaying);
  //   };
  // }, []);
  const [mute, setMute] = useState(false);

  const handleMute = () => {
    setMute(!mute);
  };

  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <Container>
      <Contents>
        <VideoCard url="https://cache.midibus.kinxcdn.com/direct/18a48f212ec10cbe" />
        <VideoCard url="https://cache.midibus.kinxcdn.com/direct/189d28bc6d9233a6" />
        <VideoCard url="https://cache.midibus.kinxcdn.com/direct/18a48f4f88885f90" />

        {/* <Card onClick={playAndPause}>
          <p className="name">베스트 웨스턴 하버 파머스 호텔</p>
          <p className="title">시원한 바다뷰 끝내줍니다!</p>
          <div>
            <img src="/img/speaker.svg" alt="speaker" />
            <img src="/img/speaker-mute.svg" alt="mute" />
            <img src="/img/fullscreen.svg" alt="full-screen" />
          </div>
          <div className="screener"></div>
          <video
            ref={videoRef}
            // controls
            //  onLoadStart={}
            muted={mute}
            controlsList="nodownload noplaybackrate"
            disablePictureInPicture
            src="https://cache.midibus.kinxcdn.com/direct/18a48f212ec10cbe"
          ></video>
        </Card> */}
        <Card>
          <video
            controls
            controlsList="nodownload noplaybackrate"
            disablePictureInPicture
            src="https://cache.midibus.kinxcdn.com/direct/189d279c38828347"
          ></video>
        </Card>
        <Card>
          <video
            controls
            controlsList="nodownload noplaybackrate"
            disablePictureInPicture
            src="https://cache.midibus.kinxcdn.com/direct/18a48f353d880d8d"
          ></video>
        </Card>
      </Contents>
    </Container>
  );
}
